import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck, faList,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';

class DepartmentView extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;

        this.state = {
            data: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // if (!this.id || !Number(this.id)) {
        //     return
        // }

        this.setState({ isLoading: true })

        axios.get(config.apiURL + `api/department/${this.id}`).then(res => {
            console.log('res', res);
            if (res.data.success) {
                this.setState({ data: res.data.data })
            }
        }).finally(() => this.setState({ isLoading: false }))
    }

    handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value.trim();
        }

        if (!data?.name.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Şöbə adı boş olmamalıdır.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        axios.post(config.apiURL + 'api/department/create', data).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Şöbə uğurla əlavə edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/department/list")
            }
        })
    }

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Şöbə məlumatları</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            !this.state.isLoading ?
                                <>
                                    {
                                        this.state.data ?
                                            <>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='name' className="font-weight-bold">Şöbə adı</Label>
                                                    <Input
                                                        id='name'
                                                        readOnly
                                                        defaultValue={this.state.data.name}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='company' className="font-weight-bold">Şirkət</Label>
                                                    <Input
                                                        id='company'
                                                        readOnly
                                                        defaultValue={this.state.data.company_name}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <div className="col-12">
                                                <div className="alert alert-warning p-3">
                                                    Not found data
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="row justify-content-center mb-3 mt-5">
                        <div className="col-3">
                            <Link
                                to="/department/list"
                                className='btn btn-light'
                            >
                                <FontAwesomeIcon icon={faList} className="mr-2" />
                                Back to list
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DepartmentView;