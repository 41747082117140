import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPlus,
    faEdit,
    faPercentage,
    faDollarSign,
    faTrashAlt,
    faEye,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import { terminal_types } from '../../../store/staticData';
import CustomPagination from '../../common/CustomPagination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changeValue } from '../../../store/action';
import { Link } from "react-router-dom"
import { errorMessages, successMessages } from "../../../utils/httpMessages"

function PromotionList() {

    const dispatch = useDispatch()
    const {
        coupon_cost_types,
        yachts,
        option_values,
        customer_categories,
    } = useSelector(store => store.staticData)

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        rows: 10,
        total: 0,
    })
    const [searchParams, setSearchParams] = useState({})
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedDiscountType, setSelectedDiscountType] = useState("percent")
    const [selectedCostType, setSelectedCostType] = useState(null)
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [isOpenViewModal, setIsOpenViewModal] = useState(false)
    const [selectedYacht, setSelectedYacht] = useState(null)
    const [selectedOptionValues, setSelectedOptionValues] = useState([])
    const [selectedCustomerCategories, setSelectedCustomerCategories] = useState([])
    const [promocode, setPromocode] = useState('')

    useEffect(() => {
        getData(1, pagination.rows)
        if (!coupon_cost_types.length) {
            getCostTypes()
        }
        if (!coupon_cost_types.length) {
            getYachts()
        }
        if (!option_values.length) {
            getOptionValueList()
        }
        if (!customer_categories.length) {
            getCustomerCategories()
        }
    }, [])

    const getData = (page, rows) => {
        axios.get(config.apiURL + `admin/coupons`, {
            params: {
                page: page || pagination.page,
                per_page: rows || pagination.rows,
            }
        })
            .then(res => {
                setData(res.data.data.data)
                setPagination({
                    page: res.data.data.current_page,
                    rows: res.data.data.per_page,
                    total: res.data.data.total,
                })
            })
    }

    const getCostTypes = () => {
        axios.get(config.apiURL + `admin/coupons/cost-types`).then(res => {
            dispatch(changeValue("staticData", "coupon_cost_types", res.data.data))
        })
    }

    const getYachts = () => {
        axios.get(config.apiURL + `yachts`, {
            params: {
                per_page: 1000,
            }
        }).then(res => {
            dispatch(changeValue("staticData", "yachts", res.data.data.data))
        })
    }

    const getOptionValueList = () => {
        axios.get(config.apiURL + `option_values`, {
            params: {
                per_page: 1000,
            }
        }).then(res => {
            dispatch(changeValue("staticData", "option_values", res.data.data.data))
        })
    }

    const getCustomerCategories = () => {
        axios.get(config.apiURL + `admin/customer-categories`, {
            params: {
                per_page: 1000,
            }
        }).then(res => {
            dispatch(changeValue("staticData", "customer_categories", res.data.data.data))
        })
    }

    const setRowCount = (event) => {
        setPagination(prevState => ({
            ...prevState,
            rows: event.target.value
        }))
        getData(1, event.target.value);
    }

    const toggleCreateModal = () => setIsOpenCreateModal(prevState => !prevState)

    const toggleDeleteModal = () => setIsOpenDeleteModal(prevState => !prevState)

    const createData = e => {
        e.preventDefault();

        if (!selectedDiscountType) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Discount type mus be Percentage or Fixed Amount")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.name) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Promo Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.promocode) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Promo Code")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.discount_value) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">
                        {
                            errorMessages.required(selectedDiscountType === "percent" ? "Percentage Condition" : "Fixed Amount Condition")
                        }
                    </p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!selectedCostType?.id) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Type of Cost")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (data.min_purchase && data.min_purchase < 1) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.minVal("Minimum Amount", 1)}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (data.max_purchase && data.max_purchase < 1) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.minVal("Minimum Amount", 1)}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.start_date) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Start Date")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.start_time) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Start Time")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.end_date) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("End Date")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.end_time) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("End Time")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!data.usage_limit) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Usage Limit")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.post(config.apiURL + 'admin/coupons', {
            name: data.name,
            promocode: promocode,
            discount_type: selectedDiscountType,
            discount_value: data.discount_value,
            from_date: new Date(`${data.start_date}T${data.start_time}`),
            to_date: new Date(`${data.end_date}T${data.end_time}`),
            cost_type: selectedCostType.id,
            min_purchase: data.min_purchase,
            max_purchase: data.max_purchase,
            usage_limit: data.usage_limit,
            min_duration_hour: data.min_duration_hour,
            max_duration_hour: data.max_duration_hour,
            customer_categories: selectedCustomerCategories.map(item => item.id),
            yachts: selectedYacht?.id ? [selectedYacht.id] : [],
            option_values: selectedOptionValues.map(item => item.id)
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.created("Promotion")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                getData(pagination.page, pagination.rows);
                toggleCreateModal();
                resetState();
            }
        });
    }

    const deleteData = () => {
        if (!selectedRow) return;

        axios.delete(config.apiURL + `api/area/delete/${selectedRow.id}`)
            .then(res => {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.deleted("Promotion")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(pagination.page, pagination.rows);
                this.toggleDeleteModal();
            });
    };

    const resetState = () => {
        setSelectedRow(null)
        setSelectedDiscountType("percent")
        setSelectedCostType(null)
        setSelectedCustomerCategories([])
        setSelectedYacht(null)
        setSelectedCustomerCategories([])
        setSelectedOptionValues([])
        setPromocode('')
    }

    const columns = [{
        dataField: 'id',
        text: "ID"
    }, {
        dataField: 'name',
        text: 'Promo Name',
    }, {
        dataField: 'promocode',
        text: 'Promo Code',
    }, {
        dataField: 'isActive',
        text: 'Status',
        formatter: (cell) => {
            return cell ?
                <Badge className='p-2' color='success'>Active</Badge> :
                <Badge className='p-2' color='danger'>Deactive</Badge>
        }
    }, {
        dataField: 'from_date',
        text: 'Start Date',
        formatter: (cell) => {
            if (!cell) return '';
            return moment(cell).format("DD/MM/YYYY HH:mm")
        }
    }, {
        dataField: 'to_date',
        text: 'End Date',
        formatter: (cell) => {
            if (!cell) return '';
            return moment(cell).format("DD/MM/YYYY HH:mm")
        }
    }, {
        dataField: 'browse',
        text: '',
        formatter: (cell, row) => {

            // const type = terminal_types.find(x => x.id === row.type)

            return (
                <div className="text-center">
                    <Button
                        color='light'
                        className='mr-2'
                        onClick={() => {
                            setIsOpenViewModal(true)
                            setSelectedRow(row)
                        }}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                    {/* <Button
                        color='light'
                        className='mr-2'
                        onClick={() => {
                            this.setState({
                                isOpenEditModal: true,
                                selectedRow: row,
                                selectedDiscountType: type
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                        color='light'
                        className='mr-2'
                        onClick={() => {
                            this.setState({
                                isOpenDeleteModal: true,
                                selectedRow: row,
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button> */}
                </div>
            )
        }
    }];

    const handlePromoGenerate = () => {
        axios.post(config.apiURL + 'admin/coupons/generate-promocode').then(res => {
            setPromocode(res.data.data)
            toast.notify(({ onClose }) => (
                <div className="alert alert-info m-3">
                    <h5>{successMessages.success}</h5>
                    <p className="mb-0">{res.data.message}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
        })
    }

    return (
        <>
            <div className="bg-white rounded p-4">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="page-header">
                            <h1 className="font-weight-bold">Promotion list</h1>
                            <Button
                                color='primary'
                                className='font-weight-bold'
                                onClick={toggleCreateModal}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add New Promotion
                            </Button>
                        </div>
                    </div>
                </div>
                <>
                    {
                        data?.length ?
                            <div className="col-12 mt-4">
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    keyField='id'
                                    data={data}
                                    {...tableConfig}
                                    columns={columns}
                                    wrapperClasses="table-responsive"
                                />
                                <CustomPagination
                                    total={pagination.total}
                                    page={pagination.page}
                                    rows={pagination.rows}
                                    getData={getData}
                                    setRowCount={setRowCount}
                                    params={searchParams}
                                />
                            </div>
                            : <div className="alert alert-info">
                                Not found
                            </div>
                    }
                    {/* create modal */}
                    <Modal
                        size="lg"
                        centered
                        isOpen={isOpenCreateModal}
                        onClosed={resetState}
                    >
                        <ModalHeader toggle={toggleCreateModal}>New Promotion</ModalHeader>
                        <Form onSubmit={e => createData(e)}>
                            <ModalBody>
                                <div className="row pt-2">
                                    <div className="col-lg-12 mb-4">
                                        <Button
                                            className='mr-1'
                                            color={selectedDiscountType === "percent" ? 'primary' : 'secondary'}
                                            onClick={() => setSelectedDiscountType("percent")}
                                        >
                                            <FontAwesomeIcon icon={faPercentage} className='mr-2' />
                                            Percentage
                                        </Button>
                                        <Button
                                            className='mr-1'
                                            color={selectedDiscountType === "amount" ? 'primary' : 'secondary'}
                                            onClick={() => setSelectedDiscountType("amount")}
                                        >
                                            <FontAwesomeIcon icon={faDollarSign} className='mr-2' />
                                            Fixed Amount
                                        </Button>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <Label className="font-weight-bold">Basic Promo Details</Label>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <Input
                                            name="name"
                                            placeholder="Promotion name"
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <Input
                                            name="promocode"
                                            placeholder="Promo code"
                                            value={promocode}
                                            onChange={e => setPromocode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-4">
                                        <Button
                                            color='primary'
                                            onClick={handlePromoGenerate}
                                        >
                                            Generator
                                        </Button>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <Label className="font-weight-bold">
                                            {
                                                selectedDiscountType === "percent" ?
                                                    "Percentage Condition"
                                                    :
                                                    "Fixed Amount Condition"
                                            }
                                        </Label>
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Input
                                            name="discount_value"
                                            type='number'
                                            placeholder={`${selectedDiscountType === "percent" ? "%" : "AED"}`}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Select
                                            placeholder="Type of Cost"
                                            value={selectedCostType}
                                            options={coupon_cost_types}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.name}
                                            onChange={e => setSelectedCostType(e)}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Input
                                            name="min_purchase"
                                            type='number'
                                            placeholder="Minimum amount"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Input
                                            name="max_purchase"
                                            type='number'
                                            placeholder="Maximum amount"
                                        />
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <Label className="font-weight-bold">Promo Duration Details</Label>
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Label>Start date</Label>
                                        <Input
                                            name="start_date"
                                            type='date'
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Label>Start time</Label>
                                        <Input
                                            name="start_time"
                                            type='time'
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Label>End date</Label>
                                        <Input
                                            name="end_date"
                                            type='date'
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-4">
                                        <Label>End time</Label>
                                        <Input
                                            name="end_time"
                                            type='time'
                                        />
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <Label className="font-weight-bold">Usage Limit</Label>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <Input
                                            name="usage_limit"
                                            type='number'
                                            placeholder="Number of Usage"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <Select
                                            placeholder="Client category"
                                            isMulti
                                            value={selectedCustomerCategories}
                                            options={customer_categories}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.name}
                                            onChange={e => {
                                                if (!e) {
                                                    setSelectedCustomerCategories([])
                                                    return
                                                }
                                                setSelectedCustomerCategories(e)
                                            }}
                                        />
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <Label className="font-weight-bold">More Conditions</Label>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <Select
                                            placeholder="Select Yacht"
                                            value={selectedYacht}
                                            options={yachts}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.name}
                                            onChange={e => setSelectedYacht(e)}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        {
                                            selectedYacht &&
                                            <Button
                                                type='button'
                                                color='light'
                                                onClick={() => setSelectedYacht(null)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        }
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <Select
                                            placeholder="Select Addons"
                                            isMulti
                                            value={selectedOptionValues}
                                            options={option_values}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.name}
                                            onChange={e => {
                                                if (!e) {
                                                    setSelectedOptionValues([])
                                                    return
                                                }
                                                setSelectedOptionValues(e)
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        {
                                            selectedOptionValues.length > 0 &&
                                            <Button
                                                type='button'
                                                color='light'
                                                onClick={() => setSelectedOptionValues([])}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="light" onClick={toggleCreateModal}>Cancel</Button>
                                <Button
                                    color="primary"
                                    className="font-weight-bold"
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                    {
                        selectedRow &&
                        <>
                            {/* delete modal */}
                            <Modal
                                size="sm"
                                centered
                                isOpen={isOpenDeleteModal}
                                onClosed={resetState}
                            >
                                <ModalHeader toggle={toggleDeleteModal}></ModalHeader>
                                <ModalBody>
                                    <div className="row pt-2">
                                        <div className="col-md-12 text-center mb-4">
                                            <h5 className="font-weight-bold">
                                                Terminalı silmək istədiyinizdən əminsinizmi?
                                            </h5>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="justify-content-center">
                                    <Button color="light" onClick={toggleDeleteModal}>Cancel</Button>
                                    <Button
                                        color="primary"
                                        className="font-weight-bold"
                                        onClick={deleteData}
                                    >
                                        Sil
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            {/* detail modal */}
                            <Modal
                                size="lg"
                                centered
                                isOpen={isOpenViewModal}
                                onClosed={resetState}
                            >
                                <ModalHeader toggle={() => setIsOpenViewModal(false)}>Details</ModalHeader>
                                <ModalBody>
                                    <div className="row pt-2">
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">ID</Label>
                                            <p className="font-weight-bold">#{selectedRow.id}</p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Promo Name</Label>
                                            <p className="font-weight-bold">{selectedRow.name ?? ""}</p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Promo Code</Label>
                                            <p className="font-weight-bold">{selectedRow.promocode ?? ""}</p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Discount</Label>
                                            <p className="font-weight-bold">
                                                {selectedRow.discount_value}
                                                {' '}
                                                {
                                                    selectedRow.discount_type === "percent" ?
                                                        '%' :
                                                        selectedRow.discount_type === "amount" ?
                                                            'AED'
                                                            :
                                                            "undefined"
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Status</Label>
                                            <p className="font-weight-bold">
                                                {
                                                    selectedRow.isActive ?
                                                        <Badge color='success' className='p-2'>Active</Badge>
                                                        :
                                                        <Badge color='danger' className='p-2'>Deactive</Badge>
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Cost Type</Label>
                                            <p className="font-weight-bold">
                                                {
                                                    coupon_cost_types.find(x => x.id === selectedRow.cost_type)?.name
                                                    ?? ""
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Start Date</Label>
                                            <p className="font-weight-bold">
                                                {
                                                    selectedRow.from_date ?
                                                        moment(selectedRow.from_date).format("DD/MM/YYYY HH:mm")
                                                        :
                                                        ''
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">End Date</Label>
                                            <p className="font-weight-bold">
                                                {
                                                    selectedRow.to_date ?
                                                        moment(selectedRow.to_date).format("DD/MM/YYYY HH:mm")
                                                        :
                                                        ''
                                                }
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mb-4">
                                            <Label className="text-muted">Usage Count</Label>
                                            <p className="font-weight-bold">{selectedRow.usage_count}</p>
                                        </div>
                                        <div className='col-12 mb-4'>
                                            <hr />
                                        </div>
                                        <div className='col-12 mb-4'>
                                            <div className='table-responsive'>
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th>Condition</th>
                                                            <th>Status</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Usage Limit</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.usage_limit && "green"}
                                                                    icon={selectedRow.usage_limit ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    selectedRow.usage_limit ?
                                                                        `x${selectedRow.usage_limit}` : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Minimum Amount</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.min_purchase && "green"}
                                                                    icon={selectedRow.min_purchase ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>{selectedRow.min_purchase ? `${selectedRow.min_purchase} AED` : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Maximum Amount</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.max_purchase && "green"}
                                                                    icon={selectedRow.max_purchase ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>{selectedRow.max_purchase ? `${selectedRow.max_purchase} AED` : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Minimum Duration</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.min_duration && "green"}
                                                                    icon={selectedRow.min_duration ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>{selectedRow.min_duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Maximum Duration</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.max_duration && "green"}
                                                                    icon={selectedRow.max_duration ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>{selectedRow.max_duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Client Categories</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.customer_categories.length && "green"}
                                                                    icon={selectedRow.customer_categories.length ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    selectedRow.customer_categories.map(item => (
                                                                        <Badge
                                                                            style={{
                                                                                backgroundColor: item.bg_color,
                                                                                padding: ".75rem",
                                                                                fontSize: ".75rem"
                                                                            }}
                                                                            className='mr-2'
                                                                        >
                                                                            {item.name}
                                                                        </Badge>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Yachts</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    color={selectedRow.yachts.length && "green"}
                                                                    icon={selectedRow.yachts.length ? faCheck : faTimes}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    selectedRow.yachts.map(item => (
                                                                        <p key={item.id}>
                                                                            <Link
                                                                                to={`/yacht/view/${item.id}`}
                                                                                target="_blank"
                                                                                rel="noreferrer noopener"
                                                                            >
                                                                                {item.name}
                                                                            </Link>
                                                                        </p>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                        {
                                                            !selectedRow.option_values.length ?
                                                                <tr>
                                                                    <td>Add-ons</td>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faTimes}
                                                                        />
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                :
                                                                selectedRow.option_values.map(item => (
                                                                    <tr key={item.id}>
                                                                        <td>{item.option.name}</td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                color='green'
                                                                                icon={faCheck}
                                                                            />
                                                                        </td>
                                                                        <td>{item.name}</td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="justify-content-end">
                                    <Button
                                        color="primary"
                                        onClick={() => setIsOpenViewModal(false)}
                                    >
                                        Close
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    }
                </>
            </div>

        </>
    )
}

export default PromotionList