import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import toast from "toasted-notes";
import axios from "axios";
import { config, defaultCurrency, tableConfig } from "../../../config";
import { Link } from "react-router-dom";
import { errorMessages, successMessages } from "../../../utils/httpMessages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

class CompanyCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yachtList: [],
      selectedYacht: null,
      optionList: [],
      selectedOptions: [],
      reserved_dates: {},
      from_date: null,
      to_date: null,
      excluded_dates: [],
      isOpenUrlModal: false,
    };
  }

  componentDidMount() {
    this.getYachtList();
    this.getOptionValues();
  }

  getReservedDates = (yacht_id, params) => {
    if (!yacht_id) return
    axios.get(config.apiURL + `yachts/reserved-dates/${yacht_id}`, {
      params: {
        from_date: params?.from_date ?? moment().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
        to_date: params?.to_date ?? moment().add(1, "month").format("YYYY-MM-DD HH:mm:ss"),
      }
    }).then(res => {
      if (res.data.success) {
        // // console.log("resload, res", res.data.data);
        if (params?.isLoadMore) {
          this.setState({
            reserved_dates: {
              ...this.state.reserved_dates,
              ...res.data.data.reserved_hours
            }
          })
        } else {
          this.setState({
            reserved_dates: res.data.data.reserved_hours,
            excluded_dates: Object.keys(res.data.data.reserved_hours)
          })
        }
      }
    })
  }

  toggleConfirmModal = () => {
    this.setState({
      isOpenConfirmModal: !this.state.isOpenConfirmModal
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value.trim();
    }

    if (!data.fullname) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Fullname")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!this.state.phone) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Phone")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.email) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Email")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!Number(data.count_adult)) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Adults count")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.count_children) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">Enter children's count or 0</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    if (!this.state.selectedYacht) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Yacht")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    if ((Number(data.count_adult) + Number(data.count_children)) > this.state.selectedYacht.guest_limit) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">Max guest limit is {this.state.selectedYacht.guest_limit}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    if (!this.state.from_date) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("From date")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!this.state.to_date) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("To date")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    console.log("a", moment(this.state.from_date));
    console.log("a", moment(this.state.to_date));
    console.log(moment(this.state.from_date).isSame(moment(this.state.to_date)));

    if (moment(this.state.from_date).isSameOrAfter(moment(this.state.to_date))) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">The "To Date" must be after the "From Date".</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    // // ...
    data['phone'] = this.state.phone
    data['yacht_id'] = this.state.selectedYacht.id
    data['from_date'] = this.state.from_date
    data['to_date'] = this.state.to_date
    data['options'] = this.state.selectedOptions.filter(x => x.quantity > 0)
    data['qrcode'] = true

    if (!this.state.total_price_yacht || !this.state.orderTotal) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">Order not completed. Order Price is not defined.</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    axios.post(config.apiURL + "admin/orders", data).then((res) => {
      if (res.data.success) {
        this.setState({
          isOpenUrlModal: true,
          newOrder: {
            order_id: res.data.data.order_id,
            url: res.data.data.url,
            reference_id: res.data.data.reference_id,
            qrcode: res.data.data.qrcode
          }
        })

        toast.notify(() => (
          <div className="alert alert-success m-3">
            <h5>{successMessages.success}</h5>
            <p className="mb-0">{successMessages.created("Payment link")}</p>
          </div>
        ), { position: "top-right", duration: 2500 });
      }
    });
  };

  getYachtList = () => {
    axios.get(config.apiURL + "yachts", {
      params: {
        per_page: 100,
      }
    }).then(res => {
      if (res.data.success) {
        this.setState({ yachtList: res.data.data.data })
      }
    })
  }

  getOptionValues = () => {
    axios.get(config.apiURL + "option_values", {
      params: {
        per_page: 250,
      }
    }).then(res => {
      if (res.data.success) {
        this.setState({
          optionList: res.data.data.data,
          selectedOptions: res.data.data.data.map(item => ({
            id: item.id,
            quantity: 0,
            total: 0
          }))
        })
      }
    })
  }

  calculateByHours = (from_date, to_date, price) => {
    if (!from_date || !to_date || !price) {
      return 0
    }

    const startDateTime = moment(from_date, 'YYYY-MM-DD HH:mm');
    const endDateTime = moment(to_date, 'YYYY-MM-DD HH:mm');
    const duration = moment.duration(endDateTime.diff(startDateTime));

    if (duration.minutes()) {
      return (duration.hours() + 1) * price
    }

    return duration.hours() * price

  }

  calculateOrderTotal = (from_date, to_date, yacht) => {
    const yacht_price = this.calculateByHours(from_date, to_date, yacht?.price ?? 0)
    const options_price = this.state.total_price_options ?? 0

    this.setState({
      total_price_yacht: yacht_price,
      orderTotal: yacht_price + options_price
    })
  }

  handleQuantityChange = (value, row) => {
    this.setState(prevState => {
      const updatedSelectedOptions = prevState.selectedOptions.map(option => {
        if (option.id === row.id) {
          // console.log(option, "option");
          return {
            ...option,
            quantity: Number(value),
            total: row.price * Number(value)
          };
        }
        return option;
      });

      let options_subtotal = 0;

      updatedSelectedOptions.forEach(item => {
        options_subtotal += item.total
      })

      return {
        selectedOptions: updatedSelectedOptions,
        total_price_options: options_subtotal,
        // orderTotal: this.state.selectedYacht ? Number(this.state.selectedYacht.price) + options_subtotal : options_subtotal
      };
    });
  };

  filterTime = time => {
    console.log("filterTime", time);

    return []

    // const hour = moment(time).format("HH:mm:ss")
    const day = moment(time).format("YYYY-MM-DD HH:mm:ss")

    // console.log(day);
    // console.log("***");
    // console.log("***");
    // console.log("this.state.reserved_dates", this.state.reserved_dates);
    // const hour = time.getHours();
    // return !excludedHours.includes(hour);

    return !this.state.reserved_dates.includes(day)
  };

  loadMoreReservedDates = date => {
    this.setState({ to_date: date })
    const monthExists = Object.keys(this.state.reserved_dates).includes(moment(date).format("YYYY-MM-DD"));
    if (!monthExists) {
      this.getReservedDates(this.state.selectedYacht?.id, {
        from_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment(date).add(1, "month").format("YYYY-MM-DD HH:mm:ss"),
        isLoadMore: true
      })
    }
  }

  handleOnChangeToDate = e => {
    if (!e) {
      this.setState({ to_date: null },
        this.calculateOrderTotal(this.state.from_date, null, this.state.selectedYacht)
      )
      return
    }
    const selectedDay = e.getDate();
    const newToDate = new Date(e);
    const fromDateDay = moment(this.state.from_date).get("date")
    if (selectedDay < fromDateDay) {
      // If selected time is earlier than current time, set date to this.state.from_date's date
      newToDate.setDate(fromDateDay);
    }

    console.log("newToDate", newToDate.getTime());
    console.log("this.state.from_date", this.state.from_date.getTime());
    console.log("test", newToDate.getTime() <= this.state.from_date.getTime());

    if (newToDate.getTime() <= this.state.from_date.getTime()) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">To date is not valid</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    this.setState({ to_date: newToDate },
      this.calculateOrderTotal(this.state.from_date, newToDate, this.state.selectedYacht)
    )
  }

  render() {

    const columns = [
      {
        dataField: "id",
        text: "#",
        formatter: (cell, row, index) => `${index + 1}.`
      },
      {
        dataField: "mark",
        text: "",
        formatExtraData: this.state.selectedOptions,
        formatter: (cell, row, index, extraData) =>
        (
          extraData.find(x => x.id === row.id)?.quantity ? <FontAwesomeIcon icon={faCheck} /> : ""
        )

      },
      {
        dataField: "option.name",
        text: "Option",
      },
      {
        dataField: "name",
        text: "Value",
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (cell) => !cell ? "Free" : `${cell} ${defaultCurrency}`
      },
      {
        dataField: "quantity",
        text: "Quantity",
        formatExtraData: this.state.selectedOptions,
        formatter: (cell, row, index, extraData) => {
          return <Input
            type="number"
            min={0}
            style={{ maxWidth: "50%" }}
            value={extraData.find(item => item.id === row.id).quantity}
            onChange={async e => {
              const updateQuantity = async () => {
                return new Promise(resolve => {
                  resolve(
                    this.handleQuantityChange(e.target.value, row)
                  )
                })
              }

              await updateQuantity().then(() => this.calculateOrderTotal(this.state.from_date, this.state.to_date, this.state.selectedYacht))
            }}
          />
        }
      },
      {
        dataField: "total_price",
        text: "Subtotal",
        formatExtraData: this.state.selectedOptions,
        formatter: (cell, row, index, extraData) => {
          return extraData.find(x => x.id === row.id)?.total ? `${extraData.find(x => x.id === row.id).total} ${defaultCurrency}` : ""
        }
      },
    ];

    const { reserved_dates, from_date } = this.state;

    // Format reserved dates and times as Date objects
    const disabledDates = Object.keys(reserved_dates).map(date => {
      const reservedTimes = reserved_dates[date].map(time => {
        const [hours, minutes] = time.split(':');
        const reservedTime = new Date(date);
        reservedTime.setHours(Number(hours), Number(minutes), 0, 0);
        return reservedTime;
      });
      return {
        date: new Date(date),
        times: reservedTimes,
      };
    });

    console.log("disabledDates", disabledDates);

    // Custom function to filter out reserved times for a selected date
    const filterTimes = time => {

      // console.log("disabledDates", disabledDates);

      // console.log("date", moment(from_date).get("month"));
      // console.log("time", moment(time).get("month"));

      // if(!isNaN(from_date) && )

      const selectedDateTimes = disabledDates.find(disabledDate =>
        // // disabledDate.date.toDateString() === from_date.toDateString()
        // disabledDate.date.toDateString() === new Date().toDateString()
        disabledDate.date.getDate() === time.getDate()
      );

      // console.log("disabledDates", disabledDates);

      if (selectedDateTimes) {
        return !selectedDateTimes.times.some(reservedTime =>
          time.getHours() === reservedTime.getHours() &&
          time.getMinutes() === reservedTime.getMinutes()
        );
      }

      return true;
    };

    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">Create new order</h1>
              </div>
            </div>
          </div>

          <Form
            className="row"
            onSubmit={this.handleSubmit}
            autoComplete="off"
          >
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="name" className="font-weight-bold">
                Fullname <span className="text-danger">*</span>
              </Label>
              <Input id="fullname" name="fullname" placeholder="Enter fullname" />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="surname" className="font-weight-bold">
                Phone <span className="text-danger">*</span>
              </Label>
              <PhoneInput
                value={this.state.phone}
                defaultCountry="AE"
                onChange={e => this.setState({ phone: e })}
                className="reactPhoneInput"
                placeholder="Enter phone number"
                onCountryChange={e => console.log("eee", e)}
              />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="email" className="font-weight-bold">
                Email <span className="text-danger">*</span>
              </Label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="Enter email"
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Label for="count_adult" className="font-weight-bold">
                Adults <span className="text-danger">*</span>
              </Label>
              <Input
                id="count_adult"
                type="number"
                name="count_adult"
                min="0"
                max={this.state.selectedYacht?.guest_limit}
                placeholder="Enter adult's count"
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Label for="count_adult" className="font-weight-bold">
                Children <span className="text-danger">*</span>
              </Label>
              <Input
                id="count_children"
                type="number"
                name="count_children"
                defaultValue={0}
                min="0"
                max={this.state.selectedYacht?.guest_limit}
                placeholder="Enter children's count"
              />
            </div>
            <div className="col-md-12 col-lg-12 mb-4">
              <Label for="message" className="font-weight-bold">
                Message
              </Label>
              <Input
                id="message"
                name="message"
                type="textarea"
                placeholder="Enter message"
              />
            </div>
            <div className="col-md-12">
              <hr className="my-5" />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label className="font-weight-bold">
                Yacht <span className="text-danger">*</span>
              </Label>
              <Select
                placeholder="Select"
                value={this.state.selectedYacht}
                options={this.state.yachtList}
                isClearable={true}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => `${option.name} - ${option.price} ${defaultCurrency}`}
                onChange={e => {
                  if (e) this.getReservedDates(e.id)
                  this.setState({
                    selectedYacht: e,
                  }, this.calculateOrderTotal(this.state.from_date, this.state.to_date, e))
                }}
              />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label className="font-weight-bold">
                From date <span className="text-danger">*</span>
              </Label>
              {
                console.log("rerender")
              }
              <DatePicker
                disabled={!this.state.selectedYacht}
                selected={this.state.from_date}
                minDate={moment.now()}
                minTime={moment.now()}
                maxTime={moment().endOf("day")}
                onMonthChange={e => {
                  this.loadMoreReservedDates(e)
                  this.setState({ from_date: e.setMinutes(0), to_date: null })
                }}
                filterTime={filterTimes}
                // excludeDates={disabledDates.map(disabledDate => disabledDate.date)}
                showTimeSelect
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="dd/mm/yyyy hh:mm"
                timeCaption="Hour"
                isClearable
                onChange={(e) => {
                  if (!e) {
                    this.setState({
                      from_date: null,
                      to_date: null,
                    },
                      this.calculateOrderTotal(null, this.state.to_date, this.state.selectedYacht)
                    )
                  }
                  this.setState({ from_date: e },
                    this.calculateOrderTotal(e, this.state.to_date, this.state.selectedYacht))
                }}
              />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label className="font-weight-bold">
                To date <span className="text-danger">*</span>
              </Label>
              <DatePicker
                disabled={!this.state.from_date}
                selected={this.state.to_date}
                minDate={this.state.from_date}
                minTime={this.state.from_date ? moment(this.state.from_date).startOf('day') : null}
                maxTime={moment().endOf("day")}
                // filterTime={filterTimes}
                filterTime={time => {
                  const selectedDateTimes = disabledDates.find(disabledDate =>
                    // disabledDate.date.toDateString() === from_date.toDateString()
                    // disabledDate.date.toDateString() === new Date().toDateString()
                    disabledDate.date.getDate() === time.getDate()
                  );
                  if (selectedDateTimes) {
                    if (selectedDateTimes.times.some(reservedTime =>
                      time.getHours() === reservedTime.getHours() &&
                      time.getMinutes() === reservedTime.getMinutes()
                    )) {
                      return false
                    }
                    return true
                  } else {
                    return time.getHours() !== this.state.from_date.getHours() && time.getHours() >= this.state.from_date.getHours() && time.getMinutes() >= this.state.from_date.getMinutes()
                  }
                }}

                onCalendarOpen={() => {
                  console.log("fff", moment(from_date).add(1, "hour").toDate());
                  console.log("sss", this.state.to_date);
                  this.setState({ to_date: moment(from_date).add(1, "hour").toDate() })
                }}

                onChange={this.handleOnChangeToDate}
                onMonthChange={e => this.loadMoreReservedDates(e)}
                showTimeSelect
                timeIntervals={60}
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="dd/mm/yyyy hh:mm"
                timeCaption="Hour"
                isClearable
              />
            </div>
            <div className="col-12 mb-4">
              <BootstrapTable
                bootstrap4
                striped
                keyField="id"
                data={this.state.optionList}
                {...tableConfig}
                columns={columns}
                wrapperClasses="table-responsive"
              />
            </div>
            <div className="col-12 mb-4">
              <div>Yacht subtotal: <h4>{this.state.total_price_yacht ?
                `${this.state.total_price_yacht.toLocaleString()} ${defaultCurrency}` : ""}</h4></div>
              <div>Add-ons: <h4>{this.state.total_price_options ?
                `${this.state.total_price_options.toLocaleString()} ${defaultCurrency}` : ""}</h4></div>
              <div>Total: <h3>{this.state.orderTotal ?
                `${this.state.orderTotal.toLocaleString()} ${defaultCurrency}` : ""}</h3></div>
            </div>
            <div className="col-md-3 col-lg-2 mb-4">
              {/* create input for promocode here */}
              <Input
                type="text"
                name="promocode"
                style={{
                  textTransform: "uppercase",
                }}
                maxLength={8}
                minLength={8}
                placeholder="promocode"
              />
            </div>
            {/*  */}
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <Link
                to="/order/list"
                className="btn btn-light font-weight-bold"
              >
                Cancel
              </Link>
              <Button
                type="submit"
                color="primary"
                className="font-weight-bold ml-2"
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
              </Button>
            </div>
          </Form>
        </div>
        {
          this.state.newOrder &&
          <>
            {/* url modal */}
            <Modal Modal
              size="md"
              centered
              isOpen={this.state.isOpenUrlModal}
            >
              <ModalHeader>Payment link created</ModalHeader>
              <ModalBody>
                <div className="row pt-2">
                  <div className="col-md-12 text-center mb-4">
                    <p>Order: <span>#{this.state.newOrder.order_id}</span></p>
                    <p>Reference: <span>{this.state.newOrder.reference_id}</span></p>
                    <p>Payment link: <span className="font-weight-bold">{this.state.newOrder.url}</span></p>
                    <div>
                      <img src={this.state.newOrder.qrcode} />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                {/* <Button
       color="light"
       onClick={this.toggleDeleteModal}
     >
       Copy payment link
     </Button> */}
                <Button
                  color="primary"
                  className="font-weight-bold"
                  onClick={() => this.props.history.push("/order/list")}
                >
                  Go to orders page
                </Button>
              </ModalFooter>
            </Modal >
          </>
        }
      </>
    );
  }
}

const mapStateToProps = (store) => store;
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
