import React from "react";
import { Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config } from "../../../config";
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import moment from "moment";

class CompanyView extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      data: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.apiURL + `users/${this.id}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value.trim();
    }

    if (!data?.name.trim()) {
      toast.notify(
        () => (
          <div className="alert alert-danger m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Təşkilatın adı boş olmamalıdır.</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    axios.post(config.apiURL + "api/yacht/create", data).then((res) => {
      if (res.data) {
        this.setState({ data: res.data });
      }
    });
  };

  render() {
    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">System users</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {!this.state.isLoading ? (
              <>
                {this.state.data ? (
                  <>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="name" className="font-weight-bold">
                        Name
                      </Label>
                      <Input
                        id="name"
                        readOnly
                        defaultValue={this.state.data.name}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="surname" className="font-weight-bold">
                        Surname
                      </Label>
                      <Input
                        id="surname"
                        readOnly
                        defaultValue={
                          this.state.data.surname
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="username" className="font-weight-bold">
                        Username
                      </Label>
                      <Input
                        id="username"
                        readOnly
                        defaultValue={this.state.data.username}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="created_at" className="font-weight-bold">
                        Created at
                      </Label>
                      <Input
                        id="created_at"
                        readOnly
                        defaultValue={this.state.data.created_at ? moment(this.state.data.created_at).format('DD-MM-YYYY HH:mm:ss') : ""}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-warning p-3">
                      Not found
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="col-12">
                <div className="alert alert-info p-4 text-center">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row justify-content-center mb-3 mt-5">
            <div className="col-3">
              <Link to="/system-users/list" className="btn btn-light">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Back to list
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyView;
