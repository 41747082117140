import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store/action';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAlignLeft,
  faBars,
  faBell,
  faDownload,
  faPlus,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import {
  Navbar,
  Collapse,
  Button,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import toast from "toasted-notes";
import axios from 'axios';
import { config } from '../../config';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  readNotification = id => {
    if (!id) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-success m-3">
          <h5>Xəta baş verdi!</h5>
          <p className="mb-0">ID təyin edilmədi.</p>
        </div>), { position: "top-right", duration: 2500 }
      );
      return;
    };
    axios.put(config.apiURL + 'api/notifications/' + id).then(res => {
      // console.log('res', res);
    });
  }

  render() {
    return (
      <Navbar className="navbar p-3 mb-3" expand="sm">
        <Button
          color="light"
          className="mr-2"
          onClick={this.props.toggle}
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Button color="dark" onClick={this.toggle} className="d-block d-sm-none">
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto mt-3 mt-sm-0" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className='d-flex align-items-center justify-content-end' nav>
                <div className="user-details">
                  <div className="mr-2">
                    <p className="font-weight-bold text-right m-0">
                      {this.props.user?.fullname}
                    </p>
                    <p style={{ lineHeight: 1 }} className="m-0 text-right">
                      {this.props.user?.role ? this.props.user.role?.label : ''}
                    </p>
                  </div>
                  <Button
                    color="white"
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </Button>
                </div>
              </DropdownToggle>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = store => store;
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);