import React from "react";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, tableConfig } from "../../../config";
import toast from "toasted-notes";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import moment from "moment";

class RoleList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total: 0,
            rows: 1000,
            page: 1,
            data: [],
            isOpenDetailModal: false,
            isOpenEditModal: false,
            isOpenDeleteModal: false,
            selectedRow: null,
            permissionList: [],
            permissions: [],
            selectedPermissionsObj: {},
        };

    }

    componentDidMount() {
        this.getPermissions();
        this.getData(1, this.state.rows);
    }

    checkUserPermissions = role_permissions => {

        const permissionIdList = role_permissions.map(item => item.id)

        let selectedPermissionsObj = {};

        permissionIdList.forEach(item => {

            const permission_item = this.state.permissions.find(x => x.id === item)

            if (permission_item) {
                selectedPermissionsObj[permission_item.name] = true;
            }

        });
        this.setState({
            selectedPermissionsObj: selectedPermissionsObj,
        });

        // console.log('selectedPermissionsObj', selectedPermissionsObj);
    };

    getData = (page, rows) => {
        this.setState({ isLoadingData: true })
        axios.get(config.apiURL + `roles`, {
            params: {
                page: page,
                per_page: rows,
            }
        }).then(res => {
            if (res.data.success) {
                console.log("resss", res.data);
                this.setState({
                    data: res.data.data.data,
                    page: res.data.data.current_page,
                    total: res.data.data.total,
                })
            }
        }).finally(() => this.setState({ isLoadingData: false }));
    }

    getPermissions = () => {
        axios.get(config.apiURL + "roles/permissions", {
            params: {
                per_page: 1000
            }
        }).then((res) => {
            if (res.data.success) {

                console.log(res.data.data.data);

                // let permissionsResponse = { ...res.data.data.data };
                // let permissionList = [];
                // let permissions = [];
                // Object.keys(permissionsResponse).map((roleId) => {
                //     permissions.push(...permissionsResponse[roleId]);
                //     permissionList.push(permissionsResponse[roleId]);
                // });

                // // console.log('permissionList', permissionList);

                this.setState({
                    permissionList: res.data.data.data,
                    // permissions: permissions,
                });
            }
        });
    }

    toggleViewModal = () => {
        this.setState({ isOpenDetailModal: !this.state.isOpenDetailModal });
    };

    toggleEditModal = () => {
        this.setState({ isOpenEditModal: !this.state.isOpenEditModal });
    };

    toggleBlockModal = () => {
        this.setState({ isOpenBlockModal: !this.state.isOpenBlockModal });
    };

    toggleDeleteModal = () => {
        this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal });
    };

    editData = e => {
        e.preventDefault();

        if (!this.state.selectedRow) {
            toast.notify(
                ({ onClose }) => (
                    <div className="alert alert-danger m-3">
                        <h5>Xəta başverdi!</h5>
                        <p className="mb-0">Not found data.</p>
                    </div>
                ),
                { position: "top-right", duration: 2500 }
            );
            return;
        }

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value.trim();
        }

        if (!data.label) {
            toast.notify(
                ({ onClose }) => (
                    <div className="alert alert-danger m-3">
                        <h5>Uğursuz əməliyyat!</h5>
                        <p className="mb-0">Rol adı daxil edilməyib.</p>
                    </div>
                ),
                { position: "top-right", duration: 2500 }
            );
            return;
        }

        // let allPermissionList = [];

        // this.state.permissionList.map((list) => {
        // 	allPermissionList = allPermissionList.concat(list);
        // });

        let permissionIdList = [];
        for (const [key, value] of Object.entries(this.state.selectedPermissionsObj)) {
            if (value) {
                let item = this.state.permissions.find((x) => x.name === key)
                if (item?.id) permissionIdList.push(item.id);
            }
        }

        if (!permissionIdList.length) {
            toast.notify(
                ({ onClose }) => (
                    <div className="alert alert-danger m-3">
                        <h5>Uğusuz əməliyyat!</h5>
                        <p className="mb-0">İcazə seçilməyib.</p>
                    </div>
                ),
                { position: "top-right", duration: 2500 }
            );
            return;
        }

        // console.log('permissionIdList', permissionIdList);

        data["permissions"] = permissionIdList;

        axios.put(config.apiURL + `api/role/${this.state.selectedRow.id}`, data).then((res) => {
            if (res.data.success) {
                toast.notify(
                    ({ onClose }) => (
                        <div className="alert alert-success m-3">
                            <h5>Uğurlu əməliyyat!</h5>
                            <p className="mb-0">Rol uğurla redakdə edildi.</p>
                        </div>
                    ),
                    { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.setState({
                    selectedRow: null,
                    selectedPermissionsObj: {},
                });
                this.toggleEditModal();
            }
        });
    };

    deleteData = () => {
        axios
            .delete(config.apiURL + `api/role/${this.state.selectedRow.id}`).then((res) => {
                if (res.data.success) {
                    toast.notify(
                        ({ onClose }) => (
                            <div className="alert alert-success m-3">
                                <h5>Uğurlu əməliyyat!</h5>
                                <p className="mb-0">Rol uğurla silindi.</p>
                            </div>
                        ),
                        { position: "top-right", duration: 2500 }
                    );
                    this.getData(this.state.page, this.state.rows);
                    this.setState({ selectedRow: null });
                    this.toggleDeleteModal();
                }
            });
    };

    handleCheck = (type, check) => {
        this.setState({
            selectedPermissionsObj: {
                ...this.state.selectedPermissionsObj,
                [type]: check,
            },
        });
    };

    render() {
        const columns = [
            {
                dataField: "id",
                text: "No.",
                formatter: (cell, row, index) => {
                    return index + 1;
                },
            },
            {
                dataField: "name",
                text: "Name of the role",
            },
            {
                dataField: "created_at",
                text: "Created at",
                formatter: (cell) => !cell ? "" : moment(cell).format("DD-MM-YYYY")
            },
            {
                dataField: "browse",
                text: "",
                formatter: (cell, row) => {
                    return (
                        <div className="text-center">
                            <UncontrolledDropdown>
                                <DropdownToggle color="primary">...</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({ selectedRow: row });
                                            this.toggleViewModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-2" />
                                        View
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({
                                                selectedRow: row,
                                            });
                                            this.toggleEditModal();
                                            this.checkUserPermissions(row.permissions);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPen} className="mr-2" />
                                        Edit
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({ selectedRow: row });
                                            this.toggleDeleteModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                        Delete
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    );
                },
            },
        ];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <h1 className="font-weight-bold">User roles</h1>
                                <Link
                                    to="/role/create"
                                    className='btn btn-primary font-weight-bold'
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Create
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <BootstrapTable
                            bootstrap4
                            striped
                            keyField="id"
                            data={this.state.data}
                            {...tableConfig}
                            columns={columns}
                        />
                    </div>
                </div>

                {/* detail modal */}
                <Modal isOpen={this.state.isOpenDetailModal} centered size="md">
                    <ModalHeader toggle={this.toggleViewModal}>Details</ModalHeader>
                    {this.state.selectedRow ? (
                        <>
                            <ModalBody>
                                <div className="row pt-2">
                                    <div className="col-12 col-md-6 mb-4">
                                        <Label className="text-muted">Name of the role</Label>
                                        <p className="font-weight-bold">
                                            {
                                                this.state.selectedRow?.name ?? ""
                                            }
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <Label className="text-muted">Role permissions</Label>
                                        {
                                            console.log("this.state.selectedRow", this.state.selectedRow)
                                        }
                                        {
                                            this.state.selectedRow.permissions.map(item => {
                                                return (
                                                    <p key={item.id} className="font-weight-bold">{item.label}</p>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning">Not found data</div>
                            </div>
                        </div>
                    )}
                </Modal>
                {/* edit modal */}
                <Modal isOpen={this.state.isOpenEditModal} centered size="lg">
                    <ModalHeader toggle={this.toggleEditModal}>Edit role details</ModalHeader>
                    <Form onSubmit={(e) => this.editData(e)}>
                        {this.state.selectedRow ? (
                            <>
                                <ModalBody>
                                    <div className="row pt-2">
                                        <div className="col-md-6 col-lg-4 mb-4">
                                            <Label className="font-weight-bold">Name of the role</Label>
                                            <Input
                                                name="name"
                                                placeholder="Ad daxil et"
                                                defaultValue={this.state.selectedRow.name}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                {
                                                    this.state.permissionList.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-md-12">
                                                                <div className="pro-checkbox-wrapper">
                                                                    {/* {
                                                                        item.map(item => {
                                                                            return ( */}
                                                                                <label
                                                                                    key={item.id}
                                                                                    className="custom-checkbox"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        onChange={e =>
                                                                                            this.handleCheck(
                                                                                                item.name,
                                                                                                e.target.checked
                                                                                            )
                                                                                        }
                                                                                        // checked={
                                                                                        //     this.state.selectedPermissionsObj[item.name]
                                                                                        // }
                                                                                    />
                                                                                    {item.label}
                                                                                </label>
                                                                            {/* );
                                                                        })
                                                                    } */}
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        );
                                                    })
                                                }
                                                {/* {
                                                    this.state.permissionList.map((list, index) => {
                                                        return (
                                                            <div key={index} className="col-md-12">
                                                                <div className="pro-checkbox-wrapper">
                                                                    {
                                                                        list.map(item => {
                                                                            return (
                                                                                <label
                                                                                    key={item.id}
                                                                                    className="custom-checkbox"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        onChange={e =>
                                                                                            this.handleCheck(
                                                                                                item.name,
                                                                                                e.target.checked
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            this.state.selectedPermissionsObj[item.name]
                                                                                        }
                                                                                    />
                                                                                    {item.label}
                                                                                </label>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        );
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="light" onClick={this.toggleEditModal}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" className="font-weight-bold">
                                        Təsdiq et
                                    </Button>
                                </ModalFooter>
                            </>
                        ) : (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-warning">Not found data</div>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
                {/* delete modal */}
                <Modal isOpen={this.state.isOpenDeleteModal} centered size="sm">
                    <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                    {this.state.selectedRow ? (
                        <>
                            <ModalBody>
                                <div className="row pt-2">
                                    <div className="col-md-12 text-center mb-4">
                                        <h5 className="font-weight-bold">
                                            İstifadəçi rolunu siyahıdan silməyinizə əminsinizmi?
                                        </h5>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className="justify-content-center">
                                <Button color="light" onClick={this.toggleDeleteModal}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    className="font-weight-bold"
                                    onClick={this.deleteData}
                                >
                                    Sil
                                </Button>
                            </ModalFooter>
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning">Not found data</div>
                            </div>
                        </div>
                    )}
                </Modal>
            </>
        );
    }
}

export default RoleList;
