import axios from 'axios';
import { config } from "./config";
import { store } from './store/store';
import { changeValue, logout } from './store/action';
import toast from 'toasted-notes';
import React from "react";
import { errorMessages } from './utils/httpMessages';

const { dispatch } = store;

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token && config.url.indexOf('auth') === -1) {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.headers['Content-Type'] = 'application/json'
    }
    dispatch(changeValue("isLoading", "wrapper", true));
    return config;
  }, error => {
    Promise.reject(error);
  }
);


axios.interceptors.response.use(
  response => {
    dispatch(changeValue("isLoading", "wrapper", false));
    return response;
  }, async (error) => {
    dispatch(changeValue("isLoading", "wrapper", false));
    console.log('interceptor', error.response);

    if (!error.response) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5 className='m-0'>{errorMessages.internal}</h5>
          <p className="mb-0">Could not connect to the server.</p>
        </div>), { position: "top-right", duration: 3500 });
      return Promise.reject(error)
    }

    if (error.response.status === 405 || error.response.status === 429) {
      return Promise.reject(error);
    }
    else if (error.response.status === 400) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5 className='m-0'>{errorMessages.failed}</h5>
          <p className="mb-0">
            {
              error.response.data.message ? error.response.data.message : '.'
            }
          </p>
        </div>), { position: "top-right", duration: 3500 });
      return Promise.reject(error)
    } else if (error.response.status === 422) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5 className='m-0'>{errorMessages.failed}</h5>
          {/* {error.response?.data?.error?.map(x => x && Object.values(x.constraints))?.map((x, idx) => <p key={idx} className='mb-0'>{x}</p>)} */}
        </div>), { position: "top-right", duration: 4500 });
      return Promise.reject(error)
    } else if (error.response.status === 403) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5>You don't have permission!</h5>
          <p className="mb-0">
            {
              error.response.data.errors &&
              Object.values(error.response.data.errors).map((item, index) =>
                <p key={index} className="m-0">{item.join('\n')}</p>
              )
            }
          </p>
        </div>), { position: "top-right", duration: 3500 });
      return Promise.reject(error)
    } else if (error.response.status === 500) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5 className='m-0'>{errorMessages.failed}</h5>
          <p className="mb-0">
            {
              error.response.data.message ? error.response.data.message : '.'
            }
          </p>
        </div>), { position: "top-right", duration: 3500 });
      return Promise.reject(error)
    }
    // else if (error.response.status !== 400 && error.response.status !== 401) {
    //   toast.notify(({ onClose }) => (
    //     <div className="alert alert-danger m-3">
    //       <h5 className='m-0'>{errorMessages.failed}</h5>
    //       <p className="mb-0">
    //         {
    //           error.response.data.errors &&
    //           Object.values(error.response.data.errors).map((item, index) =>
    //             <p key={index} className="m-0">{item.join('\n')}</p>
    //           )
    //         }
    //       </p>
    //     </div>), { position: "top-right", duration: 3500 });
    //   return Promise.reject(error)
    // }

    if (error.response.status === 401 && !error.response.data.error) {
      toast.notify(({ onClose }) => (
        <div className="alert alert-danger m-3">
          <h5 className='m-0'>{errorMessages.failed}</h5>
          <p className="mb-0">
            {
              error.response.data.message ? error.response.data.message : '.'
            }
          </p>
        </div>), { position: "top-right", duration: 3500 });
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('refreshToken');

      if (
        (error.response.data.error.type === 'expired')
      ) {

        try {
          const refreshResponse = await axios.post(
            config.apiURL + 'auth/refresh-token',
            {
              refresh_token: refreshToken,
            }
          );

          console.log("refreshResponse", refreshResponse);

          localStorage.setItem('token', refreshResponse.data.data.access_token);
          localStorage.setItem(
            'refreshToken',
            refreshResponse.data.data.refresh_token
          );

          return axios(originalRequest);
        } catch (refreshError) {
          // Refresh token request failed, log out the user
          dispatch(logout());

          // Show an error message to the user
          toast.error('Failed to refresh token. Logging out.');

          return Promise.reject(refreshError);
        }
      }

      dispatch(logout());
    }




    return new Promise((resolve) => {
      // const refreshToken = localStorage.getItem('refreshToken');

      // console.log("originalRequest", originalRequest);

      // console.log("errrr", error.response.data.error.type === "expired");


      // if (error.response.status === 401 && error.response.data. && !originalRequest._retry)

      // if (error.response.status === 401 && !originalRequest._retry &&
      //   error.response.config.url.indexOf('/auth/refresh') === -1) {
      //   originalRequest._retry = true;
      //   let response = axios.post(config.apiURL + 'auth/refresh', {
      //     refreshToken: refreshToken,
      //   }).then(res => {
      //     localStorage.setItem('token', res.data.data.accessToken);
      //     localStorage.setItem('refreshToken', res.data.data.refreshToken);
      //     return axios(originalRequest);
      //   });

      //
      // if (error.response.status === 401) {
      //   toast.notify(({ onClose }) => (
      //     <div className="alert alert-danger m-3">
      //       <h5 className='m-0'>{errorMessages.failed}</h5>
      //       <p className="mb-0">
      //         Username or password is not valid.
      //         <br />
      //         You must log in again.
      //       </p>
      //     </div>), { position: "top-right", duration: 3500 });
      //   // dispatch(logout());
      // }

      // ..

      // if (error.response.status === 401 &&
      //   error.response.data.status === "fail" &&
      //   error.response.config.url.indexOf('/auth/refresh') !== -1) {
      //   dispatch(logout());
      // }

      // if (error.response.status === 401 && !originalRequest._retry &&
      //   error.response.config.url.indexOf('/auth/refresh') === -1) {
      //   originalRequest._retry = true;
      //   let response = axios.post(config.apiURL + 'auth/refresh', {
      //     refreshToken: refreshToken,
      //   }).then(res => {
      //     localStorage.setItem('token', res.data.data.accessToken);
      //     localStorage.setItem('refreshToken', res.data.data.refreshToken);
      //     return axios(originalRequest);
      //   });

      //   resolve(response);
      // }

      return Promise.reject(error)
    });
  }
);