export const terminal_types = [{
    id: 0,
    name: "Hər ikisi"
}, {
    id: "enter",
    name: "Giriş"
}, {
    id: "exit",
    name: "Çıxış"
}]

export const log_types = [{
    id: "onTimeComers",
    name: "İşə vaxtında gələnlər"
}, {
    id: "lateComers",
    name: "İşə gecikənlər"
}, {
    id: "earlyLeaves",
    name: "İşdən tez çıxanlar"
}, {
    id: "lateLeaves",
    name: "İşdən gec çıxanlar (dünən üçün)"
}, {
    id: "earlyComers",
    name: "İşə tez gələnlər"
}, {
    id: "filo",
    name: "İş saatı"
}, {
    id: "absent",
    name: "Gəlməyənlər"
},]


export const conditionTypes =
    [
        { id: 1, name: 'Zəif' },
        { id: 2, name: 'Orta' },
        { id: 3, name: 'Yaxşı' },
        { id: 4, name: 'Ela' }
    ]