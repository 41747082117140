import React from 'react';
import {
    Button,
    Form,
    Label
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faUndo
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import BootstrapTable from 'react-bootstrap-table-next';

class AreaEmployeeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            total: 0,
            rows: 10,
            page: 1,
            data: null,
            areaList: [],
            selectedArea: null,
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getAreaList();
    };

    getAreaList = () => {
        axios.get(config.apiURL + `api/area/list?register=1&per_page=500&page=1`)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        areaList: res.data.data.data,
                    })
                }
            })
    }

    getData = (page, rows, e) => {

        if (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            let data = {};

            for (const [key, value] of formData.entries()) {
                data[key] = value;
            }

            if (!this.state.selectedArea) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-danger m-3">
                        <h5>Uğursuz əməliyyat!</h5>
                        <p className="mb-0">Terminal seçilməyib.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                return
            }

            this.setState({ isLoadingData: true })
            axios.get(config.apiURL + `api/area/${this.state.selectedArea.id}/employees`).then(res => {

                // console.log('LOG => ', res);

                if (res.data.success) {
                    this.setState({
                        data: res.data.data,
                    });
                };
            }).finally(() => this.setState({ isLoadingData: false }));
        }
    };

    setRowCount = event => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    };

    resetForm = () => {
        this.formRef.current.reset();
        this.setState({
            data: null,
            selectedArea: null,
            isLoadingData: false,
        })
    }

    render() {
        const columns = [{
            dataField: 'user_id',
            text: 'ID',
        }, {
            dataField: 'user_name',
            text: 'Ad, soyad',
            sort: true,
        }];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Terminal istifadəçiləri</h1>
                            </div>
                        </div>
                    </div>
                    <Form
                        className="row"
                        innerRef={this.formRef}
                        onSubmit={e => this.getData(this.state.page, this.state.rows, e)}
                    >
                        <div className="col-md-4 mb-4">
                            <Label className="font-weight-bold">Terminal</Label>
                            <Select
                                placeholder="Seç"
                                value={this.state.selectedArea}
                                options={this.state.areaList}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                onChange={(e) => this.setState({ selectedArea: e })}
                            />
                        </div>
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <Button
                                type='submit'
                                color='primary'
                                className='ml-2'
                            >
                                <FontAwesomeIcon icon={faSearch} className="mr-2" /> Axtar
                            </Button>
                            <Button
                                type='button'
                                color='light'
                                className='ml-2'
                                onClick={() => this.resetForm()}
                            >
                                <FontAwesomeIcon icon={faUndo} className="mr-2" /> Axtarışı sıfırla
                            </Button>
                        </div>
                    </Form>
                    <div className="row mb-4">
                        {
                            !this.state.isLoadingData ?
                                <>
                                    {
                                        this.state.data ?
                                            this.state.data.length ?
                                                <div className="col-md-12">
                                                    <p>Nəticə: <span className='text-bold'>{this.state.data.length}</span></p>
                                                    <BootstrapTable
                                                        bootstrap4
                                                        striped
                                                        keyField='id'
                                                        data={this.state.data}
                                                        {...tableConfig}
                                                        columns={columns}
                                                    />
                                                </div>
                                                :
                                                <div className="col-md-12">
                                                    <div className="alert alert-warning p-4 mt-4">
                                                        Not found data.
                                                    </div>
                                                </div>
                                            :
                                            ''
                                    }
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center mt-4">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default AreaEmployeeList;