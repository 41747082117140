import React from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Label } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config } from "../../../config";
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { errorMessages, successMessages } from "../../../utils/httpMessages";

class CompanyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      data: null,
      isLoading: false,
      selectedPosition: null,
      selectedCompany: null,
      selectedRole: null,
      isShowPassword: false,
      positionList: [],
      companyList: [],
      roleList: [],
    };
  }

  componentDidMount() {
    this.getData();
    // this.getListData();
  }

  getListData() {
    axios
      .get(config.apiURL + `api/yacht/list`, {
        params: {
          page: 1,
          per_page: 10000,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ companyList: res.data.data.data });
        }
      });

    axios
      .get(config.apiURL + `api/position/list`, {
        params: {
          page: 1,
          per_page: 10000,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ positionList: res.data.data.data });
        }
      });

    axios
      .get(config.apiURL + `api/roles`, {
        params: {
          page: 1,
          per_page: 10000,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ roleList: res.data.data });
        }
      });
  }

  getData = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.apiURL + `users/${this.id}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            selectedCompany: res.data.data.company,
            selectedPosition: res.data.data.position,
            selectedRole: res.data.data.role,
          });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  editData = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value.trim();
    }

    if (!data.name) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Name")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.surname) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Surname")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.username) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Username")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    axios.put(config.apiURL + `users/${this.id}`, data).then((res) => {
      if (res.data.success) {
        toast.notify(
          () => (
            <div className="alert alert-success m-3">
              <h5>{successMessages.success}</h5>
              <p className="mb-0">
                {successMessages.updated("User")}
              </p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        this.props.history.push("/system-users/list");
      }
    });
  };

  render() {
    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">System user details</h1>
              </div>
            </div>
          </div>
          {!this.state.isLoading ? (
            <>
              {this.state.data ? (
                <Form className="row" onSubmit={(e) => this.editData(e)}>
                  <div className="col-md-6 col-lg-6 mb-4">
                    <Label for="name" className="font-weight-bold">
                      Name
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      defaultValue={this.state.data.name}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 mb-4">
                    <Label for="surname" className="font-weight-bold">
                      Surname
                    </Label>
                    <Input
                      id="surname"
                      name="surname"
                      placeholder="Enter surname"
                      defaultValue={this.state.data.surname}
                    />
                  </div>
                  <div className="form-group col-md-6 col-lg-6 mb-4 position-relative">
                    <Label for="password" className="font-weight-bold">
                      Password
                    </Label>
                    <Input
                      id="password"
                      type={this.state.isShowPassword ? "text" : "password"}
                      name="password"
                      autoComplete="new-password"
                      placeholder="******"
                      defaultValue={this.state.data.password}
                    />
                    <FontAwesomeIcon
                      icon={this.state.isShowPassword ? faEye : faEyeSlash}
                      className="eye-icon"
                      onClick={() => {
                        this.setState({
                          isShowPassword: !this.state.isShowPassword,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 mb-4">
                    <Label for="Username" className="font-weight-bold">
                      Email
                    </Label>
                    <Input
                      id="Username"
                      name="Username"
                      placeholder="Enter username"
                      type="text"
                      defaultValue={this.state.data.username}
                    />
                  </div>
                  <div className="col-md-12 mb-4 d-flex justify-content-end">
                    <Link
                      to="/system-users/list"
                      className="btn btn-light font-weight-bold"
                    >
                      Cancel
                    </Link>
                    <Button
                      type="submit"
                      color="primary"
                      className="font-weight-bold ml-2"
                    >
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />{" "}
                      Submit
                    </Button>
                  </div>
                </Form>
              ) : (
                <div className="col-12">
                  <div className="alert alert-warning p-3">
                    Not found
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="alert alert-info p-4 text-center">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => store;
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit);
