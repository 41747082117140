import React from 'react';
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import classNames from 'classnames';
import NavBar from './Navbar';
import Home from "../pages/Home";
import { routes } from '../../routes';

class Content extends React.Component {
  render() {
    return (
      <div className={classNames('content', { 'is-open': this.props.isOpen })}>
        <>
          <NavBar toggle={this.props.toggle} />
          <Route path="/" exact component={Home} />
          {
            routes.map((route, index) =>
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
            )
          }
        </>
      </div>
    );
  }
}

const mapStateToProps = store => store;
export default connect(mapStateToProps)(Content);
