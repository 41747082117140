if (typeof window !== 'undefined') {
    try {
        // Create a custom date formatter with the desired timezone
        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'Asia/Dubai',
            hour12: false, // Optionally, you can specify 24-hour format
        });

        // Extend the Date prototype to use the custom date formatter
        Date.prototype.toLocaleString = function () {
            return customDateFormatter.format(this);
        };
    } catch (e) {
        console.error('Error setting default timezone:', e);
    }
}