import React from "react";
import { Button, Form, Input, Label } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config } from "../../../config";
import toast from "toasted-notes";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import az from "date-fns/locale/az";
registerLocale("az", az);
// import "react-datepicker/dist/react-datepicker.css";

class PermitCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeList: [],
      selectedEmployee: null,
      // start_date: new Date(),
      // end_date: new Date(),
    };
  }

  componentDidMount() {
    this.getEmployeeList();
  }

  getEmployeeList = () => {
    axios.get(config.apiURL + "api/employees/search").then((res) => {
      if (res.data.success) {
        this.setState({ employeeList: res.data.data });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.selectedEmployee) {
      toast.notify(
        ({ onClose }) => (
          <div className="alert alert-danger m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Əməkdaş seçilməyib.</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    if (!this.state.start_date) {
      toast.notify(
        ({ onClose }) => (
          <div className="alert alert-danger m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Başlama tarixi seçilməyib.</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    if (!this.state.end_date) {
      toast.notify(
        ({ onClose }) => (
          <div className="alert alert-danger m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Başlama tarixi seçilməyib.</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (!data.description) {
      toast.notify(
        ({ onClose }) => (
          <div className="alert alert-danger m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">İcazə ilə bağlı qeyd daxil edilməyib.</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    data["employeeId"] = this.state.selectedEmployee.id;
    data["startDate"] = moment(this.state.start_date).format(
      "yyyy-MM-DD HH:mm"
    );
    data["endDate"] = moment(this.state.end_date).format("yyyy-MM-DD HH:mm");

    // console.log('DATA =>', data);

    axios.post(config.apiURL + "api/leaveDays", data).then((res) => {
      if (res.data.success) {
        toast.notify(
          ({ onClose }) => (
            <div className="alert alert-success m-3">
              <h5>Uğurlu əməliyyat!</h5>
              <p className="mb-0">İcazə uğurla əlavə edildi.</p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        this.props.history.push("/permit/list");
      }
    });
  };

  render() {
    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">İcazə əlavə et</h1>
              </div>
            </div>
          </div>
          <Form className="row" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="col-md-4 mb-4">
              <Label className="font-weight-bold">Əməkdaş</Label>
              <Select
                placeholder="Seç"
                value={this.state.selectedEmployee}
                options={this.state.employeeList}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.fullname}
                onChange={(e) => this.setState({ selectedEmployee: e })}
              />
            </div>
            <div className="col-md-4 mb-4">
              <Label className="font-weight-bold">Başlama tarixi</Label>
              <DatePicker
                selected={this.state.start_date}
                onChange={(e) => this.setState({ start_date: e })}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="gün/ay/il"
                timeCaption="Saat"
              />
            </div>
            <div className="col-md-4 mb-4">
              <Label className="font-weight-bold">Bitmə tarixi</Label>
              <DatePicker
                selected={this.state.end_date}
                onChange={(e) => this.setState({ end_date: e })}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="gün/ay/il"
                timeCaption="Saat"
              />
            </div>
            <div className="col-md-12 mb-4">
              <Label className="font-weight-bold">Qeyd</Label>
              <Input
                type="textarea"
                name="description"
                rows={4}
                placeholder="İcazə ilə bağlı qeyd daxil edin"
              />
            </div>
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                className="font-weight-bold ml-2"
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
              </Button>
              <Button
                type="button"
                color="light"
                className="ml-2"
                onClick={() => this.props.history.push("/permit/list")}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="mr-2" /> Cancel
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default PermitCreate;
