import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { Button } from 'reactstrap';
import { config } from '../../config';

class CustomUploadInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedData: null,
        }
    };

    async uploadImage(image) {
        if (!image) return;

        if (!this.props.isMulti) {
            let data = {}
            await new Promise(resolve => {
                let reader = new FileReader();
                reader.readAsDataURL(image[0]);
                reader.onloadend = resolve;
            }).then(res => {
                data["file"] = {
                    // "fileName": image[0].name,
                    "fileType": image[0].type,
                    "extension": "." + image[0].name.split('.').pop(),
                    "encodedData": res.target.result.split(',')[1]
                }
            });

            // console.log('upload data', image);

            let resultData = {
                "file": {
                    "fileType": data.file.fileType,
                    "encodedData": data.file.encodedData
                }
            }

            this.props.setStateFunc(resultData);
            this.setState({ uploadedData: resultData })

        } else {
            // documents

            // console.log(image);
            let fileList = [];

            for (let i = 0; i < image.length; i++) {
                let formData = new FormData();
                formData.append("file", image[i]);
                await axios.post(config.apiURL + 'api/file', formData).then(res => {
                    if (res.data.success) {
                        // fileList.push(res.data.data.id)
                        fileList.push(res.data.data)
                    };
                });
            };

            this.props.setStateFunc({
                fileIdList: fileList.map(item => item.id),
                uploadedFiles: fileList
            });
        };
    }

    render() {
        const {
            id,
            name,
            text,
            isMulti,
            isRounded,
            uploadRef,
            isUploadedData,
        } = this.props;

        return (
            <Button
                color="primary"
                block
                className={
                    `upload-input ${isRounded ? 'is-rounded' : ''} ${this.state.uploadedData ? 'p-0' : ''}`
                }
                // onClick={() => {
                //     if (!this.state.uploadedData) {
                //         uploadRef.current.click()
                //     }
                // }}
            >
                {
                    !this.state.uploadedData && !isUploadedData ?
                        <>
                            <span className="text">
                                {
                                    !text ? 'Fayl seç'
                                        :
                                        text
                                }
                            </span>
                            <FontAwesomeIcon icon={faUpload} />
                            <input
                                type="file"
                                id={id}
                                name={name}
                                className="d-none"
                                ref={uploadRef}
                                multiple={isMulti}
                                onChange={(event) => this.uploadImage(event.target.files)}
                            />
                        </>
                        :
                        <img
                            src={`data:${this.state.uploadedData.file.fileType};base64,${this.state.uploadedData.file.encodedData}`}
                            alt="logo"
                        />
                }
            </Button>
        )
    }
}

export default CustomUploadInput
