import React from 'react';
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';


class ActiveEmployeeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            rows: 10,
            page: 1,
            isLoadingData: false,
            isLoadingConfirmData: false,
            selectedRow: null,
            isOpenConfirmModal: false,
            companyList: [],
            positionList: [],
            selectedCompany: null,
            selectedPosition: null,
        };
    }

    componentDidMount() {
        this.getCompanyList();
        this.getPositionList();
        this.getData(1, this.state.rows);
    }

    getData = (page, rows) => {
        this.setState({ isLoadingData: true })
        axios.get(config.apiURL + `api/employees?isActive=0`).then(res => {
            if (res.data.success) {
                this.setState({
                    data: res.data.data.data,
                    page: res.data.data.current_page,
                    total: res.data.data.total,
                })
            }
        }).finally(() => this.setState({ isLoadingData: false }));
    }

    getCompanyList = () => {
        axios.get(config.apiURL + `api/yacht/list?per_page=1000`).then(res => {
            if (res.data.success) {
                this.setState({ companyList: res.data.data.data })
            }
        });
    }

    getPositionList = () => {
        axios.get(config.apiURL + `api/position/list?per_page=1000`).then(res => {
            if (res.data.success) {
                this.setState({ positionList: res.data.data.data })
            }
        });
    }

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    }

    toggleConfirmModal = () => {
        this.setState({ isOpenConfirmModal: !this.state.isOpenConfirmModal });
    }

    confirmData = () => {
        if (!this.state.selectedRow?.id) return;

        this.setState({ isLoadingConfirmData: true })
        axios.post(config.apiURL + `api/employees/confirm`, {
            employeeId: this.state.selectedRow.id,
            company_id: this.state.selectedCompany?.id,
            position_id: this.state.selectedPosition?.id,
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">İstifadəçisi uğurla təsdiq edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleConfirmModal();
            }
        }).finally(() => this.setState({ isLoadingConfirmData: false }));
    }

    modalOnCloseFunc = () => {
        this.setState({
            selectedRow: null,
            selectedCompany: null,
            selectedPosition: null,
        })
    }

    render() {

        const columns = [{
            dataField: 'id',
            text: 'ID',
            // formatter: (cell, row, index) => {
            //   return index + 1
            // },
        }, {
            dataField: 'fullname',
            text: 'Ad, soyad',
            sort: true,
        }, {
            dataField: 'terminal_user_id',
            text: 'FİN',
            sort: true,
        }, {
            dataField: 'created_at',
            text: 'Yaradılma tarixi',
            formatter: (cell) => {
                return cell ? moment(cell).format('DD-MM-YYYY') : ''
            }
        }, {
            dataField: 'browse',
            text: '',
            formatter: (cell, row) => {
                return (
                    <div className="text-center">
                        <Button
                            color='primary'
                            onClick={() => {
                                this.setState({
                                    isOpenConfirmModal: true,
                                    selectedRow: row
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Submit
                        </Button>
                    </div>
                )
            }
        }];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Təsdiq gözləyən istifadəçilər</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            !this.state.isLoadingData ?
                                <>
                                    {
                                        this.state.data?.length ?
                                            <div className="col-12 mt-4">
                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    keyField='id'
                                                    data={this.state.data}
                                                    {...tableConfig}
                                                    columns={columns}
                                                    wrapperClasses="table-responsive"
                                                />
                                            </div>
                                            : <div className="col-12">
                                                <div className="alert alert-info">
                                                    Not found
                                                </div>
                                            </div>
                                    }
                                    {/* confirm modal */}
                                    <Modal
                                        size="lg"
                                        centered
                                        isOpen={this.state.isOpenConfirmModal}
                                        onClosed={this.modalOnCloseFunc}
                                    >
                                        <ModalHeader toggle={this.toggleConfirmModal}>
                                            İstifadəçini təsdiq etmək istədiyinizdən əminsinizmi?
                                        </ModalHeader>
                                        {
                                            this.state.selectedRow ?
                                                <>
                                                    {
                                                        !this.state.isLoadingConfirmData ?
                                                            <>
                                                                <ModalBody>
                                                                    <div className="row pt-2">
                                                                        <div className="col-md-12 mb-4">
                                                                            <p>
                                                                                Əməkdaş:
                                                                                <span className='font-weight-bold'>
                                                                                    {
                                                                                        ` ${this.state.selectedRow.fullname ?? ''} 
                                                                                    ${this.state.selectedRow.terminal_user_id ?? ''}
                                                                                    `
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-md-6 mb-4">
                                                                            <Label className="font-weight-bold">Təmsil etdiyi şirkət</Label>
                                                                            <Select
                                                                                placeholder="Seç"
                                                                                value={this.state.selectedCompany}
                                                                                options={this.state.companyList}
                                                                                getOptionValue={option => option.id}
                                                                                getOptionLabel={option => option.name}
                                                                                onChange={(e) => {
                                                                                    this.setState({ selectedCompany: e });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 mb-4">
                                                                            <Label className="font-weight-bold">Vəzifə</Label>
                                                                            <Select
                                                                                placeholder="Seç"
                                                                                value={this.state.selectedPosition}
                                                                                options={this.state.positionList}
                                                                                getOptionValue={option => option.id}
                                                                                getOptionLabel={option => option.name}
                                                                                onChange={(e) => {
                                                                                    this.setState({ selectedPosition: e });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </ModalBody>
                                                                <ModalFooter className="justify-content-center">
                                                                    <Button color="light" onClick={this.toggleConfirmModal}>Cancel</Button>
                                                                    <Button
                                                                        color="primary"
                                                                        className="font-weight-bold"
                                                                        onClick={this.confirmData}
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </ModalFooter>
                                                            </>
                                                            :
                                                            <div className="alert alert-info p-4 text-center">
                                                                <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div className="alert alert-warning">
                                                    Not found data
                                                </div>
                                        }
                                    </Modal>
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default ActiveEmployeeList;