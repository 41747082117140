import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck, faList,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import { Link } from 'react-router-dom';
import Select from "react-select";
import CustomUploadInput from "../../lib/CustomUploadInput";
import { conditionTypes } from "../../../store/staticData";
import moment from 'moment';

class CompanyView extends React.Component {

    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {
            isDraggingOver: false,
            images: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // if (!this.id || !Number(this.id)) {
        //     return
        // }

        this.setState({ isLoading: true })

        axios.get(config.apiURL + `yachts/${this.id}`).then(res => {
            console.log('res', res);
            if (res.data.success) {
                this.setState({ data: res.data.data })
            }
        }).finally(() => this.setState({ isLoading: false }))
    }

    handleFileSelect = (event) => {
        const files = event.target.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            // setSelectedFiles(selectedFilesArray);
            // dispatch(changeValue("room", "images", selectedFilesArray))
            this.setState({
                images: selectedFilesArray,
            })
        }
    };

    handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            // setSelectedFiles(selectedFilesArray);
            // dispatch(changeValue("room", "images", selectedFilesArray))
            this.setState({
                images: selectedFilesArray,
            })
        }
        this.setState({
            isDraggingOver: false,
        })
    };

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: true,
        })
    };

    handleDragLeave = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: false,
        })

    };

    removeFile = (file) => {
        const arr = this.state.images.filter((selectedFile) => selectedFile !== file)
        this.setState({
            images: arr,
        })
    };

    render() {
        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Yacht details</h1>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.data ? "Data not found"
                            :
                            <Form
                                className="row"
                                onSubmit={e => this.handleSubmit(e)}
                            >
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <Label for='id' className="font-weight-bold">ID</Label>
                                    <Input
                                        name='id'
                                        id='id'
                                        value={this.state.data.id}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <Label for='slug' className="font-weight-bold">Slug</Label>
                                    <Input
                                        name='slug'
                                        id='slug'
                                        value={this.state.data.slug}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <Label for='name' className="font-weight-bold">Name</Label>
                                    <Input
                                        name='name'
                                        id='name'
                                        value={this.state.data.name}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-2 mb-4">
                                    <Label for='guest_limit' className="font-weight-bold">Guest limit</Label>
                                    <Input
                                        name='guest_limit'
                                        id='guest_limit'
                                        value={this.state.data.guest_limit}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-2 mb-4">
                                    <Label for='cabin_count' className="font-weight-bold">Cabins</Label>
                                    <Input
                                        name='cabin_count'
                                        id='cabin_count'
                                        value={this.state.data.cabin_count}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-2 mb-4">
                                    <Label for='length_ft' className="font-weight-bold">Yacht length</Label>
                                    <Input
                                        name='length_ft'
                                        id='length_ft'
                                        value={`${this.state.data.length_ft} ft`}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-2 mb-4">
                                    <Label for='price' className="font-weight-bold">Price</Label>
                                    <Input
                                        name='price'
                                        id='price'
                                        value={`${this.state.data.price} AED`}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 mb-4">
                                    <Label for='description' className="font-weight-bold">Description</Label>
                                    <Input
                                    type='textarea'
                                        name='description'
                                        id='description'
                                        value={this.state.data.description}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <Label for='created_at' className="font-weight-bold">Created at</Label>
                                    <Input
                                        name='created_at'
                                        id='created_at'
                                        value={this.state.data.created_at ? moment(this.state.data.created_at).format("DD-MM-yyyy HH:mm:ss") : ""}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 mb-4">
                                    {
                                        this.state.data.media.length ?
                                            this.state.data.media.map((item, index) => {
                                                return (
                                                    <img
                                                        key={index}
                                                        src={config.imageURL + item.media_path}
                                                        alt="image"
                                                        crossOrigin="anonymous"
                                                        className="img-fluid mr-4 mb-4"
                                                        width={"200"}
                                                        height={"200"}
                                                    />
                                                )
                                            })
                                            :
                                            <div className='alert alert-info'>
                                                Yacht images empty
                                            </div>
                                    }
                                </div>
                            </Form>
                    }
                    <div className="row justify-content-center mb-3 mt-5">
                        <div className="col-3">
                            <Link to="/yacht/list" className="btn btn-light">
                                <FontAwesomeIcon icon={faList} className="mr-2" />
                                Back to list
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CompanyView;