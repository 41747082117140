import React from 'react';
import {
    Button,
    Form,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faEye,
    faPlus,
    faSearch,
    faTrashAlt,
    faUndo
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import CustomPagination from '../../common/CustomPagination';
import { Link } from 'react-router-dom';

class PermitList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            total: 0,
            rows: 10,
            page: 1,
            data: null,
            employeeList: [],
            selectedEmployee: null,
            // start_date: new Date(),
            // end_date: new Date(),
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        // this.getData(this.state.page, this.state.rows);
        this.getData()
        this.getEmployeeList()
    };

    getData = (page, rows, e, searchParams) => {
        if (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            let data = {};

            for (const [key, value] of formData.entries()) {
                data[key] = value;
            }

            if (!this.state.selectedEmployee) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-danger m-3">
                        <h5>Uğursuz əməliyyat!</h5>
                        <p className="mb-0">Əməkdaş seçilməyib.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                return
            }

            this.setState({
                isLoadingData: true,
                searchParams: {
                    page: 1,
                    per_page: rows ?? this.state.rows,
                    employee_id: this.state.selectedEmployee?.id,
                    // start: data.start,
                    // end: data.end,
                    // fullname: data.fullname,
                    // fin: data.fin,
                }
            })

            axios.get(config.apiURL + `api/leaveDays`, {
                params: {
                    page: 1,
                    per_page: rows ?? this.state.rows,
                    employee_id: this.state.selectedEmployee?.id,
                    // start: data.start,
                    // end: data.end,
                    // fullname: data.fullname,
                    // fin: data.fin,
                }
            }).then(res => {
                if (res.status === 200) {
                    this.setState({
                        data: res.data.data,
                        total: res.data.meta.total,
                        page: res.data.meta.current_page
                    });
                };
            }).finally(() => this.setState({ isLoadingData: false }));
        } else {
            this.setState({ isLoadingData: true })

            // console.log('searchParams', searchParams);
            // console.log('this.state.searchParams', this.state.searchParams);

            axios.get(config.apiURL + `api/leaveDays`, {
                params: {
                    employee_id: searchParams?.employee_id,
                    page: page ? page : 1,
                    per_page: rows ? rows : this.state.rows,
                }
            }).then(res => {
                if (res.status === 200) {
                    this.setState({
                        data: res.data.data,
                        total: res.data.meta.total,
                        page: res.data.meta.current_page
                    });
                };
            }).finally(() => this.setState({ isLoadingData: false }));
        }
    };

    getEmployeeList = () => {
        axios.get(config.apiURL + 'api/employees?isActive=1&per_page=2000').then(res => {
            if (res.data.success) {
                this.setState({ employeeList: res.data.data.data })
            }
        })
    }

    setRowCount = event => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    };

    resetForm = () => {
        this.formRef.current.reset();
        this.setState({
            selectedEmployee: null,
            isLoadingData: false,
            searchParams: {}
        })
        this.getData(1, this.state.rows, null, {})
    }

    deleteData = () => {
        if (!this.state.selectedRow) return;
        axios.delete(config.apiURL + `api/leaveDays/${this.state.selectedRow.id}`).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">İcazə uğurla silindi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.setState({ isOpenDeleteModal: false })
                this.getData(1, this.state.rows)
            }
        })
    }

    render() {

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: (cell, row, index) => {
                return index + 1
            },
        }, {
            dataField: 'employee',
            text: 'Ad, soyad',
            sort: true,
        }, {
            dataField: 'description',
            text: 'Qeyd',
            sort: true,
        }, {
            dataField: 'start',
            text: 'Başlama tarixi',
            sort: true,
            formatter: (cell) => {
                return cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : ''
            }
        }, {
            dataField: 'end',
            text: 'Bitmə tarixi',
            sort: true,
            formatter: (cell) => {
                return cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : ''
            }
        }, {
            dataField: 'user',
            text: 'Əməkdaş',
            sort: true,
        }, {
            dataField: 'created_at',
            text: 'Yaradılma tarixi',
            sort: true,
            formatter: (cell) => {
                return cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : ''
            }
        }, {
            dataField: 'browse',
            text: '',
            formatter: (cell, row) => {
                return (
                    <div>
                        <Link
                            to={`/permit/view/${row.id}`}
                            className='btn btn-light btn-sm mr-2 mb-2'
                        >
                            <FontAwesomeIcon icon={faEye} className="mr-2" /> View
                        </Link>
                        <Link
                            to={`/permit/edit/${row.id}`}
                            className='btn btn-light btn-sm mr-2 mb-2'
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
                        </Link>
                        <Button
                            size='sm'
                            color='light'
                            className='mr-2 mb-2'
                            onClick={() => {
                                this.setState({
                                    isOpenDeleteModal: true,
                                    selectedRow: row,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Delete
                        </Button>
                    </div>
                )
            }
        }];

        return (
            <div className="bg-white rounded p-4">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="page-header">
                            <h1 className="font-weight-bold">İcazə günləri</h1>
                            <Link
                                to="/permit/create"
                                className='btn btn-primary font-weight-bold'
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />Create
                            </Link>
                        </div>
                    </div>
                </div>
                <Form
                    className="row"
                    innerRef={this.formRef}
                    onSubmit={e => this.getData(this.state.page, this.state.rows, e)}
                >
                    <div className="col-md-4 mb-4">
                        <Label className="font-weight-bold">Əməkdaş</Label>
                        <Select
                            placeholder="Seç"
                            value={this.state.selectedEmployee}
                            options={this.state.employeeList}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.fullname}
                            onChange={(e) => this.setState({ selectedEmployee: e })}
                        />
                    </div>
                    {/* <div className="col-md-4 mb-4">
                        <Label className="font-weight-bold">Ad, soyad</Label>
                        <Input
                            name='fullname'
                            placeholder='Ad, soyad daxil edin'
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <Label className="font-weight-bold">FİN</Label>
                        <Input
                            name='fin'
                            placeholder='FİN daxil edin'
                        />
                    </div> */}
                    {/* <div className="col-md-4 mb-4">
                        <Label className="font-weight-bold">Başlama tarixi</Label>
                        <Input
                            type='date'
                            name='start'
                            defaultValue={new Date().toISOString().split("T")[0]}
                            onChange={e => moment(e.target.value, "DD-MM-YYYY")}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <Label className="font-weight-bold">Bitmə tarixi</Label>
                        <Input
                            type='date'
                            name='end'
                            defaultValue={new Date().toISOString().split("T")[0]}
                        />
                    </div> */}
                    <div className="col-md-12 mb-4 d-flex justify-content-end">
                        <Button
                            type='submit'
                            color='primary'
                            className='ml-2'
                        >
                            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Axtar
                        </Button>
                        <Button
                            type='button'
                            color='light'
                            className='ml-2'
                            onClick={() => this.resetForm()}
                        >
                            <FontAwesomeIcon icon={faUndo} className="mr-2" /> Axtarışı sıfırla
                        </Button>
                    </div>
                </Form>
                <div className="row mb-4">
                    {
                        !this.state.isLoadingData ?
                            <>
                                {
                                    this.state.data ?
                                        this.state.data.length ?
                                            <>
                                                <div className="col-md-12">
                                                    <BootstrapTable
                                                        bootstrap4
                                                        striped
                                                        keyField='id'
                                                        data={this.state.data}
                                                        {...tableConfig}
                                                        columns={columns}
                                                    />
                                                    <CustomPagination
                                                        total={this.state.total}
                                                        page={this.state.page}
                                                        rows={this.state.rows}
                                                        getData={this.getData}
                                                        setRowCount={this.setRowCount}
                                                        params={this.state.searchParams}
                                                    />
                                                </div>
                                                {/* delete modal */}
                                                <Modal
                                                    size="md"
                                                    centered
                                                    isOpen={this.state.isOpenDeleteModal}
                                                    onClosed={this.modalOnCloseFunc}
                                                >
                                                    <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                                                    {
                                                        this.state.selectedRow ?
                                                            <>
                                                                {
                                                                    !this.state.isLoadingConfirmData ?
                                                                        <>
                                                                            <ModalBody>
                                                                                <div className="row pt-2">
                                                                                    <div className="col-md-12 text-center mb-2">
                                                                                        <h5 className="font-weight-bold mb-4">
                                                                                            Seçilən icazəni silmək istədiyinizdən əminsinizmi?
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <div className="p-2">
                                                                                            <p className='mb-0'>Əməkdaş:
                                                                                                <span className="ml-1 text-bold">
                                                                                                    {this.state.selectedRow.employee ?? ""}
                                                                                                </span>
                                                                                            </p>
                                                                                            <p className='mb-0'>Başlama tarixi:
                                                                                                <span className="ml-1 text-bold">
                                                                                                    {
                                                                                                        this.state.selectedRow.start ?
                                                                                                            moment(this.state.selectedRow.start).format("DD-MM-yyyy HH:mm:ss")
                                                                                                            :
                                                                                                            ''
                                                                                                    }
                                                                                                </span>
                                                                                            </p>
                                                                                            <p className='mb-0'>Bitmə tarixi:
                                                                                                <span className="ml-1 text-bold">
                                                                                                    {
                                                                                                        this.state.selectedRow.end ?
                                                                                                            moment(this.state.selectedRow.end).format("DD-MM-yyyy HH:mm:ss")
                                                                                                            :
                                                                                                            ''
                                                                                                    }
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ModalBody>
                                                                            <ModalFooter className="justify-content-center">
                                                                                <Button color="light" onClick={() => this.setState({ isOpenDeleteModal: false })}>Cancel</Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    className="font-weight-bold"
                                                                                    onClick={this.deleteData}
                                                                                >
                                                                                    Yes
                                                                                </Button>
                                                                            </ModalFooter>
                                                                        </>
                                                                        :
                                                                        <div className="alert alert-info p-4 text-center">
                                                                            <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                                                        </div>
                                                                }
                                                            </>
                                                            :
                                                            <div className="alert alert-warning">
                                                                Not found data
                                                            </div>
                                                    }
                                                </Modal>
                                            </>
                                            :
                                            <div className="col-md-12">
                                                <div className="alert alert-warning p-4 mt-4">
                                                    Not found data.
                                                </div>
                                            </div>
                                        :
                                        null
                                }
                            </>
                            :
                            <div className="col-12">
                                <div className="alert alert-info p-4 text-center mt-4">
                                    <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default PermitList;