export const errorMessages = {
    internal: "Internal server error",
    serverError: `Unexpected error ocoured.`,
    badRequest: `Bad request.`,
    required: (field) => `${field} field is required.`,
    minLength: (field, length) => `${field} length must be at least ${length} character.`,
    maxLength: (field, length) => `${field} length must be maximum ${length} character.`,
    minVal: (field, value) => `${field} value must be at least ${value}.`,
    maxVal: (field, value) => `${field} value must be maximum ${value}.`,
    notFound: (field) => `${field} not found.`,
    notCreated: (field) => `${field} not created.`,
    notUpdated: (field) => `${field} not updated.`,
    notDeleted: (field) => `${field} not deleted.`,
    notUploaded: (file_names) => `Files not uploaded: ${file_names}.`,
    notValid: (field) => `${field} is not valid.`,
    invalidType: (field, validType) => `${field} type must be ${validType}.`,
    passwordsNotMatch: `Passwords not match.`,
    invalidLogin: "Username or password is not valid.",
    unauthorized: "Unauthorized.",
    updatePassword: "You must update your password.",
    samePassword: "New password must be different from old password.",
    exist: (field) => `${field} already exist.`,
    failed: "Failed."
}

export const successMessages = {
    success: "Success.",
    created: (field) => `${field} successfully created.`,
    updated: (field) => `${field} successfully updated.`,
    deleted: (field) => `${field} successfully deleted.`,
}