import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Input,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faTrashAlt,
  faEye,
  faSearch,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, tableConfig } from "../../../config";
import toast from "toasted-notes";
import BootstrapTable from "react-bootstrap-table-next";
import CustomPagination from "../../common/CustomPagination";
import { Link } from "react-router-dom";
import moment from "moment/moment";

class SystemUsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      total: 0,
      rows: 10,
      page: 1,
      isLoadingData: false,
      isLoadingConfirmData: false,
      selectedRow: null,
      isOpenConfirmModal: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getData(1, this.state.rows);
  }

  getData = (page, rows, e) => {
    let data = {};
    this.setState({ isLoadingData: true });

    if (e) {
      e.preventDefault();

      const formData = new FormData(e.target);
      for (const [key, value] of formData.entries()) {
        data[key] = value;
      }
    }

    axios
      .get(config.apiURL + `users`, {
        params: {
          page: page,
          per_page: rows,
          name: data?.name ? data.name : undefined,
          surname: data?.surname ? data.surname : undefined,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data.data,
            page: res.data.data.current_page,
            total: res.data.data.total,
          });
        }
      })
      .finally(() => this.setState({ isLoadingData: false }));
  };

  toggleDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
    });
  };

  setRowCount = (event) => {
    this.setState({ rows: event.target.value });
    this.getData(1, event.target.value);
  };

  toggleConfirmModal = () => {
    this.setState({ isOpenConfirmModal: !this.state.isOpenConfirmModal });
  };

  confirmData = () => {
    if (!this.state.selectedRow?.id) return;

    this.setState({ isLoadingConfirmData: true });
    axios
      .post(config.apiURL + `api/employees/confirm`, {
        employeeId: this.state.selectedRow.id,
      })
      .then((res) => {
        if (res.data.success) {
          toast.notify(
            ({ onClose }) => (
              <div className="alert alert-success m-3">
                <h5>Uğurlu əməliyyat!</h5>
                <p className="mb-0">İstifadəçisi uğurla təsdiq edildi.</p>
              </div>
            ),
            { position: "top-right", duration: 2500 }
          );
          this.getData(this.state.page, this.state.rows);
          this.toggleConfirmModal();
        }
      })
      .finally(() => this.setState({ isLoadingConfirmData: false }));
  };

  deleteData = () => {
    if (!this.state.selectedRow?.id) return;

    this.setState({ isLoadingConfirmData: true });
    axios
      .delete(config.apiURL + `dashboard/vendor/${this.state.selectedRow.id}`)
      .then((res) => {
        console.log("test",);
        if (res.data.success) {
          toast.notify(({ onClose }) => (
            <div className="alert alert-success m-3">
              <h5>Uğurlu əməliyyat!</h5>
              <p className="mb-0">İstifadəçi uğurla silindi.</p>
            </div>
          ), { position: "top-right", duration: 3500 });
          this.getData(1, this.state.rows);
          this.toggleDeleteModal();
        }
      })
      .finally(() => this.setState({ isLoadingConfirmData: false }));
  };

  modalOnCloseFunc = () => {
    this.setState({
      selectedRow: null,
    });
  };

  resetState = () => {
    this.formRef.current.reset();
    this.getData(1, this.state.rows);
  };

  handleBlockUser(id) {
    axios.put(config.apiURL + `api/user/${id}/switchBlock`).then((res) => {
      if (res.data.success) {
        toast.notify(
          () => (
            <div className="alert alert-success m-3">
              <h5>Uğurlu əməliyyat!</h5>
              <p className="mb-0">İstifadəçi uğurla silindi edildi.</p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        this.getData(1, this.state.rows);
      }
    });
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "name",
        text: "Fullname",
        sort: true,
        formatter: (cell, row) => {
          return `${row.name} ${row.surname ? row.surname : ""}`;
        },
      },
      {
        dataField: "username",
        text: "Username",
      },
      {
        dataField: "created_at",
        text: "Created at",
        formatter: (cell) => cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : ''
      },
      // {
      //   dataField: "browse",
      //   text: "",
      //   formatter: (cell, row) => {
      //     return (
      //       <div className="text-center">
      //         <Link
      //           to={`/system-users/view/${row.id}`}
      //           className="btn btn-light btn-sm mr-2"
      //         >
      //           <FontAwesomeIcon icon={faEye} className="mr-2" /> View
      //         </Link>
      //         <Link
      //           to={`/system-users/edit/${row.id}`}
      //           className="btn btn-light btn-sm mr-2"
      //         >
      //           <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
      //         </Link>
      //         <Button
      //           size="sm"
      //           color="light"
      //           className="mr-2"
      //           onClick={() => {
      //             this.setState({
      //               isOpenDeleteModal: true,
      //               selectedRow: row,
      //             });
      //           }}
      //         >
      //           <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Delete
      //         </Button>
      //         <Button
      //           size="sm"
      //           color="light"
      //           className="mr-2"
      //           onClick={() => this.handleBlockUser(row.id)}
      //         >
      //           <FontAwesomeIcon
      //             icon={row.is_blocked === 0 ? faLock : faLockOpen}
      //             className="mr-2"
      //           />{" "}
      //           {row.is_blocked === 0 ? "Deaktiv et" : "Aktiv et"}
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];

    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">System users</h1>
                {/* <Link
                  to="/system-users/create"
                  className="btn btn-primary font-weight-bold"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Create
                </Link> */}
              </div>
            </div>
          </div>
          {/* <Form
            className="row"
            innerRef={this.formRef}
            onSubmit={(e) => this.getData(this.state.page, this.state.rows, e)}
          >
            <div className="col-md-4 mb-4">
              <Label className="font-weight-bold">Ad</Label>
              <Input
                name="name"
                placeholder="Ad daxil edin"
                ref={this.nameInput}
              />
            </div>
            <div className="col-md-4 mb-4">
              <Label className="font-weight-bold">Soyad</Label>
              <Input
                name="surname"
                placeholder="Soyad daxil edin"
                ref={this.surnameInput}
              />
            </div>
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <Button type="submit" color="primary" className="ml-2">
                <FontAwesomeIcon icon={faSearch} className="mr-2" /> Axtar
              </Button>
              <Button
                type="button"
                color="light"
                className="ml-2"
                onClick={this.resetState}
              >
                <FontAwesomeIcon icon={faUndo} className="mr-2" /> Axtarışı
                sıfırla
              </Button>
            </div>
          </Form> */}
          <div className="row">
            {!this.state.isLoadingData ? (
              <>
                {this.state.data?.length ? (
                  <div className="col-12 mt-4">
                    <BootstrapTable
                      bootstrap4
                      striped
                      keyField="id"
                      data={this.state.data}
                      {...tableConfig}
                      columns={columns}
                      wrapperClasses="table-responsive"
                    />
                    <CustomPagination
                      total={this.state.total}
                      page={this.state.page}
                      rows={this.state.rows}
                      getData={this.getData}
                      setRowCount={this.setRowCount}
                      params={this.state.searchParams}
                    />
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-info">
                      The list is empty.
                    </div>
                  </div>
                )}
                {/* delete modal */}
                <Modal
                  size="md"
                  centered
                  isOpen={this.state.isOpenDeleteModal}
                  onClosed={this.modalOnCloseFunc}
                >
                  <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                  {this.state.selectedRow ? (
                    <>
                      {!this.state.isLoadingConfirmData ? (
                        <>
                          <ModalBody>
                            <div className="row pt-2">
                              <div className="col-md-12 text-center mb-4">
                                <h5 className="font-weight-bold">
                                  Seçilən istifadəçini silmək istədiyinizdən
                                  əminsinizmi?
                                </h5>
                                <p className="font-weight-bold">#{this.state.selectedRow.id} {this.state.selectedRow.name ?? ""} {this.state.selectedRow.surname ?? ""}</p>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter className="justify-content-center">
                            <Button
                              color="light"
                              onClick={this.toggleDeleteModal}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              className="font-weight-bold"
                              onClick={this.deleteData}
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </>
                      ) : (
                        <div className="alert alert-info p-4 text-center">
                          <p
                            className="m-0"
                            style={{ fontSize: "24px", letterSpacing: "1px" }}
                          >
                            Loading...
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="alert alert-warning">Not found data</div>
                  )}
                </Modal>
              </>
            ) : (
              <div className="col-12">
                <div className="alert alert-info p-4 text-center">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SystemUsersList;
