import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faSearch,
    faSync,
    faUndo,
    faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import az from 'date-fns/locale/az'
// import "react-datepicker/dist/react-datepicker.css";
registerLocale('az', az)

class PermitCreate extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;

        this.state = {
            data: null,
            start_date: null,
            end_date: null,
        };
    }

    componentDidMount() {
        axios.get(config.apiURL + `api/leaveDays/${this.id}`).then(res => {
            if (res.data.success) {
                this.setState({
                    data: res.data.data,
                    start_date: res.data.data.start ? new Date(res.data.data.start) : null,
                    end_date: res.data.data.end ? new Date(res.data.data.end) : null,
                })
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.state.start_date) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Başlama tarixi seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        if (!this.state.end_date) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Başlama tarixi seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value
        }

        if (!data.description) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">İcazə ilə bağlı qeyd daxil edilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        data['employee_id'] = this.state.data.employee_id;
        data['startDate'] = moment(this.state.start_date).format("yyyy-MM-DD HH:mm");
        data['endDate'] = moment(this.state.end_date).format("yyyy-MM-DD HH:mm");

        // console.log('DATA =>', data);

        axios.put(config.apiURL + `api/leaveDays/${this.id}`, data).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">İcazə uğurla redakdə edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/permit/list")
            }
        })
    }

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">İcazə məlumatları</h1>
                            </div>
                        </div>
                    </div>
                    <>
                        {
                            !this.state.isLoading ?
                                <>
                                    {
                                        this.state.data ?
                                            <>
                                                <Form
                                                    className="row"
                                                    onSubmit={e => this.handleSubmit(e)}
                                                >
                                                    <div className="col-md-6 col-lg-6 mb-4">
                                                        <Label for='fullname' className="font-weight-bold">Ad, soyad</Label>
                                                        <Input
                                                            id='fullname'
                                                            readOnly
                                                            defaultValue={this.state.data.employee}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-4">
                                                        <Label for='user' className="font-weight-bold">Daxil edən əməkdaş</Label>
                                                        <Input
                                                            id='user'
                                                            readOnly
                                                            defaultValue={this.state.data.user}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-4">
                                                        <Label className="font-weight-bold">Başlama tarixi</Label>
                                                        <DatePicker
                                                            selected={this.state.start_date}
                                                            onChange={e => this.setState({ start_date: e })}
                                                            showTimeSelect
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            locale="az"
                                                            className='form-control'
                                                            placeholderText="gün/ay/il"
                                                            timeCaption="Saat"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-4">
                                                        <Label className="font-weight-bold">Başlama tarixi</Label>
                                                        <DatePicker
                                                            selected={this.state.end_date}
                                                            onChange={e => this.setState({ end_date: e })}
                                                            showTimeSelect
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            locale="az"
                                                            className='form-control'
                                                            placeholderText="gün/ay/il"
                                                            timeCaption="Saat"
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <Label className="font-weight-bold">Qeyd</Label>
                                                        <Input
                                                            type='textarea'
                                                            name='description'
                                                            rows={4}
                                                            placeholder='İcazə ilə bağlı qeyd daxil edin'
                                                            defaultValue={this.state.data.description}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mb-4 d-flex justify-content-end">
                                                        <Button
                                                            type='submit'
                                                            color='primary'
                                                            className='font-weight-bold ml-2'
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
                                                        </Button>
                                                        <Button
                                                            type='button'
                                                            color='light'
                                                            className='ml-2'
                                                            onClick={() => this.props.history.push("/permit/list")}
                                                        >
                                                            <FontAwesomeIcon icon={faUndoAlt} className="mr-2" /> Cancel
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </>
                                            :
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-warning p-3">
                                                        Not found data
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </>
                </div>
            </>
        )
    }
}

export default PermitCreate;