import React from "react";
import { Button, Form, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPrint,
    faSync,
    faTrash,
    faUpload,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config } from "../../../config";
import toast from "toasted-notes";
import Select from "react-select";
import { Link } from "react-router-dom";
import _ from "lodash";

class CreateRole extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            permissionList: [],
            permissions: [],
            isCheckAll: false,
            selectedPermissionsObj: {},
        };

        this.formRef = React.createRef();
        this.uploadDocRef = React.createRef();
    }

    componentDidMount() {
        axios.get(config.apiURL + "roles/permissions").then((res) => {
            if (res.data.success) {

                const groupedData = _.groupBy(res.data.data, 'group_name')
                const inititalSelectedPermissions = {}


                Object.keys(groupedData).forEach(item => {
                    inititalSelectedPermissions[item] = []
                })

                console.log("inititalSelectedPermissions", inititalSelectedPermissions);


                this.setState({
                    permissionList: groupedData,
                    selectedPermissionsObj: inititalSelectedPermissions
                });
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value.trim();
        }

        if (!data.name) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Role name field is required.</p>
                </div>
            ), { position: "top-right", duration: 2500 });
            return;
        }

        let permissionIdList = Object.values(this.state.selectedPermissionsObj).flat();

        if (!permissionIdList.length) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Fail!</h5>
                    <p className="mb-0">Role must have at least 1 permission.</p>
                </div>
            ), { position: "top-right", duration: 2500 });
            return;
        }

        data['permissions'] = permissionIdList;

        this.setState({ loading: true });

        axios.post(config.apiURL + "roles", data).then((res) => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Success!</h5>
                        <p className="mb-0">Role created successfully.</p>
                    </div>
                ),
                    { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/role/list")
            }
        });
    };

    resetForm = e => {
        // this.formRef.current.reset();
        if (e) {
            e.target.reset();
        }
        this.setState({
            isCheckAll: false,
            selectedPermissionsObj: {},
        });
    };

    handleChangeCheckbox = (stateName, value) => {
        this.setState({
            selectedPermissionsObj: {
                ...this.state.selectedPermissionsObj,
                [stateName]: value,
            }
        });
    };

    toggleCheckAll = isChecked => {
        // if (isChecked) {
        // this.state.permissions.map((x) => x.id)

        // console.log('ALL =>', this.state.permissions);

        let obj = {};

        this.state.permissions.forEach(item => obj[item.name] = isChecked)

        this.setState({
            isCheckAll: isChecked,
            selectedPermissionsObj: obj
        })
        // }
    }

    handlePermissionToggle = (group, permissionId) => {
        this.setState(prevState => ({
            selectedPermissionsObj: {
                ...prevState.selectedPermissionsObj,
                [group]: prevState.selectedPermissionsObj[group]
                    ? prevState.selectedPermissionsObj[group].includes(permissionId)
                        ? prevState.selectedPermissionsObj[group].filter(id => id !== permissionId)
                        : [...prevState.selectedPermissionsObj[group], permissionId]
                    : [permissionId],
            },
        }));
    };

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Create new role</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <Form
                                className="row"
                                ref={this.formRef}
                                onSubmit={e => this.handleSubmit(e)}
                            >
                                <div className="col-md-4 mb-4">
                                    <Label for="name" className="font-weight-bold">
                                        Name of role
                                    </Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Enter role name"
                                    />
                                </div>
                                {/* <div className="col-md-12 mb-4">
                                    <div className="page-header">
                                        <span className="font-weight-bold">İcazələr</span>
                                        <label
                                            className={`checkbox-wrapper mr-4`}
                                        >
                                            Hamsını seç
                                            <input
                                                type="checkbox"
                                                checked={this.state.isCheckAll}
                                                // defaultChecked={isChecked}
                                                className="checkbox"
                                                onChange={e => this.toggleCheckAll(e.target.checked)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-md-12 mb-4">
                                    {Object.entries(this.state.permissionList).map(([group, permissions]) => (
                                        <div key={group}>
                                            {/* <h5>{group}</h5> */}
                                            <hr />
                                            {
                                                permissions.map(permission => {
                                                    return (
                                                        <label
                                                            key={permission.id}
                                                            className={`checkbox-wrapper mb-4 mr-4`}
                                                        >
                                                            {permission.label}
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={this.state.selectedPermissionsObj[group].includes(permission.id) || false}
                                                                onChange={() => this.handlePermissionToggle(group, permission.id)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                })
                                            }
                                        </div>
                                    ))}
                                    {/* {
                                        this.state.permissionList.map((item) => {
                                            return (
                                                <label
                                                    key={item.id}
                                                    className={`checkbox-wrapper mb-4 mr-4`}
                                                >
                                                    {item.label}
                                                    <input
                                                        type="checkbox"
                                                        // checked={this.state.selectedPermissionsObj[permission.name]}
                                                        className="checkbox"
                                                    // onChange={(e) => {
                                                    //     this.handleChangeCheckbox(item.name, e.target.checked)
                                                    // }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            );
                                        })} */}
                                </div>
                                {/* submit */}
                                <div className="col-md-12 text-md-right">
                                    <Link
                                        to="/role/list"
                                        className="btn btn-light"
                                    >
                                        Cancel
                                    </Link>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="font-weight-bold ml-2"
                                    >
                                        Create
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreateRole;