import React from 'react';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledDropdown
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTrash,
    faSync,
    faUpload,
    faPlus,
    faTimes,
    faEdit,
    faTrashAlt,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, defaultCurrency, tableConfig } from '../../../config';
import toast from "toasted-notes";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
// import Pagination from '../../common/Pagination';
import CustomPagination from '../../common/CustomPagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { successMessages } from '../../../utils/httpMessages';


class YachtList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            rows: 10,
            page: 1,
            isLoadingData: false,
            isLoadingConfirmData: false,
            selectedRow: null,
            isOpenConfirmModal: false,
        };
    }

    componentDidMount() {
        this.getData(1, this.state.rows);
    }

    getData = (page, rows) => {
        this.setState({ isLoadingData: true })
        axios.get(config.apiURL + `yachts`, {
            params: {
                page: page,
                per_page: rows,
            }
        })
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        data: res.data.data.data,
                        page: res.data.data.current_page,
                        total: res.data.data.total,
                    })
                }
            }).finally(() => this.setState({ isLoadingData: false }));
    }

    toggleDeleteModal = () => {
        this.setState({
            isOpenDeleteModal: !this.state.isOpenDeleteModal
        })
    }

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    }

    toggleConfirmModal = () => {
        this.setState({ isOpenConfirmModal: !this.state.isOpenConfirmModal });
    }

    deleteData = () => {
        if (!this.state.selectedRow?.id) return;

        this.setState({ isLoadingConfirmData: true })
        axios.delete(config.apiURL + `yachts`, {
            data: {
                id_list: [this.state.selectedRow.id]
            }
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.deleted("Yacht")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleDeleteModal();
            }
        }).finally(() => this.setState({ isLoadingConfirmData: false }));
    }

    modalOnCloseFunc = () => {
        this.setState({
            selectedRow: null,
        })
    }

    render() {
        const columns = [{
            dataField: 'id',
            text: 'ID',
        }, {
            dataField: 'media',
            text: '',
            formatter: (cell, row) => {
                if (!cell.length || !cell[0]?.media_path) return ''
                return (
                    <img
                        src={config.imageURL + cell[0].media_path}
                        alt={row.name}
                        width={70}
                        height={70}
                        style={{ objectFit: "cover" }}
                    />
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
        }, {
            dataField: 'slug',
            text: 'Slug',
        }, {
            dataField: 'price',
            text: 'Price',
            formatter: (cell) => cell ? `${cell} / Hour` : ""
        }, {
            dataField: 'guest_limit',
            text: 'Guest limit',
            formatter: (cell) => cell ? `${cell} person${cell > 1 ? "s" : ""}` : ""
        }, {
            dataField: 'created_at',
            text: 'Created On',
            formatter: (cell) => cell ? moment(cell).format('DD/MM/YYYY') : ''
        }, {
            dataField: 'browse',
            text: '',
            formatter: (cell, row) => {
                return (
                    <div className="text-center">
                        <Link
                            to={`/yacht/view/${row.id}`}
                            className='btn btn-light btn-sm mr-2'
                        >
                            <FontAwesomeIcon icon={faEye} className="mr-2" /> View
                        </Link>
                        <Link
                            to={`/yacht/edit/${row.id}`}
                            className='btn btn-light btn-sm mr-2'
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
                        </Link>
                        <Button
                            size='sm'
                            color='light'
                            className='mr-2'
                            onClick={() => {
                                this.setState({
                                    isOpenDeleteModal: true,
                                    selectedRow: row
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Delete
                        </Button>
                    </div>
                )
            }
        }];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Yachts</h1>
                                <Link
                                    to="/yacht/create"
                                    className='btn btn-primary font-weight-bold'
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Create
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            !this.state.isLoadingData ?
                                <>
                                    {
                                        this.state.data?.length ?
                                            <div className="col-12 mt-4">
                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    keyField='id'
                                                    data={this.state.data}
                                                    {...tableConfig}
                                                    columns={columns}
                                                    wrapperClasses="table-responsive"
                                                />
                                                <CustomPagination
                                                    total={this.state.total}
                                                    page={this.state.page}
                                                    rows={this.state.rows}
                                                    getData={this.getData}
                                                    setRowCount={this.setRowCount}
                                                    params={this.state.searchParams}
                                                />
                                            </div>
                                            : <div className="col-12">
                                                <div className="alert alert-info">
                                                    Not found
                                                </div>
                                            </div>
                                    }
                                    {/* delete modal */}
                                    <Modal
                                        size="md"
                                        centered
                                        isOpen={this.state.isOpenDeleteModal}
                                        onClosed={this.modalOnCloseFunc}
                                    >
                                        <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                                        {
                                            this.state.selectedRow ?
                                                <>
                                                    {
                                                        !this.state.isLoadingConfirmData ?
                                                            <>
                                                                <ModalBody>
                                                                    <div className="row pt-2">
                                                                        <div className="col-md-12 text-center mb-4">
                                                                            <h5 className="font-weight-bold">
                                                                                Are you sure to delete the selected data?
                                                                            </h5>
                                                                            <p className="font-weight-bold">#{this.state.selectedRow.id} {this.state.selectedRow.name ?? ""} {this.state.selectedRow.surname ?? ""}</p>
                                                                        </div>
                                                                    </div>
                                                                </ModalBody>
                                                                <ModalFooter className="justify-content-center">
                                                                    <Button color="light" onClick={this.toggleDeleteModal}>Cancel</Button>
                                                                    <Button
                                                                        color="primary"
                                                                        className="font-weight-bold"
                                                                        onClick={this.deleteData}
                                                                    >
                                                                        Yes
                                                                    </Button>
                                                                </ModalFooter>
                                                            </>
                                                            :
                                                            <div className="alert alert-info p-4 text-center">
                                                                <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div className="alert alert-warning">
                                                    Not found data
                                                </div>
                                        }
                                    </Modal>
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default YachtList;