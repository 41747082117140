import React from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Label } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { config } from "../../../config";
import { Link } from "react-router-dom";
import { errorMessages, successMessages } from "../../../utils/httpMessages";

class CompanyCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPosition: null,
      selectedCompany: null,
      selectedRole: null,
      isShowPassword: false,
      isReadOnly: true,
      positionList: [],
      companyList: [],
      roleList: [],
      departmentList: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value.trim();
    }

    if (!data.name) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Name")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.surname) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Surname")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.username) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("username")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }
    if (!data.password) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("password")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    axios.post(config.apiURL + "users", data).then((res) => {
      if (res.data.success) {
        toast.notify(
          () => (
            <div className="alert alert-success m-3">
              <h5>{successMessages.success}</h5>
              <p className="mb-0">{successMessages.created("User")}</p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        this.props.history.push("/system-users/list");
      }
    });
  };

  componentDidMount() {
  }

  render() {
    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">Create system user</h1>
              </div>
            </div>
          </div>

          <Form
            className="row"
            onSubmit={(e) => this.handleSubmit(e)}
            autoComplete="off"
          >
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="name" className="font-weight-bold">
                Name <span className="text-danger">*</span>
              </Label>
              <Input id="name" name="name" placeholder="Enter name" />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="surname" className="font-weight-bold">
                Surname <span className="text-danger">*</span>
              </Label>
              <Input
                id="surname"
                name="surname"
                placeholder="Enter surname"
              />
            </div>
            <div className="form-group col-md-6 col-lg-6 mb-4 position-relative">
              <Label for="password" className="font-weight-bold">
                Password <span className="text-danger">*</span>
              </Label>
              <Input
                id="password"
                type={this.state.isShowPassword ? "text" : "password"}
                name="password"
                autoComplete="new-password"
                placeholder="******"
              />
              <FontAwesomeIcon
                icon={this.state.isShowPassword ? faEye : faEyeSlash}
                className="eye-icon"
                onClick={() => {
                  this.setState({
                    isShowPassword: !this.state.isShowPassword,
                  });
                }}
              />
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Label for="username" className="font-weight-bold">
                Username <span className="text-danger">*</span>
              </Label>
              <Input
                id="username"
                name="username"
                type="text"
                placeholder="Enter username"
              />
            </div>
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <Link
                to="/system-users/list"
                className="btn btn-light font-weight-bold"
              >
                Cancel
              </Link>
              <Button
                type="submit"
                color="primary"
                className="font-weight-bold ml-2"
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => store;
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
