import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Input,
  Label,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faTrashAlt,
  faEye,
  faSearch,
  faUndo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, defaultCurrency, orderStatusTypes, tableConfig } from "../../../config";
import toast from "toasted-notes";
import BootstrapTable from "react-bootstrap-table-next";
import CustomPagination from "../../common/CustomPagination";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { errorMessages, successMessages } from "../../../utils/httpMessages";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../../lib/Switch/Switch";

const OrderList = () => {

  const columns = useMemo(() => ([
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell) => {
        const status = orderStatusTypes.find(x => x.key === cell)
        if(!status) return cell

        return <Badge color={status.class}>{status.label}</Badge>
      }
    },
    {
      dataField: "fullname",
      text: "Fullname",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "yacht_name",
      text: "Yacht",
    },
    {
      dataField: "total_price",
      text: "Total",
      formatter: (cell) => cell ? `${cell} ${defaultCurrency}` : ""
    },
    {
      dataField: "from_date",
      text: "From",
      formatter: (cell) => cell ? moment(cell).format('DD-MM-YYYY') : ''
    },
    {
      dataField: "from_hour",
      text: "",
      formatter: (cell, row) => row.from_date ? moment(row.from_date).format('HH:mm') : ''
    },
    {
      dataField: "to_date",
      text: "To",
      formatter: (cell) => cell ? moment(cell).format('DD-MM-YYYY') : ''
    },
    {
      dataField: "to_hour",
      text: "",
      formatter: (cell, row) => row.to_date ? moment(row.to_date).format('HH:mm') : ''
    },
    {
      dataField: "browse",
      text: "",
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <Link
              to={`/order/view/${row.id}`}
              className="btn btn-light btn-sm mr-2"
            >
              <FontAwesomeIcon icon={faEye} className="mr-2" /> View
            </Link>
          </div>
        );
      },
    },
  ]), [])

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    row: 10,
    total: 0,
  })
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [searchParams, setSearchParams] = useState({})
  const [phone, setPhone] = useState('')
  const [yachtList, setYAchtList] = useState([])
  const [selectedYacht, setSelectedYacht] = useState(null)
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [startHour, setStartHour] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endHour, setEndHour] = useState('')
  const [reservationList, setReservationList] = useState([])
  const [isFullDay, setIsFullDay] = useState(false)
  const [maxDuration, setMaxDuration] = useState(0)
  const [optionList, setOptionList] = useState([])
  const [selectedOptionValues, setSelectedOptionValues] = useState({})
  const [isExistClient, setIsExistClient] = useState(false)
  const [clientList, setClientList] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)


  useEffect(() => {
    getData(1, pagination.row)
    // getYachtList()
    // getOptions()
    // getClients()
  }, [])

  const getData = (page, rows, e) => {
    let data = {};

    if (e) {
      e.preventDefault();

      const formData = new FormData(e.target);
      for (const [key, value] of formData.entries()) {
        data[key] = value;
      }
    }

    axios.get(config.apiURL + `orders`, {
      params: {
        page: page,
        per_page: rows,
        name: data?.name ?? undefined,
        surname: data?.surname ?? undefined,
      },
    }).then((res) => {
      setData(res.data.data.data)
      setPagination(prevState => ({
        page: res.data.data.current_page,
        row: res.data.data.per_page,
        total: res.data.data.total,
      }))
    })
  };

  // const getYachtList = () => {
  //   axios.get(config.apiURL + `yachts`, {
  //     params: {
  //       per_page: 1000,
  //     }
  //   }).then(res => setYAchtList(res.data.data.data))
  // }

  // const getOptions = () => {
  //   axios.get(config.apiURL + 'options', { params: { per_page: 1000 } }).then(res => setOptionList(res.data.data.data))
  // }

  // const getClients = () => {
  //   axios.get(config.apiURL + 'admin/customers', { params: { per_page: 1000 } }).then(res => setClientList(res.data.data.data))
  // }

  // const toggleCreateModal = useCallback(() => {
  //   setIsOpenCreateModal(prevState => !prevState)
  // }, [])

  const setRowCount = useCallback(event => {
    setPagination(prevState => ({
      ...prevState,
      row: event.target.value,
    }))
    getData(1, event.target.value)
  }, [])

  // const modalOnClose = useCallback(() => {
  //   setSelectedRow(null)
  //   setReservationList([])
  // }, [])

  // const handleChangePhone = useCallback(phone => {
  //   setPhone(phone)
  // }, [])

  // const createData = () => {

  // }

  // const renderOptions = useCallback((limit) => {
  //   const arr = []
  //   for (let index = 0; index < limit; index++) {
  //     arr.push(index + 1)
  //   }

  //   return arr.map(item => (
  //     <option key={item}>{item}</option>
  //   ))
  // }, [])

  // const renderStartHourOptions = () => {
  //   const currentHour = moment().hours();

  //   return Array.from({ length: 24 }, (_, index) => {
  //     const currentDateTime = moment(startDate).set({ hour: index, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss");
  //     const isReserved = reservationList.includes(currentDateTime);

  //     return (
  //       <option
  //         key={index}
  //         value={index}
  //         disabled={
  //           moment(startDate).isSame(moment().format('YYYY-MM-DD')) &&
  //           (index <= currentHour || isReserved)
  //         }
  //       >
  //         {index < 10 ? `0${index}` : index}:00
  //       </option>
  //     );
  //   });
  // }

  // const renderEndHourOptions = () => {
  //   const currentHour = moment().hours()

  //   if (!isFullDay) {
  //     return Array.from({ length: 24 }, (_, index) => {
  //       const currentDateTime = moment(startDate).set({ hour: index, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss");
  //       const isReserved = reservationList.includes(currentDateTime);
  //       const isBeforeStartDate = !moment(startDate).set({ hour: startHour, minute: 0, second: 0 }).isBefore(moment(startDate).set({ hour: index, minute: 0, second: 0 }))

  //       console.log("startDate", startDate)

  //       return <option
  //         key={index}
  //         value={index}
  //         disabled={
  //           // moment(startDate).isSame(moment().format('YYYY-MM-DD')) &&
  //           (index <= currentHour || isReserved || isBeforeStartDate)
  //         }
  //       >
  //         {index < 10 ? `0${index}` : index}:00
  //       </option>
  //     })
  //   }

  //   return Array.from({ length: 24 }, (_, index) => {
  //     const currentDateTime = moment(startDate)
  //       .set({ hour: index, minute: 0, second: 0 })
  //       .format("YYYY-MM-DD HH:mm:ss");

  //     const isReserved = reservationList.includes(currentDateTime);
  //     const isInvalidDate = moment(currentDateTime).isBefore(
  //       moment(startDate).set({ hour: startHour, minute: 0, second: 0 })
  //     );

  //     return (
  //       <option
  //         key={index}
  //         value={index}
  //         disabled={isInvalidDate || isReserved}
  //       >
  //         {index < 10 ? `0${index}` : index}:00
  //       </option>
  //     );
  //   });

  // }

  // const handleChangeYacht = e => {
  //   setSelectedYacht(e)
  //   setStartHour('')
  //   setEndHour('')
  //   getYachtOrders(e.id, startDate)
  // }

  // const getYachtOrders = (yacht_id, date) => {
  //   axios.get(config.apiURL + `yachts/${yacht_id}/orders?date=${date}`)
  //     .then(resp => {
  //       const reservedDates = [];

  //       resp.data.data.orders.forEach((reservation) => {
  //         const fromDateTime = moment(reservation.from_date);
  //         const toDateTime = moment(reservation.to_date);

  //         while (fromDateTime.isBefore(toDateTime)) {
  //           reservedDates.push(moment(fromDateTime).format("YYYY-MM-DD HH:mm:ss"));
  //           fromDateTime.add(1, 'hour');
  //         }
  //       });

  //       setReservationList(reservedDates)
  //     })
  // }

  // const toggleFullDay = () => setIsFullDay(prevState => !prevState)

  // useEffect(() => {
  //   if (startHour) {
  //     let duration = 0
  //     let date = moment(!isFullDay ? startDate : endDate).set({ hour: Number(startHour) + 1, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss")

  //     console.log("reservationList", reservationList);

  //     for (let i = 0; i < 720; i++) { // 30 days as hours
  //       console.log("date => ", date);
  //       if (reservationList.includes(date)) {
  //         break
  //       } else {
  //         date = moment(date).add(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  //         duration += 1
  //       }
  //     }

  //     setMaxDuration(duration)

  //     console.log("duration", duration);
  //   }
  // }, [startHour])

  // const handleChangeEndHours = (value) => {
  //   const fromDate = moment(startDate).set({ hour: startHour, minute: 0, second: 0 })
  //   const toDate = moment(!isFullDay ? startDate : endDate).set({ hour: value, minute: 0, second: 0 })
  //   const duration = moment.duration(toDate.diff(fromDate))
  //   let diff_hour = duration.hours()
  //   if (duration.minutes()) diff_hour = duration.hours() + 1

  //   // console.log("DIIFFF", diff_hour);

  //   if (maxDuration < diff_hour) {
  //     setEndHour('')
  //     toast.notify(() => (
  //       <div className="alert alert-danger m-3">
  //         <h5>{errorMessages.failed}</h5>
  //         <p className="mb-0">End hour is not valid. End hours max be {`${Number(startHour) + maxDuration}:00`}</p>
  //       </div>
  //     ), { position: "top-right", duration: 3500 });
  //     return
  //   }

  //   setEndHour(value)
  // }

  // const handleChangeAddOns = (option_id, option_value) => {
  //   setSelectedOptionValues(prevState => ({ ...prevState, [option_id]: option_value }))
  // }

  return (
    <>
      <div className="bg-white rounded p-4">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="page-header">
              <h1 className="font-weight-bold">Orders</h1>
              {/* <Button
                color="primary"
                className="font-weight-bold"
                onClick={toggleCreateModal}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Booking 
              </Button>*/}
              <Link
                to="/order/create"
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Booking
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {
            data?.length ? (
              <div className="col-12 mt-4">
                <BootstrapTable
                  bootstrap4
                  striped
                  keyField="id"
                  data={data}
                  {...tableConfig}
                  columns={columns}
                  wrapperClasses="table-responsive"
                />
                <CustomPagination
                  total={pagination.total}
                  page={pagination.page}
                  rows={pagination.row}
                  getData={getData}
                  setRowCount={setRowCount}
                  params={searchParams}
                />
              </div>
            ) : (
              <div className="col-12">
                <div className="alert alert-info">
                  The list is empty.
                </div>
              </div>
            )
          }
          {/* {
            isOpenCreateModal &&
            <Modal
              size="lg"
              centered
              isOpen={isOpenCreateModal}
              onClosed={modalOnClose}
            >
              <ModalHeader toggle={toggleCreateModal}></ModalHeader>
              <Form onSubmit={e => createData(e)}>
                <ModalBody>
                  <div className="row">
                    <div className='col-12 mb-2'>
                      <div className="d-flex mb-2">
                        <Label className="font-weight-bold d-inline-block mr-4">Client Details</Label>
                        <Switch
                          label="Client"
                          isOpen={isExistClient}
                          handleToggle={() => setIsExistClient(prev => !prev)}
                        />
                      </div>
                    </div>
                    {
                      isExistClient && (
                        <>
                          <div className="col-lg-6 mb-4">
                            <Select
                              placeholder="Select Client"
                              value={selectedClient}
                              options={clientList}
                              isClearable={false}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => `#${option.id} - ${option.fullname}`}
                              onChange={e => setSelectedClient(e)}
                            />
                            {
                              selectedClient &&
                              <div className="bg-light p-4 mt-2">
                                <p className="mb-0">{selectedClient.fullname}</p>
                                <p className="mb-0">{selectedClient.phone}</p>
                                <p className="mb-0">{selectedClient.email}</p>
                              </div>
                            }
                          </div>
                        </>
                      )
                    }
                    {
                      !isExistClient &&
                      <>
                        <div className="col-lg-6 mb-4">
                          <Input
                            name="fullname"
                            placeholder="Full Name"
                          />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <PhoneInput
                            value={phone}
                            defaultCountry="AE"
                            onChange={e => handleChangePhone(e)}
                            className="reactPhoneInput"
                            placeholder="Phone Number"
                            onCountryChange={e => console.log("country", e)}
                          />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                      </>
                    }
                    <div className="col-lg-3 mb-4">
                      <Input
                        type="select"
                      >
                        <option value="" hidden>Adults</option>
                        {renderOptions(20)}
                      </Input>
                    </div>
                    <div className="col-lg-3 mb-4">
                      <Input
                        type="select"
                      >
                        <option value="" hidden>Children</option>
                        {renderOptions(20)}
                      </Input>
                    </div>
                    <div className='col-12 mb-2'>
                      <Label className="font-weight-bold">Yacht Details</Label>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <Select
                        placeholder="Select Yacht"
                        value={selectedYacht}
                        options={yachtList}
                        isClearable={false}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => `#${option.id} - ${option.name} - ${option.price} AED`}
                        onChange={handleChangeYacht}
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <Switch
                        label="Full Day"
                        isOpen={isFullDay}
                        handleToggle={() => {
                          toggleFullDay()
                          setStartHour('')
                          setEndHour('')
                        }}
                      />
                    </div>
                    {
                      !isFullDay ?
                        <>
                          <div className="col-lg-6 mb-4">
                            <Label>Start date</Label>
                            <Input
                              type="date"
                              value={startDate}
                              min={moment().format('YYYY-MM-DD')}
                              disabled={!selectedYacht}
                              onChange={e => {
                                setStartDate(e.target.value)
                                setStartHour('')
                                setEndHour('')
                                setEndDate('')

                                getYachtOrders(selectedYacht?.id, e.target.value)
                              }}
                            />
                          </div>
                          <div className="col-lg-3 mb-4">
                            <Label className="invisible">Start Time</Label>
                            <Input
                              type="select"
                              value={startHour}
                              disabled={!selectedYacht || !startDate}
                              onChange={e => {
                                setStartHour(e.target.value)
                                setEndHour('')
                              }}
                            >
                              <option value="" disabled>
                                Start Time
                              </option>
                              {renderStartHourOptions()}
                            </Input>
                          </div>
                          <div className="col-lg-3 mb-4">
                            <Label className="invisible">End Time</Label>
                            <Input
                              value={endHour}
                              type="select"
                              disabled={!startHour || !selectedYacht}
                              onChange={e => handleChangeEndHours(e.target.value)}
                            >
                              <option value="" disabled>
                                End Time
                              </option>
                              {renderEndHourOptions()}
                            </Input>
                          </div>
                        </>
                        :
                        <>
                          <div className="col-lg-3 mb-4">
                            <Label>Start date</Label>
                            <Input
                              type="date"
                              value={startDate}
                              min={moment().format('YYYY-MM-DD')}
                              disabled={!selectedYacht}
                              onChange={e => {
                                setStartDate(e.target.value)
                                setStartHour('')
                                setEndDate('')
                                setEndHour('')

                                getYachtOrders(selectedYacht?.id, e.target.value)
                              }}
                            />
                          </div>
                          <div className="col-lg-3 mb-4">
                            <Label className="invisible">Start Time</Label>
                            <Input
                              type="select"
                              value={startHour}
                              disabled={!selectedYacht || !startDate}
                              onChange={e => {
                                setStartHour(e.target.value)
                                setEndHour('')
                              }}
                            >
                              <option value="" disabled>
                                Start Time
                              </option>
                              {renderStartHourOptions()}
                            </Input>
                          </div>

                          <div className="col-lg-3 mb-4">
                            <Label>End date</Label>
                            <Input
                              type="date"
                              value={endDate}
                              min={startDate}
                              disabled={!selectedYacht || !startDate || !startHour}
                              onChange={e => {
                                setEndDate(e.target.value)
                                setEndHour('')
                              }}
                            />
                          </div>
                          <div className="col-lg-3 mb-4">
                            <Label className="invisible">End Time</Label>
                            <Input
                              type="select"
                              value={endHour}
                              disabled={!selectedYacht || !startDate || !startHour || !endDate}
                              onChange={e => handleChangeEndHours(e.target.value)}
                            >
                              <option value="" disabled>
                                End Time
                              </option>
                              {renderEndHourOptions()}
                            </Input>
                          </div>
                        </>
                    }
                  </div>
                  {
                    optionList.map(item => (
                      <div key={item.id} className="row">
                        <div className='col-12 mb-2'>
                          <Label className="font-weight-bold">{item.name} Details</Label>
                        </div>
                        <div className="col-lg-7 mb-4">
                          <Select
                            placeholder={`Select ${item.name}`}
                            value={selectedOptionValues[item.id]}
                            options={item.option_values}
                            isClearable={false}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => `${option.name} - ${option.price} AED`}
                            onChange={e => handleChangeAddOns(item.id, e)}
                          />
                        </div>
                        <div className="col-lg-2 mb-4">
                          <Input
                            type="number"
                            defaultValue={1}
                            placeholder="Quantity"
                            min={1}
                          />
                        </div>
                        <div className="col-lg-3 mb-4">
                          <Button
                            type="button"
                            color="light"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                        <div className="col-lg-12 mb-4">
                          <Button
                            type="button"
                            className="btn-outline-primary"
                            style={{
                              minWidth: "170px",
                              textAlign: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add New Item
                          </Button>
                        </div>
                      </div>
                    ))
                  }
                  <div className="bg-light p-4 mb-4">
                    <div className="row">
                      <div className='col-12 mb-2'>
                        <Label className="font-weight-bold">Payment Details</Label>
                      </div>
                      <div className='col-lg-7 mb-2'>
                        <Input
                          type="select"
                          className="form-controll"
                        >
                          <option value="" hidden>Select Payment Category</option>
                          <option value="100">Fully Paid</option>
                          <option value="100">50% Depozit</option>
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-12 mb-2'>
                      <Label className="font-weight-bold">Additional Details</Label>
                      <Input
                        type="textarea"
                        placeholder="Message"
                        className="bg-light"
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="light"
                    onClick={toggleCreateModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="font-weight-bold"
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          } */}
        </div>
      </div >
    </>
  );
}

export default OrderList