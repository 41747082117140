import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

function CustomPagination(props) {

    const {
        total,
        page,
        rows,
        getData,
        setRowCount,
        params,
    } = props;

    const [pagination, setPagination] = useState({
        page: 1,
        rows: 10,
        total: 0,
        selected_page: -1,
    });

    useEffect(() => {
        setPagination(prevState => {
            return {
                page: Number(page),
                rows: Number(rows),
                total: Math.ceil(total / rows)
            }
        })
    }, [total, page, rows])

    useEffect(() => {
        if (page) {
            setPagination(prevState => {
                return {
                    ...prevState,
                    selected_page: Number(page) - 1
                }
            })
        }
    }, [page])

    const handleChange = ({ selected }, rows) => {
        getData(selected + 1, rows ?? pagination.rows, null, params);
    }

    return (
        <div className='pro-pagination-wrapper'>
            <select
                className="d-inline"
                onChange={setRowCount}
                value={pagination.rows}
            >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel="..."
                pageCount={pagination.total}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handleChange}
                containerClassName="pro-pagination"
                pageClassName="page-item"
                previousClassName="page-btn"
                nextClassName="page-btn"
                // nextLinkClassName="page-item"
                breakClassName="page-btn"
                // breakLinkClassName='page-link'
                activeClassName='active'
                forcePage={pagination.selected_page}
            />
        </div>
    )
}

export default CustomPagination