import React from "react";
import { connect } from "react-redux";
import SideBar from "./components/common/SideBar";
import Content from "./components/common/Content";
import Login from "./components/pages/Login";
import "./assets/bootstrap/bootstrap.scss";
import "./assets/scss/style.scss";
import { HashRouter } from "react-router-dom";
import "./utils/defaultTimezone"

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: window.innerWidth > 900,
      notificationList: [],
    };
  }

  updateDimensions = () => {
    this.setState({ isOpen: window.innerWidth > 1100 });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  componentDidMount() {
  }

  render() {
    window.addEventListener("resize", this.updateDimensions);

    return this.props.isAuth ? (
      <div className="wrapper">
        {this.props.isLoading.wrapper && <div className="lds-dual-ring"></div>}
        <HashRouter>
          <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
          <Content toggle={this.toggle} isOpen={this.state.isOpen} />
        </HashRouter>
      </div>
    ) : (
      <Login />
    );
  }
}

const mapStateToProps = (store) => store;

export default connect(mapStateToProps)(App);
