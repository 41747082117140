import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck, faList,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import moment from 'moment';

class PermitView extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;

        this.state = {
            data: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ isLoading: true })
        axios.get(config.apiURL + `api/leaveDays/${this.id}`).then(res => {
            console.log('res', res);
            if (res.data.success) {
                this.setState({ data: res.data.data })
            }
        }).finally(() => this.setState({ isLoading: false }))
    }

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">İcazə məlumatları</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            !this.state.isLoading ?
                                <>
                                    {
                                        this.state.data ?
                                            <>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='fullname' className="font-weight-bold">Ad, soyad</Label>
                                                    <Input
                                                        id='fullname'
                                                        readOnly
                                                        defaultValue={this.state.data.employee}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='user' className="font-weight-bold">Daxil edən əməkdaş</Label>
                                                    <Input
                                                        id='user'
                                                        readOnly
                                                        defaultValue={this.state.data.user}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='start' className="font-weight-bold">Başlama tarixi</Label>
                                                    <Input
                                                        id='start'
                                                        readOnly
                                                        defaultValue={
                                                            this.state.data.start ? moment(this.state.data.start).format("DD-MM-yyyy HH:mm:ss") : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='end' className="font-weight-bold">Bitmə tarixi</Label>
                                                    <Input
                                                        id='end'
                                                        readOnly
                                                        defaultValue={
                                                            this.state.data.end ? moment(this.state.data.end).format("DD-MM-yyyy HH:mm:ss") : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 mb-4">
                                                    <Label for='created_at' className="font-weight-bold">Yaradılma tarixi</Label>
                                                    <Input
                                                        id='created_at'
                                                        readOnly
                                                        defaultValue={
                                                            this.state.data.created_at ? moment(this.state.data.created_at).format("DD-MM-yyyy HH:mm") : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-lg-12 mb-4">
                                                    <Label for='description' className="font-weight-bold">İcazə ilə bağlı qeyd</Label>
                                                    <Input
                                                        id='description'
                                                        type='textarea'
                                                        rows="4"
                                                        readOnly
                                                        defaultValue={this.state.data.description}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <div className="col-12">
                                                <div className="alert alert-warning p-3">
                                                    Not found data
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="row justify-content-center mb-3 mt-5">
                        <div className="col-3">
                            <Link
                                to="/permit/list"
                                className='btn btn-light'
                            >
                                <FontAwesomeIcon icon={faList} className="mr-2" />
                                Back to list
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PermitView;