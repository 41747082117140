export const config = process.env.NODE_ENV === 'production' ? {
  // apiURL: "https://api.bulud.pro/api/v1/",
  // imageURL: "https://api.bulud.pro/uploads/",

  apiURL: "https://api.dubaiyachts.com/api/v1/",
  imageURL: "https://api.dubaiyachts.com/uploads/",
} : {
  // apiURL: "http://localhost:8080/api/v1/",
  // imageURL: "http://localhost:8080/uploads/",

  apiURL: "https://api.dubaiyachts.com/api/v1/",
  imageURL: "https://api.dubaiyachts.com/uploads/",
}

export const staticDataUrls = {
  // test: "api/v1/test"
}

export const tableConfig = {
  bordered: false,
  wrapperClasses: "table-responsive",
  noDataIndication: "No data",
}

export const cellEditConfig = {
  mode: 'click',
  blurToSave: true
}

export const defaultCurrency = 'AED'

export const orderStatusTypes = [
  {
    key: 'pending',
    label: 'Pending',
    class: 'primary'
  },
  {
    key: 'pending_for_cash',
    label: 'Pending For Cash',
    class: 'primary'
  },
  {
    key: 'rejected',
    label: 'Rejected',
    class: 'danger'
  },
  {
    key: 'payment_expired',
    label: 'Payment Expired',
    class: 'secondary'
  },
  {
    key: 'payment_failed',
    label: 'Payment Failed',
    class: 'danger'
  },
  {
    key: 'not_fully_paid',
    label: 'Not Fully Paid',
    class: 'warning'
  },
  {
    key: 'payment_completed',
    label: 'Payment Completed',
    class: 'success'
  },
  {
    key: 'canceled',
    label: 'Customer Canceled',
    class: 'danger'
  },
  {
    key: 'completed',
    label: 'Completed',
    class: 'success'
  }
];