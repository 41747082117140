import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { login } from '../../store/action';
import { config } from '../../config';
import { Button, Form, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import toast from 'toasted-notes';
import logo from '../../assets/img/logo.png';


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      loading: false,
      isShowPassword: false,
      isShowResetPassword: false,
      isShowConfirmResetPassword: false,
      isRememberMe: false,
      resetPasswordValue: null,
    }
  }

  handleLogin = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value;
    };

    this.setState({ loading: true });
    axios.post(config.apiURL + 'auth/login', data).then(res => {
      if (res.data.success) {
        let userObj = {
          fullname: `${res.data.data.user.name ?? ''} ${res.data.data.user.surname ?? ''}`,
          role: {
            name: res.data.data.role?.name,
            label: res.data.data.role?.label,
          },
          permissions: res.data.data.role?.permissions,
        }
        localStorage.setItem('token', res.data.data.access_token);
        localStorage.setItem('refreshToken', res.data.data.refresh_token);
        localStorage.setItem('role', userObj.role);
        localStorage.setItem('user', JSON.stringify(userObj));
        localStorage.setItem('permissions', JSON.stringify(userObj.permissions));

        this.props.login(userObj);
      }
    }).catch(error => {
      this.setState({ loading: false });
      // toast.notify(({ onClose }) => (
      //   <div className="alert alert-danger m-3">
      //     <h5>Failed!</h5>
      //     <p className="mb-0">{error.response.message}</p>
      //   </div>), { position: "top-right", duration: 2500 }
      // );
    });
  }

  render() {
    return (
      <div className="login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-5">
              <div className="login-wrapper p-4 p-md-5">
                <div className="form-logo">
                  <img src={logo} alt="logo" />
                </div>
                <h4 className="text-center text-primary">Welcome</h4>
                <p className="text-center mb-4">Please enter your username and password correctly.</p>
                <Form onSubmit={(e) => this.handleLogin(e)}>
                  <div className="form-group">
                    <Label for="username" className="font-weight-bold">Username</Label>
                    <Input
                      id="username"
                      name="username"
                      placeholder="Enter your username"
                    />
                  </div>
                  <div className="form-group position-relative">
                    <Label for="password" className="font-weight-bold">Password</Label>
                    <Input
                      id="password"
                      name="password"
                      type={this.state.isShowPassword ? "text" : "password"}
                      placeholder="******"
                      onChange={(e) => {
                        this.setState({ newPasswordValue: e.target.value })
                      }}
                    />
                    <FontAwesomeIcon
                      icon={this.state.isShowPassword ? faEye : faEyeSlash}
                      className="eye-icon"
                      onClick={() => {
                        this.setState({
                          isShowPassword: !this.state.isShowPassword
                        })
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <Button
                      color="primary"
                      className="font-weight-bold mt-4"
                      block
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: (role) => dispatch(login(role))
  }
}

export default connect(null, mapDispatchToProps)(Login);