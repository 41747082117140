import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Input,
    Label,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faEdit,
    faTrashAlt,
    faEye,
    faSearch,
    faUndo,
    faPen,
    faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, tableConfig } from "../../../config";
import toast from "toasted-notes";
import BootstrapTable from "react-bootstrap-table-next";
import CustomPagination from "../../common/CustomPagination";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import Select from 'react-select'
import PhoneInput from "react-phone-number-input";
import { errorMessages, successMessages } from "../../../utils/httpMessages";

class ClientList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            rows: 10,
            page: 1,
            selectedRow: null,
            isOpenCategoryModal: false,
            isOpenViewModal: false,
            isOpenEditModal: false,
            isOpenCreateModal: false,

            phone: "",
            categoryList: [],
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getCategoryList();
        this.getData(1, this.state.rows);
    }

    getData = (page, rows, e) => {
        let data = {};
        this.setState({ isLoadingData: true });

        if (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            for (const [key, value] of formData.entries()) {
                data[key] = value;
            }
        }

        axios
            .get(config.apiURL + `admin/customers`, {
                params: {
                    page: page,
                    per_page: rows,
                    name: data?.name ? data.name : undefined,
                    surname: data?.surname ? data.surname : undefined,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        data: res.data.data.data,
                        page: res.data.data.current_page,
                        total: res.data.data.total,
                    });
                }
            })
            .finally(() => this.setState({ isLoadingData: false }));
    };

    getCategoryList = () => {
        axios.get(config.apiURL + 'admin/customer-categories', {
            params: {
                per_page: 1000,
            }
        })
            .then(res => this.setState({
                categoryList: res.data.data.data
            }))
    }

    toggleDeleteModal = () => {
        this.setState({
            isOpenDeleteModal: !this.state.isOpenDeleteModal,
        });
    };

    toggleViewModal = () => {
        this.setState({
            isOpenViewModal: !this.state.isOpenViewModal,
        });
    };

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    };

    toggleCreateModal = () => {
        this.setState({ isOpenCreateModal: !this.state.isOpenCreateModal });
    };

    toggleEditModal = () => {
        this.setState({ isOpenEditModal: !this.state.isOpenEditModal });
    };

    deleteData = () => {
        if (!this.state.selectedRow?.id) return;

        axios.delete(config.apiURL + 'admin/customers', {
            data: {
                id_list: [this.state.selectedRow.id]
            }
        }).then((res) => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.deleted("Client")}</p>
                    </div>
                ), { position: "top-right", duration: 3500 });
                this.getData(1, this.state.rows);
                this.toggleDeleteModal();
            }
        })
    };

    handleModalCLose = () => {
        this.setState({
            selectedRow: null,
            selectedCategory: null,
            phone: ""
        })
    };

    createData = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.fullname?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Client Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!this.state.phone) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Phone")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.email?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Email")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!this.state.selectedCategory) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Client")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.post(config.apiURL + 'admin/customers', {
            fullname: data.fullname,
            phone: this.state.phone,
            email: data.email,
            category_id: this.state.selectedCategory.id,
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.created("Client")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleCreateModal();
                this.setState({
                    selectedCategory: null,
                    phone: ""
                })
            }
        });
    }

    editData = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.fullname?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Client Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!this.state.phone) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Phone")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.email?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Email")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!this.state.selectedCategory) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Client")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.put(config.apiURL + `admin/customers/${this.state.selectedRow.id}`, {
            fullname: data.fullname,
            phone: this.state.phone,
            email: data.email,
            category_id: this.state.selectedCategory.id,
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.updated("Client")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleEditModal();
                this.setState({
                    selectedCategory: null,
                    phone: ""
                })
            }
        });
    }

    render() {
        const columns = [
            {
                dataField: "id",
                text: "ID",
            },
            {
                dataField: "fullname",
                text: "Fullname",
            },
            {
                dataField: "phone",
                text: "Phone Number",
            },
            {
                dataField: "email",
                text: "Email",
            },
            {
                dataField: "last_visit",
                text: "Last Visit",
                formatter: (cell) => {
                    if (!cell) return '';
                    return moment(cell).format("DD/MM/YYYY")
                }
            },
            {
                dataField: "first_visit",
                text: "First Visit",
                formatter: (cell) => {
                    if (!cell) return '';
                    return moment(cell).format("DD/MM/YYYY")
                }
            },
            {
                dataField: "category",
                text: "Category",
                formatter: (cell) => {
                    if (!cell) return ''
                    return (
                        <Badge
                            style={{
                                backgroundColor: cell.bg_color,
                                padding: '.5rem',
                                fontSize: '.75rem'
                            }}
                        >
                            {cell.name}
                        </Badge>
                    )
                }
            },
            // {
            //     dataField: "created_at",
            //     text: "Created at",
            //     formatter: (cell) => cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : ''
            // },
            {
                dataField: "browse",
                text: "",
                formatter: (cell, row) => {
                    return (
                        <div className="text-center">
                            <UncontrolledDropdown>
                                <DropdownToggle color="primary">...</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({ selectedRow: row });
                                            this.toggleViewModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-2" />
                                        View
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({
                                                selectedRow: row,
                                                phone: row.phone,
                                                selectedCategory: row.category
                                            });
                                            this.toggleEditModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPen} className="mr-2" />
                                        Edit
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({ selectedRow: row });
                                            this.toggleDeleteModal();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                        Delete
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    );
                },
            },
        ];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Client List</h1>
                                <Button
                                    color='primary'
                                    className='font-weight-bold'
                                    onClick={() => this.setState({ isOpenCreateModal: true })}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add New Client
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/* <Form
                        className="row"
                        innerRef={this.formRef}
                        onSubmit={(e) => this.getData(this.state.page, this.state.rows, e)}
                    >
                        <div className="col-md-3 mb-4">
                            <Input
                                name="fullname"
                                placeholder="Fullname"
                                ref={this.nameInput}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <Select
                                placeholder="Category"
                                value={this.state.selectedYacht}
                                options={this.state.yachtList}
                                isClearable={true}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={e => { }}
                            />
                        </div>
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <Button type="submit" color="primary" className="ml-2">
                                <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
                            </Button>
                            <Button
                                type="button"
                                color="light"
                                className="ml-2"
                                onClick={this.resetState}
                            >
                                <FontAwesomeIcon icon={faUndo} className="mr-2" /> Reset
                            </Button>
                        </div>
                    </Form> */}
                    <div className="row">
                        {!this.state.isLoadingData ? (
                            <>
                                {this.state.data?.length ? (
                                    <div className="col-12 mt-4">
                                        <BootstrapTable
                                            bootstrap4
                                            striped
                                            keyField="id"
                                            data={this.state.data}
                                            {...tableConfig}
                                            columns={columns}
                                            wrapperClasses="table-responsive"
                                        />
                                        <CustomPagination
                                            total={this.state.total}
                                            page={this.state.page}
                                            rows={this.state.rows}
                                            getData={this.getData}
                                            setRowCount={this.setRowCount}
                                            params={this.state.searchParams}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-12">
                                        <div className="alert alert-info">
                                            The list is empty.
                                        </div>
                                    </div>
                                )}
                                {/* detail modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenViewModal}
                                    onClosed={this.handleModalCLose}
                                >
                                    <ModalHeader toggle={this.toggleViewModal}>Client Info</ModalHeader>
                                    {
                                        this.state.selectedRow &&
                                        <ModalBody>
                                            <div className="row pt-2">
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        readOnly
                                                        defaultValue={this.state.selectedRow.fullname}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        readOnly
                                                        defaultValue={this.state.selectedRow.phone}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        readOnly
                                                        defaultValue={this.state.selectedRow.email}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    {
                                                        this.state.selectedRow.category &&
                                                        <Badge
                                                            style={{
                                                                backgroundColor: this.state.selectedRow.category.bg_color,
                                                                padding: ".5rem",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            {this.state.selectedRow.category.name}
                                                        </Badge>
                                                    }
                                                </div>
                                            </div>
                                        </ModalBody>
                                    }
                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            className="font-weight-bold"
                                            onClick={this.toggleViewModal}
                                        >
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                {/* create modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenCreateModal}
                                    onClosed={this.handleModalCLose}
                                >
                                    <ModalHeader toggle={this.toggleCreateModal}>Add New Client</ModalHeader>
                                    <Form onSubmit={e => this.createData(e)}>
                                        <ModalBody>
                                            <div className="row pt-2">
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        name="fullname"
                                                        placeholder="Client Name"
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <PhoneInput
                                                        value={this.state.phone}
                                                        defaultCountry="AE"
                                                        onChange={e => this.setState({ phone: e })}
                                                        className="reactPhoneInput"
                                                        placeholder="Phone number"
                                                        onCountryChange={e => console.log("eee", e)}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Select
                                                        placeholder="Select Category"
                                                        value={this.state.selectedCategory}
                                                        options={this.state.categoryList}
                                                        isClearable={false}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={e => this.setState({ selectedCategory: e })}
                                                    />
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="light"
                                                onClick={this.toggleCreateModal}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                className="font-weight-bold"
                                            >
                                                Save
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                {/* edit modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenEditModal}
                                    onClosed={this.handleModalCLose}
                                >
                                    <ModalHeader toggle={this.toggleEditModal}>Edit Client Info</ModalHeader>
                                    {
                                        this.state.selectedRow &&
                                        <Form onSubmit={e => this.editData(e)}>
                                            <ModalBody>
                                                <div className="row pt-2">
                                                    <div className="col-lg-6 mb-4">
                                                        <Input
                                                            name="fullname"
                                                            placeholder="Client Name"
                                                            defaultValue={this.state.selectedRow.fullname}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <PhoneInput
                                                            value={this.state.phone}
                                                            defaultCountry="AE"
                                                            onChange={e => this.setState({ phone: e })}
                                                            className="reactPhoneInput"
                                                            placeholder="Phone number"
                                                            onCountryChange={e => console.log("eee", e)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <Input
                                                            name="email"
                                                            type="email"
                                                            placeholder="Email"
                                                            defaultValue={this.state.selectedRow.email}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 mb-4">
                                                        <Select
                                                            placeholder="Select Category"
                                                            value={this.state.selectedCategory}
                                                            options={this.state.categoryList}
                                                            isClearable={false}
                                                            getOptionValue={(option) => option.id}
                                                            getOptionLabel={(option) => option.name}
                                                            onChange={e => this.setState({ selectedCategory: e })}
                                                        />
                                                    </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    type="button"
                                                    color="light"
                                                    onClick={this.toggleEditModal}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    className="font-weight-bold"
                                                >
                                                    Save
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    }
                                </Modal>
                                {/* delete modal */}
                                <Modal
                                    size="md"
                                    centered
                                    isOpen={this.state.isOpenDeleteModal}
                                    onClosed={this.handleModalCLose}
                                >
                                    <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                                    {this.state.selectedRow ? (
                                        <>
                                            <ModalBody>
                                                <div className="row pt-2">
                                                    <div className="col-md-12 text-center mb-4">
                                                        <h5 className="font-weight-bold">
                                                            Are you sure to delete the selected data?
                                                        </h5>
                                                        <p className="font-weight-bold">#{this.state.selectedRow.id} {this.state.selectedRow.fullname ?? ""} {this.state.selectedRow.phone ?? ""}</p>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter className="justify-content-center">
                                                <Button
                                                    color="light"
                                                    onClick={this.toggleDeleteModal}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    className="font-weight-bold"
                                                    onClick={this.deleteData}
                                                >
                                                    Yes
                                                </Button>
                                            </ModalFooter>
                                        </>
                                    ) : (
                                        <div className="alert alert-warning">Not found data</div>
                                    )}
                                </Modal>
                            </>
                        ) : (
                            <div className="col-12">
                                <div className="alert alert-info p-4 text-center">
                                    <p
                                        className="m-0"
                                        style={{ fontSize: "24px", letterSpacing: "1px" }}
                                    >
                                        Loading...
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div >
            </>
        );
    }
}

export default ClientList;