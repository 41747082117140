import React from "react";
import {
    Button,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSync,
    faTrash,
    faSearch,
    faEye,
    faEdit,
    faUndo,
    faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, tableConfig } from "../../../config";
import { connect } from "react-redux";
import toast from "toasted-notes";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import CustomPagination from "../../common/CustomPagination";
import moment from "moment";

class ActiveEmployeeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            total: 0,
            rows: 10,
            page: 1,
            isLoadingData: false,
            isLoadingConfirmArea: false,
            selectedRow: null,
            isOpenPermissionsModal: false,
            userList: [],
            selectedUser: null,
            selectedCompany: null,
            selectedRow: null,
            isOpenEditModal: false,
            positionList: [],
            companyList: [],
            selectedConfirmCompany: null,
            selectedPosition: null,
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.getData(1, this.state.rows);
        this.getAreaList();
        this.getUsers();
        this.getListData();
    }

    getData = (page, rows, e, resetForm) => {
        if (e) {
            e.preventDefault();

            const formData = new FormData(e.target);
            let data = {};

            for (const [key, value] of formData.entries()) {
                data[key] = value;
            }

            this.setState({
                isLoadingData: true,
                searchParams: {
                    page: 1,
                    per_page: rows ?? this.state.rows,
                    fullname: this.state.selectedUser ? this.state.selectedUser.fullname : undefined,
                    company_id: this.state.selectedCompany ? this.state.selectedCompany.id : undefined,
                    fin: data.fin,
                },
            });

            axios
                .get(config.apiURL + `api/employees/deleted`, {
                    params: {
                        page: 1,
                        per_page: rows ?? this.state.rows,
                        fullname: this.state.selectedUser ? this.state.selectedUser.fullname : undefined,
                        company_id: this.state.selectedCompany ? this.state.selectedCompany.id : undefined,
                        fin: data.fin,
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            data: res.data.data.data,
                            page: res.data.data.current_page,
                            total: res.data.data.total,
                        });
                    }
                })
                .finally(() => this.setState({ isLoadingData: false }));
        } else {
            if (!resetForm) {
                this.setState({ isLoadingData: true })
                axios
                    .get(config.apiURL + `api/employees/deleted`, {
                        params: {
                            ...this.state.searchParams,
                            page: page ? page : 1,
                            per_page: rows ? rows : this.state.rows,
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                data: res.data.data.data,
                                page: res.data.data.current_page,
                                total: res.data.data.total,
                            });
                        }
                    })
                    .finally(() => this.setState({ isLoadingData: false }));
            } else {
                axios
                    .get(config.apiURL + `api/employees/deleted`, {
                        params: {
                            page: page ? page : 1,
                            per_page: rows ? rows : this.state.rows,
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                data: res.data.data.data,
                                page: res.data.data.current_page,
                                total: res.data.data.total,
                            });
                        }
                    })
                    .finally(() => this.setState({ isLoadingData: false }));
            }
        }
    };

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    };

    togglePermissionsModal = () => {
        this.setState({
            isOpenPermissionsModal: !this.state.isOpenPermissionsModal,
        });
    };

    getAreaList = () => {
        axios
            .get(config.apiURL + `api/area/list?register=0&per_page=1000&page=1`)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        areaList: res.data.data.data,
                    });
                }
            });
    };

    addPermission = () => {
        if (!this.state.selectedRow) return;

        // if (!this.state.selectedAreas) {
        //   toast.notify(({ onClose }) => (
        //     <div className="alert alert-danger m-3">
        //       <h5>Uğursuz əməliyyat!</h5>
        //       <p className="mb-0">Terminal seçilməyib.</p>
        //     </div>), { position: "top-right", duration: 2500 }
        //   );
        //   return;
        // }
        this.setState({ isLoadingConfirmArea: true });
        axios
            .post(config.apiURL + "api/employees/change_area", {
                areaIds: this.state.selectedAreas ? this.state.selectedAreas.map((item) => item.id)
                    : [],
                employeeId: this.state.selectedRow.id,
            })
            .then((res) => {
                toast.notify(
                    ({ onClose }) => (
                        <div className="alert alert-success m-3">
                            <h5>Uğurlu əməliyyat!</h5>
                            <p className="mb-0">İstifadəçi icazələri təyin edildi.</p>
                        </div>
                    ),
                    { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.togglePermissionsModal();
            })
            .catch(err => console.log('err', err))
            .finally(() => this.setState({ isLoadingConfirmArea: false }))
    };

    resetState = () => {
        this.formRef.current.reset();
        this.setState({
            selectedRow: null,
            selectedAreas: [],
            searchParams: null,
            selectedUser: null,
            selectedCompany: null,
        });
        this.getData(1, this.state.rows, null, true);
    };

    getUsers = () => {
        axios.get(config.apiURL + `api/employees/search?isDeleted=1`).then((res) => {
            if (res.data.success) {
                this.setState({
                    userList: res.data.data,
                });
            }
        });
    }

    getListData = () => {
        axios
            .get(config.apiURL + `api/yacht/list`, {
                params: {
                    page: 1,
                    per_page: 1000,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ companyList: res.data.data.data });
                }
            });

        axios
            .get(config.apiURL + `api/position/list`, {
                params: {
                    page: 1,
                    per_page: 1000,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ positionList: res.data.data.data });
                }
            });
    }

    modalOnCloseFunc = () => {
        this.setState({
            selectedRow: null,
            isOpenEditModal: false,
        });
    };

    confirmData = () => {
        if (!this.state.selectedRow?.id) return;

        this.setState({ isLoadingConfirmData: true });
        axios
            .put(
                config.apiURL + `api/employees/update/${this.state.selectedRow.id}`,
                {
                    company_id: this.state.selectedConfirmCompany?.id,
                    position_id: this.state.selectedPosition?.id,
                }
            )
            .then((res) => {
                if (res.data.success) {
                    toast.notify(
                        ({ onClose }) => (
                            <div className="alert alert-success m-3">
                                <h5>Uğurlu əməliyyat!</h5>
                                <p className="mb-0">İstifadəçisi uğurla təsdiq edildi.</p>
                            </div>
                        ),
                        { position: "top-right", duration: 2500 }
                    );
                    this.getData(this.state.page, this.state.rows);
                    this.modalOnCloseFunc();
                }
            })
            .finally(() => this.setState({ isLoadingConfirmData: false }));
    };

    reSyncUser = id => {
        if (!id) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">İstifadəçi seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        this.setState({ isLoadingData: true })
        axios.post(config.apiURL + 'api/employees/resync', {
            employeeId: id
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">İstifadəçi məlumatları sinxronizasiya edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
            }
        }).finally(() => this.setState({ isLoadingData: false }))
    }

    handleExport = () => {
        // if (!this.state.selectedExportDate) {
        //   toast.notify(
        //     () => (
        //       <div className="alert alert-danger m-3">
        //         <h5>Uğursuz əməliyyat!</h5>
        //         <p className="mb-0">Tarix seçilməyib.</p>
        //       </div>
        //     ),
        //     { position: "top-right", duration: 2500 }
        //   );
        //   return;
        // }

        axios
            .get(config.apiURL + 'api/employees/export', {
                params: {
                    export: 1,
                    fullname: this.state.selectedUser?.fullname,
                    company_id: this.state.selectedCompany?.id
                },
                responseType: "blob",
            })
            .then((res) => {
                this.exportExcel(res);
            });
    };

    exportExcel = ({ data }) => {
        const url = window.URL.createObjectURL(
            new Blob([data], { type: "application/xlsx" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
    };

    deleteData = () => {
        if (!this.state.selectedRow?.id) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-success m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Məlumat təyin edilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.delete(config.apiURL + `api/employees/${this.state.selectedRow.id}`).then(res => {
            if (res.data.success) {
                this.getUsers()
                this.getData()
                this.setState({
                    isOpenDeleteModal: false
                })
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Seçilən istifadəçi uğurla silindi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
            }
        })
    }

    render() {
        const columns = [
            {
                dataField: "id",
                text: "ID",
            },
            {
                dataField: "fullname",
                text: "Ad, soyad",
                sort: true,
            },
            {
                dataField: "terminal_user_id",
                text: "FİN",
                sort: true,
            },
            {
                dataField: "company.name",
                text: "Təşkilat",
                sort: true,
            },
            {
                dataField: "deleted_at",
                text: "Silinmə tarixi",
                formatter: (cell) => {
                    return cell ? moment(cell).format("DD-MM-YYYY HH:mm:ss") : "";
                },
            },
            // {
            //     dataField: "browse",
            //     text: "",
            //     formatter: (cell, row) => {
            //         return (
            //             <div className="d-flex justify-content-center">
            //                 {
            //                     this.props.permissions.find(x => x.name === "can_assign_terminal_user") && (
            //                         <div className="text-center">
            //                             <Button
            //                                 color="primary"
            //                                 className="ml-1"
            //                                 onClick={() => {
            //                                     this.setState({
            //                                         isOpenPermissionsModal: true,
            //                                         selectedRow: row,
            //                                         selectedAreas: row.areas,
            //                                     });
            //                                 }}
            //                             >
            //                                 <FontAwesomeIcon icon={faEye} className="mr-2" /> Terminallar
            //                             </Button>
            //                         </div>
            //                     )
            //                 }
            //                 <Button
            //                     color="light"
            //                     className="ml-1"
            //                     onClick={() => {
            //                         this.setState({
            //                             isOpenEditModal: true,
            //                             selectedRow: row,
            //                             selectedPosition: row.position,
            //                             selectedConfirmCompany: row.company,
            //                         });
            //                     }}
            //                 >
            //                     <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
            //                 </Button>
            //                 {
            //                     this.props.permissions.find(x => x.name === "can_resync") &&
            //                     <Button
            //                         color="light"
            //                         className="ml-1"
            //                         onClick={() => this.reSyncUser(row.id)}
            //                     >
            //                         <FontAwesomeIcon icon={faSync} className="mr-2" /> Sinxronlaşdır
            //                     </Button>
            //                 }
            //                 {
            //                     this.props.permissions.find(x => x.name === "can_delete_employee") &&
            //                     <Button
            //                         color="light"
            //                         className="delete-btn ml-1"
            //                         onClick={() => this.setState({
            //                             selectedRow: row,
            //                             isOpenDeleteModal: true
            //                         })}
            //                     >
            //                         <FontAwesomeIcon icon={faTrash} className="mr-2" /> Sil
            //                     </Button>
            //                 }
            //             </div>
            //         );
            //     },
            // },
        ];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Silinən istifadəçilər</h1>
                                {/* <Button color="primary" onClick={this.handleExport}>
                                    <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                    Excell export
                                </Button> */}
                            </div>
                        </div>
                    </div>
                    <Form
                        className="row"
                        innerRef={this.formRef}
                        onSubmit={e => this.getData(this.state.page, this.state.rows, e)}
                    >
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label className="font-weight-bold">Ad, soyad</Label>
                            <Select
                                placeholder="Ad, soyad daxil edin"
                                value={this.state.selectedUser}
                                isClearable={true}
                                options={this.state.userList}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.fullname}
                                onChange={(e) => this.setState({ selectedUser: e })}
                            />
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label className="font-weight-bold">FİN</Label>
                            <Input name="fin" placeholder="FİN daxil edin" />
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label className="font-weight-bold">Təşkilat</Label>
                            <Select
                                placeholder="Təşkilat daxil edin"
                                value={this.state.selectedCompany}
                                isClearable={true}
                                options={this.state.companyList}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => this.setState({ selectedCompany: e })}
                            />
                        </div>
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <Button
                                type="submit"
                                color="primary"
                                className="ml-2"
                                disabled={this.state.isLoadingData}
                            >
                                <FontAwesomeIcon icon={faSearch} className="mr-2" /> Axtar
                            </Button>
                            <Button
                                type="button"
                                color="light"
                                className="ml-2"
                                onClick={this.resetState}
                                disabled={this.state.isLoadingData}
                            >
                                <FontAwesomeIcon icon={faUndo} className="mr-2" />
                                Axtarışı sıfırla
                            </Button>
                        </div>
                    </Form>
                    {
                        !this.state.isLoadingData ?
                            (
                                <>
                                    {
                                        this.state.data?.length ? (
                                            <div className="col-12 mt-4">
                                                {/* <div className="alert alert-info mt-3">
                          <strong>Ümumi istifadəçi sayı: 5</strong>
                      </div> */}
                                                <div className="mb-4">
                                                    <strong>Ümumi istifadəçi sayı: <span className="ml-1">{this.state.total}</span></strong>
                                                </div>
                                                <BootstrapTable
                                                    bootstrap4
                                                    striped
                                                    keyField="id"
                                                    data={this.state.data}
                                                    {...tableConfig}
                                                    columns={columns}
                                                    wrapperClasses="table-responsive"
                                                />
                                                <CustomPagination
                                                    total={this.state.total}
                                                    page={this.state.page}
                                                    rows={this.state.rows}
                                                    getData={this.getData}
                                                    setRowCount={this.setRowCount}
                                                    params={this.state.searchParams}
                                                />
                                            </div>
                                        ) : (
                                            <div className="alert alert-info">
                                                Not found
                                            </div>
                                        )
                                    }
                                    {/* permissions role modal */}
                                    <Modal
                                        size="lg"
                                        centered
                                        isOpen={this.state.isOpenPermissionsModal}
                                        onClosed={() => this.setState({ selectedRow: null })}
                                    >
                                        <ModalHeader toggle={this.togglePermissionsModal}>
                                            İcəzələri təyin et
                                        </ModalHeader>
                                        <>
                                            {
                                                !this.state.isLoadingConfirmArea ?
                                                    this.state.selectedRow ? (
                                                        <>
                                                            <ModalBody>
                                                                <div className="row pt-2">
                                                                    <div className="col-md-12 text-center mb-4">
                                                                        <Select
                                                                            placeholder="Seç"
                                                                            isMulti
                                                                            value={this.state.selectedAreas}
                                                                            options={this.state.areaList}
                                                                            getOptionValue={(option) => option.id}
                                                                            getOptionLabel={(option) => option.name}
                                                                            onChange={(e) =>
                                                                                this.setState({ selectedAreas: e })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter className="justify-content-center">
                                                                <Button
                                                                    color="light"
                                                                    onClick={this.togglePermissionsModal}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    className="font-weight-bold"
                                                                    onClick={this.addPermission}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </ModalFooter>
                                                        </>
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="alert alert-warning">
                                                                    Not found data
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div className="alert alert-info p-4 text-center">
                                                        <p
                                                            className="m-0"
                                                            style={{ fontSize: "24px", letterSpacing: "1px" }}
                                                        >
                                                            Loading...
                                                        </p>
                                                    </div>
                                            }
                                            {

                                            }
                                        </>
                                    </Modal>
                                </>
                            )
                            :
                            (
                                <div className="alert alert-info p-4 text-center">
                                    <p
                                        className="m-0"
                                        style={{ fontSize: "24px", letterSpacing: "1px" }}
                                    >
                                        Loading...
                                    </p>
                                </div>
                            )
                    }
                    {/* edit modal */}
                    <Modal
                        size="lg"
                        centered
                        isOpen={this.state.isOpenEditModal}
                        onClosed={this.modalOnCloseFunc}
                    >
                        <ModalBody>
                            <div className="row pt-2">
                                <div className="col-md-12 mb-4">
                                    <p>
                                        Əməkdaş:
                                        <span className="font-weight-bold">
                                            {` ${this.state.selectedRow?.fullname ?? ""} 
                        ${this.state.selectedRow?.terminal_user_id ?? ""}
                        `}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <Label className="font-weight-bold">
                                        Təmsil etdiyi şirkət
                                    </Label>
                                    {
                                        this.props.permissions.find(x => x.name === "can_change_company") ?
                                            <Select
                                                placeholder="Seç"
                                                value={this.state.selectedConfirmCompany}
                                                options={this.state.companyList}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e) => {
                                                    this.setState({ selectedConfirmCompany: e });
                                                }}
                                            />
                                            :
                                            <Input
                                                disabled
                                                defaultValue={this.state.selectedConfirmCompany?.name}
                                            />
                                    }

                                </div>
                                <div className="col-md-6 mb-4">
                                    <Label className="font-weight-bold">Vəzifə</Label>
                                    <Select
                                        placeholder="Seç"
                                        value={this.state.selectedPosition}
                                        options={this.state.positionList}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => {
                                            this.setState({ selectedPosition: e });
                                        }}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                            <Button color="light" onClick={this.modalOnCloseFunc}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="font-weight-bold"
                                onClick={this.confirmData}
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {/* delete modal */}
                    <Modal
                        size="md"
                        centered
                        isOpen={this.state.isOpenDeleteModal}
                        onClosed={this.modalOnCloseFunc}
                    >
                        <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                        {
                            this.state.selectedRow ?
                                <>
                                    {
                                        !this.state.isLoadingData ?
                                            <>
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-md-12 text-center mb-2">
                                                            <h5 className="font-weight-bold mb-4">
                                                                Seçilən istifadəçini silmək istədiyinizdən əminsinizmi?
                                                            </h5>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="p-2">
                                                                <p className='mb-0'>ID:
                                                                    <span className="ml-1 text-bold">
                                                                        {this.state.selectedRow.id ?? ""}
                                                                    </span>
                                                                </p>
                                                                <p className='mb-0'>Əməkdaş:
                                                                    <span className="ml-1 text-bold">
                                                                        {this.state.selectedRow.fullname ?? ""}
                                                                    </span>
                                                                </p>
                                                                <p className='mb-0'>FİN:
                                                                    <span className="ml-1 text-bold">
                                                                        {this.state.selectedRow.terminal_user_id ?? ""}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter className="justify-content-center">
                                                    <Button color="light" onClick={() => this.setState({ isOpenDeleteModal: false })}>Cancel</Button>
                                                    <Button
                                                        color="primary"
                                                        className="font-weight-bold"
                                                        onClick={this.deleteData}
                                                    >
                                                        Yes
                                                    </Button>
                                                </ModalFooter>
                                            </>
                                            :
                                            <div className="alert alert-info p-4 text-center">
                                                <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                            </div>
                                    }
                                </>
                                :
                                <div className="alert alert-warning">
                                    Not found data
                                </div>
                        }
                    </Modal>
                </div>
            </>
        );
    }
}

const mapStateToProps = (store) => store;
export default connect(mapStateToProps)(ActiveEmployeeList);
