import React from "react";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faList, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, defaultCurrency, tableConfig } from "../../../config";
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { errorMessages, successMessages } from "../../../utils/httpMessages";
import BootstrapTable from "react-bootstrap-table-next";

class CompanyView extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      data: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.apiURL + `options/${this.id}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (!data.name?.trim()) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Name")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    if (!data.price) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Price")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    if (!data.file?.size) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>{errorMessages.failed}</h5>
          <p className="mb-0">{errorMessages.required("Image")}</p>
        </div>
      ), { position: "top-right", duration: 3500 });
      return
    }

    formData.append("option_id", this.state.data.id)

    axios.post(config.apiURL + "option_values", formData).then((res) => {
      if (res.data.success) {
        toast.notify(
          () => (
            <div className="alert alert-success m-3">
              <h5>{successMessages.success}</h5>
              <p className="mb-0">{successMessages.created("Option value")}</p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        this.setState({
          data: {
            ...this.state.data,
            option_values: this.state.data.option_values?.length ? [
              ...this.state.data.option_values,
              res.data.data
            ] : [res.data.data]
          }
        })
        this.toggleModal()
      }
    });
  };

  toggleModal = () => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
    });
  };

  deleteData = () => {
    if (!this.state.selectedRow?.id) return;

    this.setState({ isLoadingConfirmData: true });
    axios
      .delete(config.apiURL + `option_values/${this.state.selectedRow.id}`)
      .then((res) => {
        if (res.data.success) {
          toast.notify(({ onClose }) => (
            <div className="alert alert-success m-3">
              <h5>{successMessages.success}</h5>
              <p className="mb-0">{successMessages.deleted("Option value")}</p>
            </div>
          ), { position: "top-right", duration: 3500 });
          this.setState({
            data: {
              ...this.state.data,
              option_values: this.state.data.option_values.filter(x => x.id !== res.data.data.id)
            }
          })
          this.toggleDeleteModal();
        }
      })
      .finally(() => this.setState({ isLoadingConfirmData: false }));
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "media",
        text: "Image",
        formatter: (cell) => {
          if (!cell?.length) return '';
          return (
            <img
              alt="img"
              crossOrigin="anonymous"
              src={config.imageURL + cell[0].media_path}
              style={{
                width: "75px",
                height: "75px",
                objectFit: "cover"
              }}
            />
          )
        }
      },
      {
        dataField: "name",
        text: "Value",
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (cell) => !cell ? "Free" : `${cell} ${defaultCurrency}`
      },
      {
        dataField: "description",
        text: "Description",
      },
      {
        dataField: "browse",
        text: "",
        formatter: (cell, row) => {
          return (
            <div className="text-center">
              <Button
                size="sm"
                color="light"
                className="mr-2"
                onClick={() => {
                  this.setState({
                    isOpenDeleteModal: true,
                    selectedRow: row,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Delete
              </Button>
              {/* <Button
                size="sm"
                color="light"
                className="mr-2"
                onClick={() => this.handleBlockUser(row.id)}
              >
                <FontAwesomeIcon
                  icon={row.is_blocked === 0 ? faLock : faLockOpen}
                  className="mr-2"
                />{" "}
                {row.is_blocked === 0 ? "Deaktiv et" : "Aktiv et"}
              </Button> */}
            </div>
          );
        },
      },
    ];

    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">Option details</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {!this.state.isLoading ? (
              <>
                {this.state.data ? (
                  <>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="name" className="font-weight-bold">
                        Name
                      </Label>
                      <Input
                        id="name"
                        readOnly
                        defaultValue={this.state.data.name}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="created_at" className="font-weight-bold">
                        Yaradılma tarixi
                      </Label>
                      <Input
                        id="created_at"
                        readOnly
                        defaultValue={this.state.data.created_at ? moment(this.state.data.created_at).format('DD-MM-YYYY HH:mm:ss') : ""}
                      />
                    </div>
                    <div className="col-md-12 mb-12">
                      <Button
                        color="primary"
                        onClick={this.toggleModal}
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />Add
                      </Button>
                    </div>
                    {this.state.data?.option_values.length ? (
                      <div className="col-12 mt-4">
                        <BootstrapTable
                          bootstrap4
                          striped
                          keyField="id"
                          data={this.state.data.option_values}
                          {...tableConfig}
                          columns={columns}
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    ) : (
                      <div className="col-12 my-4">
                        <div className="alert alert-info">
                          Option values empty
                        </div>
                      </div>
                    )}

                    {/* create modal */}
                    <Modal
                      size="md"
                      centered
                      isOpen={this.state.isOpenModal}
                      onClosed={this.modalOnCloseFunc}
                    >
                      <ModalHeader toggle={this.toggleModal}>Add new value</ModalHeader>
                      <Form onSubmit={this.handleSubmit}>
                        <ModalBody>
                          <div className="row pt-2">
                            <div className="col-md-12 mb-4">
                              <Label for="name" className="font-weight-bold">Name</Label>
                              <Input
                                id="name"
                                name="name"
                                placeholder="Enter option value"
                              />
                            </div>
                            <div className="col-md-12 mb-4">
                              <Label for="price" className="font-weight-bold">Price</Label>
                              <Input
                                id="price"
                                type="number"
                                name="price"
                                placeholder="Enter price or 0"
                              />
                            </div>
                            <div className="col-md-12 mb-4">
                              <Label for="description" className="font-weight-bold">Description</Label>
                              <Input
                                id="description"
                                name="description"
                                placeholder="..."
                              />
                            </div>
                            <div className="col-md-12 mb-4">
                              <Label for="file" className="font-weight-bold">Image</Label>
                              <Input
                                id="file"
                                name="file"
                                type="file"
                              />
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                          <Button
                            color="light"
                            onClick={this.toggleModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="font-weight-bold"
                          >
                            Submit
                          </Button>
                        </ModalFooter>
                      </Form>
                    </Modal>
                    {/* delete modal */}
                    <Modal
                      size="md"
                      centered
                      isOpen={this.state.isOpenDeleteModal}
                      onClosed={this.modalOnCloseFunc}
                    >
                      <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                      {this.state.selectedRow ? (
                        <>
                          {!this.state.isLoadingConfirmData ? (
                            <>
                              <ModalBody>
                                <div className="row pt-2">
                                  <div className="col-md-12 text-center mb-4">
                                    <h5 className="font-weight-bold">
                                      Are you sure to delete the selected data?
                                    </h5>
                                    <p className="font-weight-bold">#{this.state.selectedRow.id} {this.state.selectedRow.name ?? ""} {this.state.selectedRow.surname ?? ""}</p>
                                  </div>
                                </div>
                              </ModalBody>
                              <ModalFooter className="justify-content-center">
                                <Button
                                  color="light"
                                  onClick={this.toggleDeleteModal}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  className="font-weight-bold"
                                  onClick={this.deleteData}
                                >
                                  Yes
                                </Button>
                              </ModalFooter>
                            </>
                          ) : (
                            <div className="alert alert-info p-4 text-center">
                              <p
                                className="m-0"
                                style={{ fontSize: "24px", letterSpacing: "1px" }}
                              >
                                Loading...
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="alert alert-warning">Not found data</div>
                      )}
                    </Modal>
                  </>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-warning p-3">
                      Not found data
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="col-12">
                <div className="alert alert-info p-4 text-center">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row justify-content-center mb-3 mt-5">
            <div className="col-3">
              <Link to="/options/list" className="btn btn-light">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Back to list
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyView;
