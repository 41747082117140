import AreaList from "./components/pages/Areas/AreaList";
import EmployeeActive from "./components/pages/Emplooyes/ActiveEmployeeList";
import EmployeeDeactive from "./components/pages/Emplooyes/DeactiveEmployeeList";
import EmployeeDeleted from "./components/pages/Emplooyes/DeletedEmployeeList";
import Log from "./components/pages/Report/Log";
import AreaEmployeeList from "./components/pages/Areas/AreaEmployeeList";
import YachtList from "./components/pages/Yacht/YachtList";
import CompanyCreate from "./components/pages/Yacht/YachtCreate";
import CompanyEdit from "./components/pages/Yacht/YachtEdit";
import CompanyView from "./components/pages/Yacht/YachtView";
import ClientCategoryList from "./components/pages/Client/ClientCategoryList";
import PermitList from "./components/pages/Permit/PermitList";
import PermitCreate from "./components/pages/Permit/PermitCreate";
import PermitView from "./components/pages/Permit/PermitView";
import PermitEdit from "./components/pages/Permit/PermitEdit";
import SystemUsersList from "./components/pages/System-users/SystemUsersList";
import SystemUsersCreate from "./components/pages/System-users/SystemUsersCreate";
import SystemUsersEdit from "./components/pages/System-users/SystemUsersEdit";
import SystemUsersView from "./components/pages/System-users/SystemUsersView";
import RoleList from "./components/pages/Role/RoleList";
import RoleCreate from "./components/pages/Role/RoleCreate";
import WorkHours from "./components/pages/Settings/WorkHours";
import DepartmentList from "./components/pages/Department/DepartmentList";
import DepartmentCreate from "./components/pages/Department/DepartmentCreate";
import DepartmentEdit from "./components/pages/Department/DepartmentEdit";
import DepartmentView from "./components/pages/Department/DepartmentView";
import CitiesList from "./components/pages/Options/OptionList";
import CitiesCreate from "./components/pages/Options/OptionCreate";
import citiesView from "./components/pages/Options/OptionView";
import citiesEdit from "./components/pages/Options/OptionEdit";

import OrderList from "./components/pages/Orders/OrderList";
import OrderCreate from "./components/pages/Orders/OrderCreate";
import OrderView from "./components/pages/Orders/OrderView";
import OrderEdit from "./components/pages/Orders/OrderEdit";
import PromotionList from "./components/pages/Promotions/PromotionList";
import ClientList from "./components/pages/Client/ClientList";

export const routes = [
  {
    path: "/area/list",
    component: AreaList,
    exact: true,
    permissionKey: "area",
  },
  {
    path: "/area/employee/list",
    component: AreaEmployeeList,
    exact: true,
    permissionKey: "terminal_user",
  },
  {
    path: "/emplooyes/active",
    component: EmployeeActive,
    exact: true,
    permissionKey: "accepted_users",
  },
  {
    path: "/emplooyes/deactive",
    component: EmployeeDeactive,
    exact: true,
    permissionKey: "pending_users",
  },
  {
    path: "/emplooyes/deleted",
    component: EmployeeDeleted,
    exact: true,
    permissionKey: "all",
  },
  {
    path: "/log/list",
    component: Log,
    exact: true,
    permissionKey: "employee_logs",
  },
  {
    path: "/yacht/list",
    component: YachtList,
    exact: true,
    permissionKey: "all",
  },
  {
    path: "/yacht/create",
    component: CompanyCreate,
    exact: true,
    permissionKey: "company",
  },
  {
    path: "/yacht/view/:id",
    component: CompanyView,
    exact: true,
    permissionKey: "company",
  },
  {
    path: "/yacht/edit/:id",
    component: CompanyEdit,
    exact: true,
    permissionKey: "company",
  },

  {
    path: "/permit/list",
    component: PermitList,
    exact: true,
    permissionKey: "leave_days",
  },
  {
    path: "/permit/create",
    component: PermitCreate,
    exact: true,
    permissionKey: "leave_days",
  },
  {
    path: "/permit/view/:id",
    component: PermitView,
    exact: true,
    permissionKey: "leave_days",
  },
  {
    path: "/permit/edit/:id",
    component: PermitEdit,
    exact: true,
    permissionKey: "leave_days",
  },







  // // /system-users
  {
    path: "/system-users/list",
    component: SystemUsersList,
    exact: true,
    permissionKey: "system_users",
  },
  {
    path: "/system-users/create",
    component: SystemUsersCreate,
    exact: true,
    permissionKey: "system_users",
  },
  {
    path: "/system-users/view/:id",
    component: SystemUsersView,
    exact: true,
    permissionKey: "system_users",
  },
  {
    path: "/system-users/edit/:id",
    component: SystemUsersEdit,
    exact: true,
    permissionKey: "system_users",
  },
  // // /order
  {
    path: "/order/list",
    component: OrderList,
    exact: true,
    permissionKey: "order",
  },
  {
    path: "/order/create",
    component: OrderCreate,
    exact: true,
    permissionKey: "order",
  },
  {
    path: "/order/view/:id",
    component: OrderView,
    exact: true,
    permissionKey: "order",
  },
  {
    path: "/order/edit/:id",
    component: OrderEdit,
    exact: true,
    permissionKey: "order",
  },
  // // /options
  {
    path: "/options/list",
    component: CitiesList,
    exact: true,
    permissionKey: "options",
  },
  {
    path: "/options/create",
    component: CitiesCreate,
    exact: true,
    permissionKey: "options",
  },
  {
    path: "/options/view/:id",
    component: citiesView,
    exact: true,
    permissionKey: "options",
  },
  {
    path: "/options/edit/:id",
    component: citiesEdit,
    exact: true,
    permissionKey: "options",
  },






  {
    path: "/role/list",
    component: RoleList,
    exact: true,
    permissionKey: "roles",
  }, {
    path: "/role/create",
    component: RoleCreate,
    exact: true,
    permissionKey: "roles",
  },
  {
    path: "/settings/work-hours",
    component: WorkHours,
    exact: true,
    permissionKey: "work_hours",
  },
  {
    path: "/department/list",
    component: DepartmentList,
    exact: true,
    permissionKey: "department",
  },
  {
    path: "/department/create",
    component: DepartmentCreate,
    exact: true,
    permissionKey: "department",
  },
  {
    path: "/department/view/:id",
    component: DepartmentView,
    exact: true,
    permissionKey: "department",
  },
  {
    path: "/department/edit/:id",
    component: DepartmentEdit,
    exact: true,
    permissionKey: "department",
  },

  // // coupon
  {
    path: "/promotion/list",
    component: PromotionList,
    exact: true,
    permissionKey: "coupon",
  },

  // // client
  {
    path: "/client/category/list",
    component: ClientCategoryList,
    exact: true,
    permissionKey: "client",
  },
  {
    path: "/client/list",
    component: ClientList,
    exact: true,
    permissionKey: "client",
  },
];
