import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import { Link } from 'react-router-dom';
import Select from "react-select";
import CustomUploadInput from "../../lib/CustomUploadInput";
import { conditionTypes } from "../../../store/staticData";
import { errorMessages, successMessages } from "../../../utils/httpMessages"

class YachtEdit extends React.Component {

    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {
            isDraggingOver: false,
            images: [],
            deletedImages: [],
        };
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        this.setState({ isLoading: true })

        axios.get(config.apiURL + `yachts/${this.id}`).then(res => {
            if (res.data.success) {
                this.setState({
                    data: res.data.data,
                })
            }
        }).finally(() => this.setState({ isLoading: false }))
    }

    handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        // formData.append('multiple_images[]', this.state.images);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        // this.state.deletedImages.forEach((image) => {
        //     formData.append(`delete_images[]`, image);
        // });
        // console.log("data",data)

        if (!data?.name.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.price.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Price")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.guest_limit.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Guest limit")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!Number(data?.cabin_count)) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Cabin count")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!Number(data?.length_ft)) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Yacht length")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.description.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Yacht description")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        // data['deleted_files'] = this.state.deletedImages

        console.log("this.state.deletedImages", this.state.deletedImages);

        formData.append("deleted_files", this.state.deletedImages)

        this.state.images.forEach(item => {
            formData.append("file", item)
        })

        axios.put(config.apiURL + `yachts/${this.id}`, formData).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.updated("Yacht")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/yacht/list")
            }
        })
    }

    handleFileSelect = (event) => {
        const files = event.target.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            this.setState({
                images: [
                    ...this.state.images,
                    ...selectedFilesArray
                ],
            })
        }
    };

    handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            this.setState({
                images: selectedFilesArray,
            })
        }
        this.setState({
            isDraggingOver: false,
        })
    };

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: true,
        })
    };

    handleDragLeave = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: false,
        })

    };

    removeFile = (file) => {
        const arr = this.state.images.filter((selectedFile) => selectedFile !== file)
        this.setState({
            images: arr,
        })
    };

    deleteCurrentImages = (item) => {
        let arr = this.state.data.media.filter((image) => image.id !== item.id)

        this.setState({
            deletedImages: [...this.state.deletedImages, item.id],
            data: {
                ...this.state.data,
                media: arr,
            }
        })
    }

    render() {
        // console.log("this.state", this.state)
        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Edit yacht details</h1>
                            </div>
                        </div>
                    </div>
                    <>
                        {
                            !this.state.data ?
                                <div className='w-100 alert alert-info'>
                                    Not found
                                </div>
                                :
                                <Form
                                    className="row"
                                    onSubmit={e => this.handleSubmit(e)}
                                >
                                    <div className="col-md-6 col-lg-4 mb-4">
                                        <Label for='name' className="font-weight-bold">Name</Label>
                                        <Input
                                            name='name'
                                            id='name'
                                            placeholder='Enter yacht name'
                                            defaultValue={this.state.data.name}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-2 mb-4">
                                        <Label for='price' className="font-weight-bold">Price (AED)</Label>
                                        <Input
                                            type='number'
                                            name='price'
                                            id='price'
                                            placeholder='Enter price or 0'
                                            defaultValue={this.state.data.price}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-2 mb-4">
                                        <Label for='guest_limit' className="font-weight-bold">Guest limit</Label>
                                        <Input
                                            type='number'
                                            name='guest_limit'
                                            id='guest_limit'
                                            placeholder='Enter guest limit'
                                            defaultValue={this.state.data.guest_limit}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-2 mb-4">
                                        <Label for='cabin_count' className="font-weight-bold">Cabins</Label>
                                        <Input
                                            type='number'
                                            name='cabin_count'
                                            id='cabin_count'
                                            placeholder='Enter cabin count'
                                            defaultValue={this.state.data.cabin_count}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-2 mb-4">
                                        <Label for='length_ft' className="font-weight-bold">Yacht length (ft)</Label>
                                        <Input
                                            type='number'
                                            name='length_ft'
                                            id='length_ft'
                                            placeholder='Enter cabin count'
                                            defaultValue={this.state.data.length_ft}
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-12 mb-4">
                                        <Label for='description' className="font-weight-bold">Description</Label>
                                        <Input
                                            type='textarea'
                                            name='description'
                                            id='description'
                                            placeholder='Enter yacht description'
                                            defaultValue={this.state.data.description}
                                        />
                                    </div>

                                    {/* <Label className="font-weight-bold">Current images</Label> */}
                                    <div className="col-md-12 col-lg-12 mb-4 d-flex flex-wrap flex-direction-column mt-4">
                                        {
                                            this.state.data.media.length ?
                                                this.state.data.media.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            className='d-flex align-items-center mr-2 flex-column mb-4'>
                                                            <img
                                                                key={index}
                                                                src={config.imageURL + item.media_path}
                                                                alt="image"
                                                                crossOrigin="anonymous"
                                                                className="img-fluid mr-4 mb-1"
                                                                width={"200"}
                                                                height={"200"}
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => this.deleteCurrentImages(item)}
                                                            >
                                                                x
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className='w-100 mb-4'>
                                                    <div className='alert alert-info'>
                                                        Current images empty
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                    {/* <div className="col-md-12 col-lg-12 mb-4">
                        <div className="image-uploader">
                            <span className="text-lg font-semibold">Yeni şəkillər</span>
                            <div className="mt-5 ">
                                <div
                                    className="mt-1 d-flex flex-column align-items-center justify-content-center px-6 pt-5 pb-6 border border-secondary rounded"
                                    onDrop={this.handleDrop}
                                    onDragOver={this.handleDragOver}
                                    onDragLeave={this.handleDragLeave}
                                >
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-neutral-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                            <label
                                                htmlFor="multiple_images[]"
                                                className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                            >
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "gray",
                                                        color: "white",
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        marginBottom: "10px"
                                                    }}
                                                >Upload a file</span>
                                                <input
                                                    id="multiple_images[]"
                                                    name="multiple_images[]"
                                                    className="sr-only"
                                                    type="file"
                                                    multiple
                                                    onChange={this.handleFileSelect}
                                                />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>

                                    {
                                        // @ts-ignore
                                        this.state.images?.length > 0 &&
                                        <div className="w-100 mt-5 d-grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 d-flex">
                                            {
                                                this.state.images.map((file) => (
                                                    <div key={file.name}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignContent: 'center',
                                                            alignItems: 'center',
                                                            marginRight: '10px',
                                                        }}
                                                        className="relative mr-2 ml-2 mb-4">
                                                        <img
                                                            src={URL.createObjectURL(file)} alt={file.name}
                                                            className="object-cover rounded"
                                                            style={{ width: '132px', height: '132px', objectFit: 'cover' }}
                                                        />
                                                        <button
                                                            type={'button'}
                                                            style={{
                                                                cursor: "pointer",
                                                                padding: "10px",
                                                                borderRadius: "10px",
                                                                lineHeight: '1px',
                                                                width: '35px',
                                                                height: '35px',
                                                                marginTop: '5px',
                                                            }}
                                                            className="ml-2 absolute top-0 right-0 w-6 h-6 font-bold text-sm text-red-600 bg-white rounded-full hover:bg-gray-200"
                                                            onClick={() => this.removeFile(file)}
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div> */}

                                    <div className='col-md-12 mb-4'>
                                        <Label className="font-weight-bold">New images</Label>
                                        <div className="mt-2">
                                            <div
                                                className="mt-2 px-5 pt-5 pb-5 border border-primary"
                                                onDrop={this.handleDrop}
                                                onDragOver={this.handleDragOver}
                                                onDragLeave={this.handleDragLeave}
                                            >
                                                <div className="text-center">
                                                    <div className='text-center'>
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-neutral-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                            width="100"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <label
                                                            htmlFor="file-upload-2"
                                                        >
                                                            <span className='btn btn-light'>Upload a file</span>
                                                            <input
                                                                id="file-upload-2"
                                                                className="sr-only"
                                                                type="file"
                                                                multiple
                                                                onChange={this.handleFileSelect}
                                                            />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p>
                                                        PNG, JPG, JPEG up to 5MB
                                                    </p>
                                                </div>
                                                {
                                                    this.state.images.length > 0 &&
                                                    <div className="w-100 mt-5 d-flex align-items-center flex-wrap">
                                                        {
                                                            this.state.images.map((file) => (
                                                                <div key={file.name} className="relative mr-4 mb-4">
                                                                    <img
                                                                        alt={file.name}
                                                                        src={URL.createObjectURL(file)}
                                                                        width="200"
                                                                        height="150"
                                                                    />
                                                                    <button
                                                                        type='button'
                                                                        className="btn btn-danger btn-sm"
                                                                        onClick={() => this.removeFile(file)}
                                                                    >
                                                                        x
                                                                    </button>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-4 d-flex justify-content-end">
                                        <Link
                                            to="/yacht/list"
                                            className="btn btn-light font-weight-bold"
                                        >
                                            Cancel
                                        </Link>
                                        <Button
                                            type='submit'
                                            color='primary'
                                            className='font-weight-bold ml-2'
                                        >
                                            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
                                        </Button>
                                    </div>
                                </Form>
                        }
                    </>
                </div>
            </>
        )
    }
}

export default YachtEdit;