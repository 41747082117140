import React, { useState } from 'react'
import {
    Button,
    Form,
    Label
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { config } from '../../../config'
import toast from "toasted-notes";
import moment from 'moment/moment'

const WorkHours = () => {

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    React.useEffect(() => {
        getWorkHours()
    }, [])

    const getWorkHours = () => {
        axios.get(config.apiURL + 'api/workingHours/1').then(res => {
            if (res.data.success) {
                if (res.data.data.start_time) {
                    setStartDate(new Date().setHours(res.data.data.start_time.split(":")[0], res.data.data.start_time.split(":")[1]))
                }
                if (res.data.data.end_time) {
                    setEndDate(new Date().setHours(res.data.data.end_time.split(":")[0], res.data.data.end_time.split(":")[1]))
                }
            }
        })
    }

    const handleSubmitForm = e => {
        e.preventDefault();

        if (!startDate) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Başlama tarixi seçilməyib.</p>
                </div>
            ),
                { position: "top-right", duration: 2500 }
            );
            return
        }
        if (!endDate) {
            toast.notify(({ onClose }) => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Bitmə tarixi seçilməyib.</p>
                </div>
            ),
                { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.put(config.apiURL + 'api/workingHours/1', {
            start_time: moment(startDate).format("HH:mm") + ':00',
            end_time: moment(endDate).format("HH:mm") + ':00',
        }).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğursuz əməliyyat!</h5>
                        <p className="mb-0">İş saatları uğurla redakdə edildi.</p>
                    </div>
                ),
                    { position: "top-right", duration: 2500 }
                );
            }
        })
    }

    return (
        <div className="bg-white rounded p-4">
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="page-header">
                        <h1 className="font-weight-bold">İş saatları</h1>
                    </div>
                </div>
            </div>
            <Form
                className="row"
                onSubmit={e => handleSubmitForm(e)}
            >
                <div className="col-md-6 col-lg-4 mb-4">
                    <Label className="font-weight-bold">Başlama tarixi</Label>
                    <DatePicker
                        showTimeSelectOnly
                        selected={startDate}
                        onChange={e => setStartDate(e)}
                        locale="az"
                        showTimeSelect
                        dateFormat="HH:mm"
                        className="form-control"
                        placeholderText="saat : dəqiqə"
                        timeIntervals={15}
                        timeCaption="Saat"
                    />
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                    <Label className="font-weight-bold">Bitmə tarixi</Label>
                    <DatePicker
                        showTimeSelectOnly
                        selected={endDate}
                        onChange={e => setEndDate(e)}
                        locale="az"
                        showTimeSelect
                        dateFormat="HH:mm"
                        className="form-control"
                        placeholderText="saat : dəqiqə"
                        timeIntervals={15}
                        timeCaption="Saat"
                    />
                </div>
                <div className="col-md-12 mb-4 d-flex justify-content-end">
                    <Button type="submit" color="primary" className="ml-2">
                        <FontAwesomeIcon icon={faSave} className="mr-2" /> Yadda saxla
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default WorkHours