import React from "react";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFileExport,
  faSearch,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, tableConfig } from "../../../config";
import toast from "toasted-notes";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomPagination from "../../common/CustomPagination";
import { log_types, terminal_types } from "../../../store/staticData";
import { connect } from "react-redux";
// import { setAreaList } from "../../../store/action";
import { isToday } from "date-fns";

class Log extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      rows: 10,
      page: 1,
      data: null,
      areaList: this.props.areaList ?? [],
      selectedAreaIdList: [],
      userList: [],
      selectedUser: null,
      isOpenExcellModal: false,
      selectedExportDate: new Date().toISOString().split("T")[0],
      selectedType: terminal_types.find(x => x.id === 0),
      isDisabledSelectedType: false,
      start_date: new Date().setHours(0, 0, 0, 0),
      end_date: new Date().setHours(23, 59, 0, 0),
      selectedLogType: null,
      isRenderTableSection: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getAreaList();
    this.getUsers();
  }

  getAreaList = () => {
    if (this.props.areaList?.length) return

    axios
      // .get(config.apiURL + `api/area/list?per_page=500&page=1`)
      .get(config.apiURL + `api/yacht/areas`)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            areaList: res.data.data,
          });
          // this.props.setAreaList(res.data.data)
        }
      });
  };

  getData = (page, rows, e) => {
    if (e) e.preventDefault();

    this.setState({ isRenderTableSection: true })

    if (this.state.selectedLogType) {
      if (this.state.selectedLogType?.id === "earlyLeaves" && isToday(this.state.start_date)) {
        toast.notify(({ onClose }) => (
          <div className="alert alert-warning m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Cari tarix üçün <b>işdən tez çıxanlar</b> hesabatı çıxarıla bilməz</p>
          </div>), { position: "top-right", duration: 4500 }
        );
        return
      }
      if (this.state.selectedLogType?.id === "lateLeaves" && isToday(this.state.start_date)) {
        toast.notify(({ onClose }) => (
          <div className="alert alert-warning m-3">
            <h5>Uğursuz əməliyyat!</h5>
            <p className="mb-0">Cari tarix üçün <b>işdən gec çıxanlar</b> hesabatı çıxarıla bilməz</p>
          </div>), { position: "top-right", duration: 4500 }
        );
        return
      }
    }

    if (e) {
      // e.preventDefault();

      const formData = new FormData(e.target);
      let data = {};

      for (const [key, value] of formData.entries()) {
        data[key] = value;
      }

      if (!this.state.start_date) {
        toast.notify(
          ({ onClose }) => (
            <div className="alert alert-danger m-3">
              <h5>Uğursuz əməliyyat!</h5>
              <p className="mb-0">Başlama tarixi seçilməyib.</p>
            </div>
          ),
          { position: "top-right", duration: 2500 }
        );
        return;
      } else {
        if (!this.state.selectedLogType) {
          data['start'] = moment(this.state.start_date).format("yyyy-MM-DD HH:mm")
        } else {
          data['start'] = moment(this.state.start_date).format("yyyy-MM-DD")
        }
      }

      if (this.state.end_date) {
        if (!this.state.selectedLogType) {
          data['end'] = moment(this.state.end_date).format("yyyy-MM-DD HH:mm")
        } else {
          data['end'] = moment(this.state.end_date).format("yyyy-MM-DD")
        }
      }

      this.setState({
        isLoadingData: true,
        searchParams: {
          page: 1,
          per_page: rows ?? this.state.rows,
          area_ids: this.state.selectedAreaIdList?.map(item => item.id),
          type: this.state.selectedType?.id,
          start: data.start,
          end: data.end,
          fullname: this.state.selectedUser
            ? this.state.selectedUser?.fullname
            : undefined,
          fin: data.fin,
          logType: this.state.selectedLogType?.id,
        },
      });

      axios.get(config.apiURL + `api/employees/logs`, {
        params: {
          page: 1,
          per_page: rows ?? this.state.rows,
          area_ids: this.state.selectedAreaIdList?.map(item => item.id),
          type: this.state.selectedType?.id,
          start: data.start,
          end: data.end,
          fullname: this.state.selectedUser
            ? this.state.selectedUser?.fullname
            : undefined,
          fin: data.fin,
          logType: this.state.selectedLogType?.id,
        },
      }).then((res) => {
        if (res.status === 200) {

          if (this.state.selectedLogType?.id === "absent" || this.state.selectedLogType?.id === "filo") {
            this.setState({
              data: res.data.data,
              total: res.data.total,
              page: res.data.current_page,
            });
          } else {
            this.setState({
              data: res.data.data,
              total: res.data.meta?.total,
              page: res.data.meta?.current_page,
            });
          }

        }
      })
        .finally(() => this.setState({ isLoadingData: false }));
    } else {
      this.setState({ isLoadingData: true });
      axios.get(config.apiURL + `api/employees/logs`, {
        params: {
          ...this.state.searchParams,
          page: page ? page : 1,
          per_page: rows ? rows : this.state.rows,
          // export: isExport,
        },
        // responseType: isExport ? "blob" : "json"
      }).then((res) => {
        if (res.status === 200) {
          if (this.state.selectedLogType?.id === "absent" || this.state.selectedLogType?.id === "filo") {
            this.setState({
              data: res.data.data,
              total: res.data.data?.length,
              page: 0,
            });
          } else {
            this.setState({
              data: res.data.data,
              total: res.data.meta.total,
              page: res.data.meta.current_page,
            })
          }
        }
      }).finally(() => this.setState({ isLoadingData: false }));
    }
  };

  setRowCount = (event) => {
    this.setState({ rows: event.target.value });
    this.getData(1, event.target.value);
  };

  resetForm = () => {
    this.formRef.current.reset();
    this.setState({
      data: null,
      selectedAreaIdList: null,
      selectedType: terminal_types.find(x => x.id === 0),
      isDisabledSelectedType: false,
      isLoadingData: false,
      selectedUser: null,
      selectedLogType: null,
      start_date: new Date().setHours(0, 0, 0, 0),
      end_date: new Date().setHours(23, 59, 0, 0),
    });
  };

  handleChangeDate = (date_state, date) => {
    if (date_state === "start_date") {
      this.setState({ start_date: date });
    }
    if (date_state === "end_date") {
      this.setState({ end_date: date });
    }
  };

  handleEntryExport = () => {
    if (!this.state.selectedExportDate) {
      toast.notify(() => (
        <div className="alert alert-danger m-3">
          <h5>Uğursuz əməliyyat!</h5>
          <p className="mb-0">Tarix seçilməyib.</p>
        </div>
      ), {
        position: "top-right", duration: 2500
      });
      return;
    }

    axios.get(config.apiURL + `api/export`, {
      params: {
        export: 1,
        date: this.state.selectedExportDate
      },
      responseType: "blob",
    }).then((res) => {
      this.toggleExcellModal();
      this.exportExcel(res);
    });
  };

  exportExcel = ({ data }) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/xlsx" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  toggleExcellModal = () => {
    this.setState({
      isOpenExcellModal: !this.state.isOpenExcellModal,
    });
  };

  getUsers() {
    axios.get(config.apiURL + `api/employees/search`).then((res) => {
      if (res.data.success) {
        this.setState({
          userList: res.data.data,
        });
      }
    });
  }

  handleResultExport = () => {
    this.setState({ isLoadingData: true });
    axios.get(config.apiURL + `api/employees/logs`, {
      params: {
        ...this.state.searchParams,
        page: this.state.page,
        per_page: this.state.rows,
        export: 1,
      },
      responseType: "blob"
    }).then((res) => {
      if (res.status === 200) {
        this.exportExcel(res)
      }
    }).finally(() => this.setState({ isLoadingData: false }));
  };

  handleChangeFilterType = filter => {

    this.setState({
      selectedLogType: filter,
      isRenderTableSection: false,
    });

    if (filter?.id) {
      if (filter.id === "earlyLeaves" || filter.id === "lateLeaves") {
        this.setState(prevState => {
          return {
            areaList: this.props.areaList.filter(x => x.type !== "enter"),
            selectedAreaIdList: [],
            selectedType: terminal_types.find(x => x.id === "exit"),
            isDisabledSelectedType: true,
          }
        });
      } else if (filter.id === "lateComers" || filter.id === "earlyComers") {
        this.setState({
          areaList: this.props.areaList.filter(x => x.type !== "exit"),
          selectedAreaIdList: [],
          selectedType: terminal_types.find(x => x.id === "enter"),
          isDisabledSelectedType: true,
        });
      } else {
        this.setState(prevState => {
          return {
            areaList: this.props.areaList,
            selectedType: prevState.selectedType,
            isDisabledSelectedType: false,
          }
        });
      }
    }
  }

  render() {

    const columns = [
      {
        dataField: "id",
        text: "No.",
        formatter: (cell, row, index) => {
          return index + 1;
        },
      },
      {
        dataField: "area_id",
        text: "Terminal",
        sort: true,
        formatExtraData: this.state,
        formatter: (cell, row, rowIndex, extraData) => {
          if (cell) return extraData.areaList.find((x) => x.id === cell)?.name;
          return "";
        },
      },
      {
        dataField: "cardName",
        text: "Ad, soyad",
        sort: true,
      },
      {
        dataField: "employeeId",
        text: "FİN",
        sort: true,
      },
      {
        dataField: "method",
        text: "Tip",
        sort: true,
      },
      {
        dataField: "recNumber",
        text: "Qeydiyyat nömrəsi",
        sort: true,
      },
      {
        dataField: "date",
        text: "Tarix",
        sort: true,
      }, {
        dataField: "time",
        text: "Saat",
        sort: true,
      },
    ];

    const customColumns = [
      {
        dataField: "id",
        text: "No.",
        formatter: (cell, row, index) => {
          return index + 1;
        },
      },
      {
        dataField: "fullname",
        text: "Ad, soyad",
      },
      {
        dataField: "terminal_user_id",
        text: "FİN",
        sort: true,
      },
      {
        dataField: "workingHours",
        text: "İş saatı",
        sort: true,
      },
      {
        dataField: "first_log",
        text: "Giriş",
        sort: true,
        formatter: (cell) => {
          if (!cell) return ""
          const date = moment(cell.time).format("DD-MM-yyyy HH:mm:ss")
          return <div className="d-flex flex-column">
            <span>{cell.method ?? ""}</span>
            <span>{date ?? ""}</span>
          </div>
        }
      },
      {
        dataField: "last_log",
        text: "Çıxış",
        sort: true,
        formatter: (cell) => {
          if (!cell) return ""
          const date = moment(cell.time).format("DD-MM-yyyy HH:mm:ss")
          return <div className="d-flex flex-column">
            <span>{cell.method ?? ""}</span>
            <span>{date ?? ""}</span>
          </div>
        }
      },
    ];

    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">Log siyahısı</h1>
                {
                  this.props.permissions.find((x) => x.name === "reports") && (
                    <Button color="primary" onClick={this.toggleExcellModal}>
                      <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                      Giriş report excell
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
          <Form
            className="row"
            innerRef={this.formRef}
            onSubmit={(e) => this.getData(this.state.page, this.state.rows, e)}
          >
            <div className="col-md-6 col-lg-4 mb-4">
              <Label
                // className={`font-weight-bold ${this.state.selectedType ? "text-muted" : ""
                //   }`}
                className="font-weight-bold"
              >
                Terminal
              </Label>
              <Select
                placeholder="Seç"
                isMulti
                value={this.state.selectedAreaIdList}
                options={this.state.areaList}
                // isDisabled={this.state.selectedType}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(e) =>
                  this.setState({
                    selectedAreaIdList: e,
                    selectedType: null,
                  })
                }
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label className="font-weight-bold">Ad, soyad</Label>
              <Select
                name="fullname"
                placeholder="Ad, soyad daxil edin"
                value={this.state.selectedUser}
                showNewOptionAtTop={false}
                isClearable={true}
                isSearchable={true}
                options={this.state.userList}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.fullname}
                onChange={(e) => this.setState({ selectedUser: e })}
                clearable={true}
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label className="font-weight-bold">FİN</Label>
              <Input name="fin" placeholder="FİN daxil edin" />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label className="font-weight-bold">Başlama tarixi</Label>
              <DatePicker
                selected={this.state.start_date}
                onChange={(e) => this.setState({ start_date: e })}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="gün/ay/il"
                timeCaption="Saat"
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label className="font-weight-bold">Bitmə tarixi</Label>
              <DatePicker
                selected={this.state.end_date}
                onChange={(e) => this.setState({ end_date: e })}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                locale="az"
                className="form-control"
                placeholderText="gün/ay/il"
                timeCaption="Saat"
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label
                // className={`font-weight-bold ${this.state.selectedAreaIdList ? "text-muted" : ""
                //   }`}
                className="font-weight-bold"
              >
                Giriş növü
              </Label>
              <Select
                placeholder="Seç"
                value={this.state.selectedType}
                options={terminal_types}
                isDisabled={this.state.isDisabledSelectedType}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(e) =>
                  this.setState({
                    selectedType: e,
                    selectedAreaIdList: [],
                  })
                }
              />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Label className="font-weight-bold">Filter</Label>
              <Select
                placeholder="Seç"
                value={this.state.selectedLogType}
                options={log_types}
                isClearable={true}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={e => this.handleChangeFilterType(e)}
              />
            </div>
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <Button type="submit" color="primary" className="ml-2">
                <FontAwesomeIcon icon={faSearch} className="mr-2" /> Axtar
              </Button>
              <Button
                type="button"
                color="light"
                className="ml-2"
                onClick={this.resetForm}
              >
                <FontAwesomeIcon icon={faUndo} className="mr-2" /> Axtarışı
                sıfırla
              </Button>
            </div>
          </Form>
          <div className="row mb-4">
            {!this.state.isLoadingData ? (
              <>
                {
                  this.state.data ? (
                    this.state.data.length ? (
                      <>
                        <div className="col-md-12 mb-4">
                          <Button
                            color="primary"
                            onClick={this.handleResultExport}
                          >
                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                            Nəticəni export et
                          </Button>
                        </div>
                        <div className="col-md-12">
                          {
                            !this.state.isRenderTableSection ? null
                              :
                              this.state.selectedLogType?.id === "absent" || this.state.selectedLogType?.id === "filo" ?
                                <BootstrapTable
                                  bootstrap4
                                  striped
                                  keyField="id"
                                  data={this.state.data}
                                  {...tableConfig}
                                  columns={customColumns}
                                />
                                :
                                <>
                                  <BootstrapTable
                                    bootstrap4
                                    striped
                                    keyField="id"
                                    data={this.state.data}
                                    {...tableConfig}
                                    columns={columns}
                                  />
                                  <CustomPagination
                                    total={this.state.total}
                                    page={this.state.page}
                                    rows={this.state.rows}
                                    getData={this.getData}
                                    setRowCount={this.setRowCount}
                                    params={this.state.searchParams}
                                  />
                                </>
                          }
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12">
                        <div className="alert alert-warning p-4 mt-4">
                          Not found data.
                        </div>
                      </div>
                    )
                  ) : null
                }
              </>
            ) : (
              <div className="col-12">
                <div className="alert alert-info p-4 text-center mt-4">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            )}
          </div>
        </div >
        {/* excell modal */}
        <Modal
          size="sm"
          centered
          isOpen={this.state.isOpenExcellModal}
          onClosed={this.resetState}
        >
          <ModalHeader toggle={this.toggleExcellModal}>
            Giriş report excell
          </ModalHeader>
          <ModalBody>
            <div className="row pt-2">
              <div className="col-md-12 mb-4">
                <Label className="font-weight-bold">Tarix</Label>
                <Input
                  type="date"
                  defaultValue={this.state.selectedExportDate}
                  onChange={(e) =>
                    this.setState({ selectedExportDate: e.target.value })
                  }
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={this.toggleExcellModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="font-weight-bold"
              onClick={this.handleEntryExport}
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Yüklə
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => store;
const mapDispatchToProps = (dispatch) => {
  return {
    // setAreaList: (list) => dispatch(setAreaList(list))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Log);
