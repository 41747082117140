import React from "react";
import { connect } from "react-redux";
import { HashRouter, Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { Nav, NavItem } from "reactstrap";
import SubMenu from "./SubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faList,
  faSignOutAlt,
  faDatabase,
  faBuilding,
  faUserTag,
  faBriefcase,
  faClipboard,
  faUserCheck,
  faPersonBooth,
  faCog,
  faUserTimes,
  faHome, faCity, faCandyCane, faShip, faTags,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo.png";
import { logout } from "../../store/action";

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client_items: [{
        slug: "list",
        title: "Clients list"
      }, {
        slug: "category/list",
        title: "Client categories"
      }]
    };
  }

  componentDidMount() {
    // let register_staff = this.props.permissions.find(x => x.name === "work_hours");
    // if (!register_staff) {
    //   this.setState(prevState => {
    //     return {
    //       setting_items: prevState.setting_items.filter(x => x.slug !== "work-hours")
    //     }
    //   });
  };

  render() {

    return (
      <div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="fixed">
          <div className="sidebar-header">
            <a className="times" onClick={this.props.toggle}>
              &times;
            </a>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <HashRouter>
            <Nav vertical className="list-unstyled p-3 mt-3 flex-column">
              <NavItem>
                <NavLink exact className="nav-link" to="/">
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                  Home
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink exact className="nav-link" to="/role/list">
                  <FontAwesomeIcon icon={faPersonBooth} className="mr-2" />
                  User roles
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink exact className="nav-link" to="/system-users/list">
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  System users
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact className="nav-link" to="/yacht/list">
                  <FontAwesomeIcon icon={faShip} className="mr-2" />
                  Yachts
                </NavLink>
              </NavItem>
              <SubMenu
                slug="client"
                icon={faUserCheck}
                title="Clients"
                items={this.state.client_items}
              />
              <NavItem>
                <NavLink exact className="nav-link" to="/options/list">
                  <FontAwesomeIcon icon={faList} className="mr-2" />
                  Options
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact className="nav-link" to="/order/list">
                  <FontAwesomeIcon icon={faClipboard} className="mr-2" />
                  Orders
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact className="nav-link" to="/promotion/list">
                  <FontAwesomeIcon icon={faTags} className="mr-2" />
                  Promotions
                </NavLink>
              </NavItem>
              {/*  */}
              <NavItem>
                <a className="nav-link c-pointer" onClick={this.props.logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                  Sign out
                </a>
              </NavItem>
            </Nav>
          </HashRouter>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => store;
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
