import React from 'react';
import {
    Badge,
    Button,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPlus,
    faEdit,
    faPercentage,
    faDollarSign,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import { terminal_types } from '../../../store/staticData';
import CustomPagination from '../../common/CustomPagination';
import { errorMessages, successMessages } from '../../../utils/httpMessages';
import moment from 'moment';

class ClientCategoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            rows: 10,
            page: 1,
            isLoadingData: false,
            selectedRow: null,
            selectedType: null,
            isOpenCreateModal: false,
            isOpenEditModal: false,
            isOpenDeleteModal: false,

            //
            promo_type: "percent"
        };

    }

    componentDidMount() {
        this.getData(1, this.state.rows);
    }

    getData = (page, rows) => {
        this.setState({ isLoadingData: true })
        axios.get(config.apiURL + `admin/customer-categories?${`per_page=${rows}&page=${page}`}`)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        data: res.data.data.data,
                        page: res.data.data.current_page,
                        total: res.data.data.total,
                    })
                }
            }).finally(() => this.setState({ isLoadingData: false }));
    }

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    }

    toggleEditModal = () => {
        this.setState({ isOpenEditModal: !this.state.isOpenEditModal });
    }

    toggleCreateModal = () => {
        this.setState({ isOpenCreateModal: !this.state.isOpenCreateModal });
    }

    toggleDeleteModal = () => {
        this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal });
    }

    createData = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.name?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.bg_color?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Color")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.post(config.apiURL + 'admin/customer-categories', data).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.created("Client category")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleCreateModal();
                this.resetState();
            }
        });
    }

    deleteData = () => {
        if (!this.state.selectedRow) return;

        axios.delete(config.apiURL + `admin/customer-categories`, {
            data: {
                id_list: [this.state.selectedRow.id],
            }
        })
            .then(res => {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.deleted("Client category")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleDeleteModal();
            });
    };

    editData = (e) => {
        e.preventDefault();

        if (!this.state.selectedRow) return;

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.name?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.bg_color?.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Color")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        axios.put(config.apiURL + `admin/customer-categories/${this.state.selectedRow.id}`, data)
            .then(res => {
                if (res.data.success) {
                    toast.notify(({ onClose }) => (
                        <div className="alert alert-success m-3">
                            <h5>{successMessages.success}</h5>
                            <p className="mb-0">{successMessages.updated("Client category")}</p>
                        </div>), { position: "top-right", duration: 2500 }
                    );
                    this.getData(this.state.page, this.state.rows);
                    this.toggleEditModal();
                }
            });
    }

    resetState = () => {
        this.setState({
            selectedRow: null,
            selectedType: null,
        })
    }

    render() {

        const columns = [{
            dataField: 'id',
            text: 'ID',
        }, {
            dataField: 'name',
            text: 'Category',
            sort: true,
            formatter: (cell, row) => {
                if (!cell) return ''
                return (
                    <Badge
                        style={{
                            backgroundColor: row.bg_color,
                            padding: ".75rem",
                            fontSize: ".75rem"
                        }}
                    >
                        {row.name}
                    </Badge>
                )
            }
        }, {
            dataField: 'created_at',
            text: 'Created at',
            formatter: (cell) => {
                if (!cell) return ''
                return moment(cell).format("DD-MM-YYYY HH:mm:ss")
            }
        }, {
            dataField: 'browse',
            text: '',
            formatter: (cell, row) => {

                const type = terminal_types.find(x => x.id === row.type)

                return (
                    <div className="text-center">
                        <Button
                            size='sm'
                            color='light'
                            className='mr-2'
                            onClick={() => {
                                this.setState({
                                    isOpenEditModal: true,
                                    selectedRow: row,
                                    selectedType: type
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
                        </Button>
                        <Button
                            size='sm'
                            color='light'
                            className='mr-2'
                            onClick={() => {
                                this.setState({
                                    isOpenDeleteModal: true,
                                    selectedRow: row,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
                        </Button>
                    </div>
                )
            }
        }];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Client Categories</h1>
                                <Button
                                    color='primary'
                                    className='font-weight-bold'
                                    onClick={() => this.setState({ isOpenCreateModal: true })}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add New Client Category
                                </Button>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.isLoadingData ?
                            <>
                                {
                                    this.state.data?.length ?
                                        <div className="col-12 mt-4">
                                            <BootstrapTable
                                                bootstrap4
                                                striped
                                                keyField='id'
                                                data={this.state.data}
                                                {...tableConfig}
                                                columns={columns}
                                                wrapperClasses="table-responsive"
                                            />
                                            <CustomPagination
                                                total={this.state.total}
                                                page={this.state.page}
                                                rows={this.state.rows}
                                                getData={this.getData}
                                                setRowCount={this.setRowCount}
                                                params={this.state.searchParams}
                                            />
                                        </div>
                                        : <div className="alert alert-info">
                                            Not found
                                        </div>
                                }

                                {/* create modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenCreateModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleCreateModal}>Add New Client Category</ModalHeader>
                                    <Form onSubmit={e => this.createData(e)}>
                                        <ModalBody>
                                            <div className="row pt-2">
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        name="name"
                                                        placeholder="Category Name"
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Input
                                                        name="bg_color"
                                                        placeholder="#7F54FF"
                                                    />
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="light" onClick={this.toggleCreateModal}>Cancel</Button>
                                            <Button
                                                color="primary"
                                                className="font-weight-bold"
                                            >
                                                Save
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                {/* edit modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenEditModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleEditModal}>Edit customer category</ModalHeader>
                                    <Form onSubmit={e => this.editData(e)}>
                                        {
                                            this.state.selectedRow ?
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-lg-6 mb-4">
                                                            <Input
                                                                name="name"
                                                                placeholder="Category Name"
                                                                defaultValue={this.state.selectedRow.name}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mb-4">
                                                            <Input
                                                                name="bg_color"
                                                                placeholder="#7F54FF"
                                                                defaultValue={this.state.selectedRow.bg_color}
                                                            />
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                                :
                                                <div className="alert alert-warning py-4">
                                                    Not found data
                                                </div>
                                        }
                                        <ModalFooter>
                                            <Button color="light" onClick={this.toggleEditModal}>Cancel</Button>
                                            <Button
                                                color="primary"
                                                className="font-weight-bold"
                                            >
                                                Save
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                {/* delete modal */}
                                <Modal
                                    size="md"
                                    centered
                                    isOpen={this.state.isOpenDeleteModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                                    {
                                        this.state.selectedRow ?
                                            <>
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-md-12 text-center mb-4">
                                                            <h5 className="font-weight-bold">
                                                                Are you sure to delete the selected data?
                                                            </h5>
                                                            <p className="font-weight-bold">#{this.state.selectedRow.id} {this.state.selectedRow.name ?? ""}</p>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter className="justify-content-center">
                                                    <Button color="light" onClick={this.toggleDeleteModal}>Cancel</Button>
                                                    <Button
                                                        color="primary"
                                                        className="font-weight-bold"
                                                        onClick={this.deleteData}
                                                    >
                                                        Delete
                                                    </Button>
                                                </ModalFooter>
                                            </>
                                            :
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="alert alert-warning">
                                                        Not found data
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Modal>
                            </>
                            :
                            <div className="alert alert-info p-4 text-center">
                                <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                            </div>
                    }

                </div>

            </>
        )
    }
}

export default ClientCategoryList;