import React from 'react';
import {
    Button,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPlus,
    faEdit,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config, tableConfig } from '../../../config';
import toast from "toasted-notes";
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import { terminal_types } from '../../../store/staticData';
import CustomPagination from '../../common/CustomPagination';

class ActiveEmployeeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            rows: 10,
            page: 1,
            isLoadingData: false,
            selectedRow: null,
            selectedType: null,
            isOpenCreateModal: false,
            isOpenEditModal: false,
            isOpenDeleteModal: false,
        };

    }

    componentDidMount() {
        this.getData(1, this.state.rows);
    }

    getData = (page, rows) => {
        this.setState({ isLoadingData: true })
        axios.get(config.apiURL + `api/area/list?register=1&${`per_page=${rows}&page=${page}`}`)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        data: res.data.data.data,
                        page: res.data.data.current_page,
                        total: res.data.data.total,
                    })
                }
            }).finally(() => this.setState({ isLoadingData: false }));
    }

    setRowCount = (event) => {
        this.setState({ rows: event.target.value });
        this.getData(1, event.target.value);
    }

    toggleEditModal = () => {
        this.setState({ isOpenEditModal: !this.state.isOpenEditModal });
    }

    toggleCreateModal = () => {
        this.setState({ isOpenCreateModal: !this.state.isOpenCreateModal });
    }

    toggleDeleteModal = () => {
        this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal });
    }

    createData = e => {
        e.preventDefault();

        if (!this.state.selectedType) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Terminal tipi seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        if (!data.name) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Terminal adı daxil edilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.ip) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">IP adı daxil edilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        if (!data.port) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Port daxil edilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        data['type'] = this.state.selectedType.id;

        axios.post(config.apiURL + 'api/area/create', data).then(res => {
            if (res.data.success) {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Terminal uğurla əlavə edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleCreateModal();
                this.resetState();
            }
        });
    }

    deleteData = () => {
        if (!this.state.selectedRow) return;

        axios.delete(config.apiURL + `api/area/delete/${this.state.selectedRow.id}`)
            .then(res => {
                toast.notify(({ onClose }) => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Terminal məlumatları silindi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.getData(this.state.page, this.state.rows);
                this.toggleDeleteModal();
            });
    };

    editData = (e) => {
        e.preventDefault();

        if (!this.state.selectedRow) return;

        if (!this.state.selectedType) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Terminal tipi seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return
        }

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        };

        data['type'] = this.state.selectedType.id

        axios.put(config.apiURL + `api/area/update/${this.state.selectedRow.id}`, data)
            .then(res => {
                if (res.data.success) {
                    toast.notify(({ onClose }) => (
                        <div className="alert alert-success m-3">
                            <h5>Uğurlu əməliyyat!</h5>
                            <p className="mb-0">Terminal məlumatları uğurla redakdə edildi.</p>
                        </div>), { position: "top-right", duration: 2500 }
                    );
                    this.getData(this.state.page, this.state.rows);
                    this.toggleEditModal();
                }
            });
    }

    resetState = () => {
        this.setState({
            selectedRow: null,
            selectedType: null,
        })
    }

    render() {

        const columns = [{
            dataField: 'id',
            text: 'ID',
            // formatter: (cell, row, index) => {
            //   return index + 1
            // },
        }, {
            dataField: 'name',
            text: 'Ad',
            sort: true,
        }, {
            dataField: 'type',
            text: 'Tip',
            sort: true,
        }, {
            dataField: 'username',
            text: 'Username',
            sort: true,
        }, {
            dataField: 'ip',
            text: 'IP',
            sort: true,
        }, {
            dataField: 'port',
            text: 'Port',
            sort: true,
        }, {
            dataField: 'description',
            text: 'Qeyd',
        }, {
            dataField: 'created_at',
            text: 'Yaradılma tarixi',
        }, {
            dataField: 'browse',
            text: '',
            formatter: (cell, row) => {

                const type = terminal_types.find(x => x.id === row.type)

                return (
                    <div className="text-center">
                        <Button
                            color='light'
                            className='mr-2'
                            onClick={() => {
                                this.setState({
                                    isOpenEditModal: true,
                                    selectedRow: row,
                                    selectedType: type
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
                        </Button>
                        <Button
                            color='light'
                            className='mr-2'
                            onClick={() => {
                                this.setState({
                                    isOpenDeleteModal: true,
                                    selectedRow: row,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" /> Sil
                        </Button>
                    </div>
                )
            }
        }];

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Terminal siyahısı</h1>
                                <Button
                                    color='primary'
                                    className='font-weight-bold'
                                    onClick={() => this.setState({ isOpenCreateModal: true })}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Create
                                </Button>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.isLoadingData ?
                            <>
                                {
                                    this.state.data?.length ?
                                        <div className="col-12 mt-4">
                                            <BootstrapTable
                                                bootstrap4
                                                striped
                                                keyField='id'
                                                data={this.state.data}
                                                {...tableConfig}
                                                columns={columns}
                                                wrapperClasses="table-responsive"
                                            />
                                            <CustomPagination
                                                total={this.state.total}
                                                page={this.state.page}
                                                rows={this.state.rows}
                                                getData={this.getData}
                                                setRowCount={this.setRowCount}
                                                params={this.state.searchParams}
                                            />
                                        </div>
                                        : <div className="alert alert-info">
                                            Not found
                                        </div>
                                }

                                {/* create modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenCreateModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleCreateModal}>Terminal əlavə et</ModalHeader>
                                    <Form onSubmit={e => this.createData(e)}>
                                        <ModalBody>
                                            <div className="row pt-2">
                                                <div className="col-lg-6 mb-4">
                                                    <Label className="font-weight-bold">Tip</Label>
                                                    <Select
                                                        placeholder="Seçin"
                                                        value={this.state.selectedType}
                                                        options={terminal_types}
                                                        getOptionValue={option => option.id}
                                                        getOptionLabel={option => option.name}
                                                        onChange={e => this.setState({ selectedType: e })}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Label className="font-weight-bold">Ad</Label>
                                                    <Input
                                                        name="name"
                                                        placeholder="Ad daxil edin"
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Label className="font-weight-bold">IP</Label>
                                                    <Input
                                                        name="ip"
                                                        placeholder="IP daxil edin"
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <Label className="font-weight-bold">Port</Label>
                                                    <Input
                                                        name="port"
                                                        placeholder="Port daxil edin"
                                                    />
                                                </div>
                                                <div className="col-lg-12 mb-4">
                                                    <Label className="font-weight-bold">Qeyd</Label>
                                                    <Input
                                                        type='textarea'
                                                        name="description"
                                                        placeholder="Qeyd daxil edin"
                                                    />
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="light" onClick={this.toggleCreateModal}>Cancel</Button>
                                            <Button
                                                color="primary"
                                                className="font-weight-bold"
                                            >
                                                Təsdiq et
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                {/* edit modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenEditModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleEditModal}>Redakdə et</ModalHeader>
                                    <Form onSubmit={e => this.editData(e)}>
                                        {
                                            this.state.selectedRow ?
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-lg-6 mb-4">
                                                            <Label className="font-weight-bold">Tip</Label>
                                                            <Select
                                                                placeholder="Seçin"
                                                                value={this.state.selectedType}
                                                                options={terminal_types}
                                                                getOptionValue={option => option.id}
                                                                getOptionLabel={option => option.name}
                                                                onChange={e => this.setState({ selectedType: e })}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mb-4">
                                                            <Label className="font-weight-bold">Ad</Label>
                                                            <Input
                                                                name="name"
                                                                defaultValue={this.state.selectedRow.name}
                                                                placeholder="Ad daxil edin"
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mb-4">
                                                            <Label className="font-weight-bold">IP</Label>
                                                            <Input
                                                                name="ip"
                                                                defaultValue={this.state.selectedRow.ip}
                                                                placeholder="IP daxil edin"
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mb-4">
                                                            <Label className="font-weight-bold">Port</Label>
                                                            <Input
                                                                name="port"
                                                                defaultValue={this.state.selectedRow.port}
                                                                placeholder="Port daxil edin"
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 mb-4">
                                                            <Label className="font-weight-bold">Qeyd</Label>
                                                            <Input
                                                                type='textarea'
                                                                name="description"
                                                                defaultValue={this.state.selectedRow.description}
                                                                placeholder="Qeyd daxil edin"
                                                            />
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                                :
                                                <div className="alert alert-warning py-4">
                                                    Not found data
                                                </div>
                                        }
                                        <ModalFooter>
                                            <Button color="light" onClick={this.toggleEditModal}>Cancel</Button>
                                            <Button
                                                color="primary"
                                                className="font-weight-bold"
                                            >
                                                Təsdiq et
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                                {/* delete modal */}
                                <Modal
                                    size="sm"
                                    centered
                                    isOpen={this.state.isOpenDeleteModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleDeleteModal}></ModalHeader>
                                    {
                                        this.state.selectedRow ?
                                            <>
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-md-12 text-center mb-4">
                                                            <h5 className="font-weight-bold">
                                                                Terminalı silmək istədiyinizdən əminsinizmi?
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter className="justify-content-center">
                                                    <Button color="light" onClick={this.toggleDeleteModal}>Cancel</Button>
                                                    <Button
                                                        color="primary"
                                                        className="font-weight-bold"
                                                        onClick={this.deleteData}
                                                    >
                                                        Sil
                                                    </Button>
                                                </ModalFooter>
                                            </>
                                            :
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="alert alert-warning">
                                                        Not found data
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Modal>



                                {/* detail modal */}
                                <Modal
                                    size="lg"
                                    centered
                                    isOpen={this.state.isOpenDetailModal}
                                    onClosed={this.resetState}
                                >
                                    <ModalHeader toggle={this.toggleViewModal}>Baxış</ModalHeader>
                                    {
                                        this.state.selectedRow ?
                                            <>
                                                <ModalBody>
                                                    <div className="row pt-2">
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="text-muted">Ad</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.name ?
                                                                        this.state.selectedRow.name : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Soyad</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.surname ?
                                                                        this.state.selectedRow.surname : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Ata adı</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.patronymic ?
                                                                        this.state.selectedRow.patronymic : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Ş.V. seriyası</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.doc_serial ?
                                                                        this.state.selectedRow.doc_serial : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Ş.V. nömrəsi</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.doc_number ?
                                                                        this.state.selectedRow.doc_number : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Fin</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.pin ?
                                                                        this.state.selectedRow.pin : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Vəzifə</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.job ?
                                                                        this.state.selectedRow.job : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">İstifadəçi adı</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.username ?
                                                                        this.state.selectedRow.username : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Email</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.email ?
                                                                        this.state.selectedRow.email : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Telefon nömrəsi</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.phone ?
                                                                        this.state.selectedRow.phone : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Ünvan</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.address ?
                                                                        this.state.selectedRow.address : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Başlama tarixi</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.begin_date ?
                                                                        this.state.selectedRow.begin_date : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4 mb-4">
                                                            <Label className="font-weight-bold">Bitmə tarixi</Label>
                                                            <p className="font-weight-bold">
                                                                {
                                                                    this.state.selectedRow.end_date ?
                                                                        this.state.selectedRow.end_date : ''
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ModalBody>
                                            </>
                                            :
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="alert alert-warning">
                                                        Not found data
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Modal>
                            </>
                            :
                            <div className="alert alert-info p-4 text-center">
                                <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                            </div>
                    }

                </div>

            </>
        )
    }
}

export default ActiveEmployeeList;