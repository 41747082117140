import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck, faList,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import Select from 'react-select';

class DepartmentEdit extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;

        this.state = {
            data: null,
            isLoading: false,
            company: null,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // if (!this.id || !Number(this.id)) {
        //     return
        // }

        this.setState({ isLoading: true })

        axios.get(config.apiURL + `api/yacht/list`, {
            params: {
                page: 1,
                per_page: 10000,
            },
        }).then((companyResp) => {
            if (companyResp.data.success) {
                this.setState({ companyList: companyResp.data.data.data });
            }

            axios.get(config.apiURL + `api/department/${this.id}`).then(res => {
                if (res.data.success) {
                    this.setState({
                        data: res.data.data,
                        company: companyResp.data.data.data.find(c => c.id === res.data.data.company_id)
                    })
                }
            }).finally(() => this.setState({ isLoading: false }))
        });

    }

    editData = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value.trim();
        }

        if (!data?.name.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Şöbə adı boş olmamalıdır.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        if (!this.state.company?.id) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Təşkilat seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        data.company_id = this.state.company.id;

        axios.put(config.apiURL + `api/department/update/${this.id}`, data).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Şöbə məlumatları uğurla redakdə edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/department/list")
            }
        })
    }

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Şöbə məlumatları</h1>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.isLoading ?
                            <>
                                {
                                    this.state.data ?
                                        <Form
                                            className='row'
                                            onSubmit={e => this.editData(e)}
                                        >
                                            <div className="col-md-6 col-lg-4 mb-4">
                                                <Label for='name' className="font-weight-bold">Şöbə adı</Label>
                                                <Input
                                                    id='name'
                                                    name='name'
                                                    placeholder="Ad daxil edin"
                                                    defaultValue={this.state.data.name}
                                                />
                                            </div>
                                            <div className="col-md-6 col-lg-4 mb-4">
                                                <Label for='company' className="font-weight-bold">Təşkilat</Label>
                                                <Select
                                                    id="company"
                                                    placeholder="Seçin"
                                                    value={this.state.company}
                                                    options={this.state.companyList}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.name}
                                                    onChange={e => this.setState({ company: e })}
                                                />
                                            </div>
                                            <div className="col-md-12 mb-4 d-flex justify-content-end">
                                                <Link
                                                    to="/department/list"
                                                    className="btn btn-light font-weight-bold"
                                                >
                                                    Cancel
                                                </Link>
                                                <Button
                                                    type='submit'
                                                    color='primary'
                                                    className='font-weight-bold ml-2'
                                                >
                                                    <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
                                                </Button>
                                            </div>
                                        </Form>
                                        :
                                        <div className="col-12">
                                            <div className="alert alert-warning p-3">
                                                Not found data
                                            </div>
                                        </div>
                                }
                            </>
                            :
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert alert-info p-4 text-center">
                                        <p className='m-0' style={{ fontSize: '24px', letterSpacing: '1px' }}>Loading...</p>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default DepartmentEdit;