import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import { Link } from 'react-router-dom';
import { errorMessages, successMessages } from '../../../utils/httpMessages';

class CompanyCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isDraggingOver: false,
            images: [],
        };
    }
    componentDidMount() {
    }

    handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }

        if (!data?.name.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Name")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.price.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Price")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.guest_limit.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Guest limit")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!Number(data?.cabin_count)) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Cabin count")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!Number(data?.length_ft)) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Yacht length")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!data?.description.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>{errorMessages.failed}</h5>
                    <p className="mb-0">{errorMessages.required("Yacht description")}</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        this.state.images.forEach(item => {
            formData.append("file", item)
        })

        axios.post(config.apiURL + 'yachts', formData).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>{successMessages.success}</h5>
                        <p className="mb-0">{successMessages.created("Yacht")}</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/yacht/list")
            }
        })
    }

    handleFileSelect = (event) => {
        const files = event.target.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            this.setState({
                images: [
                    ...this.state.images,
                    ...selectedFilesArray
                ],
            })
        }
    };

    handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files) {
            const selectedFilesArray = Array.from(files);
            this.setState({
                images: selectedFilesArray,
            })
        }
        this.setState({
            isDraggingOver: false,
        })
    };

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: true,
        })
    };

    handleDragLeave = (event) => {
        event.preventDefault();
        this.setState({
            isDraggingOver: false,
        })

    };

    removeFile = (file) => {
        const arr = this.state.images.filter((selectedFile) => selectedFile !== file)
        this.setState({
            images: arr,
        })
    };

    render() {
        // console.log("this.state", this.state)
        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Create yacth</h1>
                            </div>
                        </div>
                    </div>
                    <Form
                        className="row"
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label for='name' className="font-weight-bold">Name</Label>
                            <Input
                                name='name'
                                id='name'
                                placeholder='Enter name'
                            />
                        </div>
                        <div className="col-md-6 col-lg-2 mb-4">
                            <Label for='price' className="font-weight-bold">Price (AED)</Label>
                            <Input
                                name='price'
                                id='price'
                                placeholder='Enter price'
                            />
                        </div>
                        <div className="col-md-6 col-lg-2 mb-4">
                            <Label for='guest_limit' className="font-weight-bold">Guest limit</Label>
                            <Input
                                name='guest_limit'
                                id='guest_limit'
                                placeholder='Enter guest limit'
                            />
                        </div>
                        <div className="col-md-6 col-lg-2 mb-4">
                            <Label for='cabin_count' className="font-weight-bold">Cabins</Label>
                            <Input
                                type='number'
                                name='cabin_count'
                                id='cabin_count'
                                placeholder='Enter cabin count'
                            />
                        </div>
                        <div className="col-md-6 col-lg-2 mb-4">
                            <Label for='length_ft' className="font-weight-bold">Yacht length (ft)</Label>
                            <Input
                                type='number'
                                name='length_ft'
                                id='length_ft'
                                placeholder='Enter cabin count'
                            />
                        </div>
                        <div className="col-md-12 col-lg-12 mb-4">
                            <Label for='description' className="font-weight-bold">Description</Label>
                            <Input
                                type='textarea'
                                name='description'
                                id='description'
                                placeholder='Enter yacht description'
                            />
                        </div>
                        <div className='col-md-12 mb-4'>
                            <Label className="font-weight-bold">Images</Label>
                            <div className="mt-2">
                                <div
                                    className="mt-2 px-5 pt-5 pb-5 border border-primary"
                                    onDrop={this.handleDrop}
                                    onDragOver={this.handleDragOver}
                                    onDragLeave={this.handleDragLeave}
                                >
                                    <div className="text-center">
                                        <div className='text-center'>
                                            <svg
                                                className="mx-auto h-12 w-12 text-neutral-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                                width="100"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                ></path>
                                            </svg>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="file-upload-2"
                                            >
                                                <span className='btn btn-light'>Upload a file</span>
                                                <input
                                                    id="file-upload-2"
                                                    className="sr-only"
                                                    type="file"
                                                    multiple
                                                    onChange={this.handleFileSelect}
                                                />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p>
                                            PNG, JPG, JPEG up to 5MB
                                        </p>
                                    </div>
                                    {
                                        this.state.images.length > 0 &&
                                        <div className="w-100 mt-5 d-flex align-items-center flex-wrap">
                                            {
                                                this.state.images.map((file) => (
                                                    <div key={file.name} className="relative mr-4 mb-4">
                                                        <img
                                                            alt={file.name}
                                                            src={URL.createObjectURL(file)}
                                                            width="200"
                                                            height="150"
                                                        />
                                                        <button
                                                            type='button'
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => this.removeFile(file)}
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <Link
                                to="/yacht/list"
                                className="btn btn-light font-weight-bold"
                            >
                                Cancel
                            </Link>
                            <Button
                                type='submit'
                                color='primary'
                                className='font-weight-bold ml-2'
                            >
                                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </>
        )
    }
}

export default CompanyCreate;