import { actionTypes } from "./action";

export const initialState = {
  isAuth: !!localStorage.getItem("token"),
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  isLoading: {
    wrapper: false,
  },
  permissions: localStorage.getItem('permissions') && localStorage.getItem('permissions') !== "undefined" ?
    JSON.parse(localStorage.getItem('permissions')) : [],
  staticData: {
    yachts: [],
    coupon_cost_types: [],
    option_values: [],
    customer_categories: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return Object.assign({}, state, {
        isAuth: true,
        user: action.data,
        permissions: action.data.permissions,
      });

    case actionTypes.LOGOUT:
      return Object.assign({}, state, {
        isAuth: false,
        user: null,
      });

    case actionTypes.GET_DATA:
      if (!action.id) {
        return Object.assign({}, state, {
          staticData: {
            ...state.staticData,
            [action.key]: action.data,
          },
        });
      } else {
        return Object.assign({}, state, {
          staticData: {
            ...state.staticData,
            [action.key]: {
              ...state.staticData[action.key],
              [action.id]: action.data,
            },
          },
        });
      }

    case actionTypes.CHANGE_VALUE:

      if (action.value !== undefined) {

        if (!action.subKey) {
          return Object.assign({}, state, {
            [action.section]: {
              ...state[action.section],
              [action.field]: action.value,
            }
          })
        } else {
          return Object.assign({}, state, {
            [action.section]: {
              ...state[action.section],
              [action.field]: {
                ...state[action.section][action.field],
                [action.subKey]: action.value,
              }
            }
          })
        }

      } else {
        return Object.assign({}, state, {
          [action.section]: {
            ...state[action.section],
            update: Date.now()
          }
        })
      }

    default:
      return state;
  }
};
