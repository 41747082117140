import React from 'react';
import {
    Button,
    Form,
    Input,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../../../config';
import toast from "toasted-notes";
import { Link } from 'react-router-dom';
import Select from 'react-select';

class DepartmentCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companyList: [],
            company: null,
        };
    }

    componentDidMount() {
        this.getCompanyList();
    }

    getCompanyList = () => {
        axios.get(config.apiURL + `api/yacht/list`, {
            params: {
                page: 1,
                per_page: 10000,
            },
        }).then((res) => {
            if (res.data.success) {
                this.setState({ companyList: res.data.data.data });
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        let data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value.trim();
        }

        if (!data?.name.trim()) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Şöbə adı boş olmamalıdır.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }
        if (!this.state.company?.id) {
            toast.notify(() => (
                <div className="alert alert-danger m-3">
                    <h5>Uğursuz əməliyyat!</h5>
                    <p className="mb-0">Təşkilat seçilməyib.</p>
                </div>), { position: "top-right", duration: 2500 }
            );
            return;
        }

        data['company_id'] = this.state.company.id;

        axios.post(config.apiURL + 'api/department/create', data).then(res => {
            if (res.data.success) {
                toast.notify(() => (
                    <div className="alert alert-success m-3">
                        <h5>Uğurlu əməliyyat!</h5>
                        <p className="mb-0">Şöbə uğurla əlavə edildi.</p>
                    </div>), { position: "top-right", duration: 2500 }
                );
                this.props.history.push("/department/list")
            }
        })
    }

    render() {

        return (
            <>
                <div className="bg-white rounded p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Şöbə əlavə et</h1>
                            </div>
                        </div>
                    </div>
                    <Form
                        className="row"
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label for='name' className="font-weight-bold">Şöbənin adı</Label>
                            <Input
                                name='name'
                                id='name'
                                placeholder='Ad daxil edin'
                            />
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4">
                            <Label for='company' className="font-weight-bold">Təşkilat</Label>
                            <Select
                                id="company"
                                placeholder="Seçin"
                                value={this.state.company}
                                options={this.state.companyList}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                onChange={e => this.setState({ company: e })}
                            />
                        </div>
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <Link
                                to="/department/list"
                                className="btn btn-light font-weight-bold"
                            >
                                Cancel
                            </Link>
                            <Button
                                type='submit'
                                color='primary'
                                className='font-weight-bold ml-2'
                            >
                                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </>
        )
    }
}

export default DepartmentCreate;