import React from "react";
import { Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faList } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { config, defaultCurrency, tableConfig } from "../../../config";
import toast from "toasted-notes";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { errorMessages } from "../../../utils/httpMessages";
import BootstrapTable from "react-bootstrap-table-next";

class CompanyView extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      data: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.apiURL + `orders/${this.id}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ data: res.data.data });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (const [key, value] of formData.entries()) {
      data[key] = value.trim();
    }

    if (!data?.name.trim()) {
      toast.notify(
        () => (
          <div className="alert alert-danger m-3">
            <h5>{errorMessages.failed}</h5>
            <p className="mb-0">{errorMessages.required("Name")}</p>
          </div>
        ),
        { position: "top-right", duration: 2500 }
      );
      return;
    }

    axios.post(config.apiURL + "orders", data).then((res) => {
      if (res.data.success) {
        this.setState({ data: res.data });
      }
    });
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "#",
        formatter: (cell, row, index) => `${index + 1}.`
      },
      {
        dataField: "option_value_name",
        text: "Option",
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (cell) => !cell ? "Free" : `${cell} ${defaultCurrency}`
      },
      {
        dataField: "quantity",
        text: "Quantity",
      },
      {
        dataField: "total_price",
        text: "Total",
        formatter: (cell) => cell ? `${cell} ${defaultCurrency}` : ""
      },
    ];

    return (
      <>
        <div className="bg-white rounded p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="page-header">
                <h1 className="font-weight-bold">Order details {this.state.data ? `#${this.state.data.id}` : ""}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {!this.state.isLoading ? (
              <>
                {this.state.data ? (
                  <>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="fullname" className="font-weight-bold">
                        Fullname
                      </Label>
                      <Input
                        id="fullname"
                        readOnly
                        defaultValue={this.state.data.fullname}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="phone" className="font-weight-bold">
                        Phone
                      </Label>
                      <Input
                        id="phone"
                        readOnly
                        defaultValue={
                          this.state.data.phone
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="email" className="font-weight-bold">
                        Email
                      </Label>
                      <Input
                        id="email"
                        readOnly
                        defaultValue={this.state.data.email}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="message" className="font-weight-bold">
                        Message
                      </Label>
                      <Input
                        id="message"
                        readOnly
                        defaultValue={this.state.data.message}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="yacht" className="font-weight-bold">
                        Yacht {this.state.data.yacht?.deleted_at && <span className="text-danger ml-1">
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />This yacht has been deleted
                        </span>}
                      </Label>
                      <Input
                        id="yacht"
                        readOnly
                        defaultValue={`#${this.state.data.yacht?.id} - ${this.state.data.yacht?.name}`}
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4">
                      <Label for="price" className="font-weight-bold">
                        Price (yacht)
                      </Label>
                      <Input
                        id="price"
                        readOnly
                        defaultValue={`${this.state.data.price} ${defaultCurrency}`}
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4">
                      <Label for="total_price" className="font-weight-bold">
                        Total price
                      </Label>
                      <Input
                        id="total_price"
                        readOnly
                        defaultValue={this.state.data.total_price}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="count_adult" className="font-weight-bold">
                        Adult
                      </Label>
                      <Input
                        id="count_adult"
                        readOnly
                        defaultValue={this.state.data.count_adult}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="count_children" className="font-weight-bold">
                        Children
                      </Label>
                      <Input
                        id="count_children"
                        readOnly
                        defaultValue={this.state.data.count_children}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="from_date" className="font-weight-bold">
                        From date
                      </Label>
                      <Input
                        id="from_date"
                        readOnly
                        defaultValue={this.state.data.from_date ? moment(this.state.data.from_date).format('DD-MM-YYYY HH:mm') : ""}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="to_date" className="font-weight-bold">
                        To date
                      </Label>
                      <Input
                        id="to_date"
                        readOnly
                        defaultValue={this.state.data.to_date ? moment(this.state.data.to_date).format('DD-MM-YYYY HH:mm') : ""}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                      <Label for="created_at" className="font-weight-bold">
                        Created at
                      </Label>
                      <Input
                        id="created_at"
                        readOnly
                        defaultValue={this.state.data.created_at ? moment(this.state.data.created_at).format('DD-MM-YYYY HH:mm:ss') : ""}
                      />
                    </div>
                    {
                      this.state.data.options.length ? (
                        <div className="col-12 mt-4">
                          <BootstrapTable
                            bootstrap4
                            striped
                            keyField="id"
                            data={this.state.data.options}
                            {...tableConfig}
                            columns={columns}
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      ) : (
                        <div className="col-12 my-4">
                          <div className="alert alert-info">
                            Option values empty
                          </div>
                        </div>
                      )
                    }
                    <div className="col-12 mb-4">
                      <div>Subtotal: <h4>{this.state.data.price}</h4></div>
                      <div>Add-ons: <h4>{this.state.data.total_price - this.state.data.price}</h4></div>
                      <div>Total: <h4>{this.state.data.total_price}</h4></div>
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-warning p-3">
                      Not found
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="col-12">
                <div className="alert alert-info p-4 text-center">
                  <p
                    className="m-0"
                    style={{ fontSize: "24px", letterSpacing: "1px" }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row justify-content-center mb-3 mt-5">
            <div className="col-3">
              <Link to="/order/list" className="btn btn-light">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Back to list
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyView;
